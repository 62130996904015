import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import { useUploadToMongo, useUploadCorpusToAzure } from './hooks';
import { toast } from 'react-toastify';
import { BACKEND_URL, BLOB_CONTAINER_NAME, BLOB_URL } from '../../config';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { redux_logout } from '../auth/authSlice';
import swal from 'sweetalert';
//import { ObjectID } from 'bson-objectid';
var ObjectID = require('bson-objectid');

export const SingleUploadContracts = ({
	setAllUpload,
	setFiles,
	obj,
	AllUpload,
	ClientName,
	Category,
	ProjectType,
	VendorName,
	ContractType,
	ContractVariation,
	IsGpo,
	GpoName,
	ClientId,
	CategoryId,
	ProjectTypeId,
	VendorNameId,
	ContractTypeId,
	ContractVariationId,
	syncDuplicateCheck,
}) => {
	//const Data = useSelector(state => state.auth)
	const [, setfileUploaded] = useState(false);
	let dispatch = useDispatch();
	const [Status, setStatus] = useState('Pending');
	const upload_mongo_hook = useUploadToMongo();
	const { upload_azure_hook, uploadCancelToken } = useUploadCorpusToAzure();

	const [, settryAgain] = useState(false);

	const [hexObjectID] = useState(ObjectID().toHexString());

	//const hexObjectID = ObjectID().toHexString();

	useEffect(() => {
		if (AllUpload && obj.status === 'Pending') {
			UploadToS3Handle();
			setAllUpload(false);
		}
	}, [AllUpload]);

	async function UploadToS3Handle() {
		setFiles((p) => {
			let x = [...p];
			let ind = x.findIndex((el) => el.key === obj.key);
			x[ind].status = 'Initializing';
			return x;
		});
		setStatus('Initializing');

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${BACKEND_URL}/generate_azure_sas_url`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('azure_access_token'),
			},
		};
		try {
			const response = await axios.request(config);

			const connectionString = response.data.connection_string;

			/* const id  = new ObjectID();
            
            console.log(id); */

			const blobName = hexObjectID + '_' + obj.file.name.split(' ').join('_');

			const prepath =
				'/raw/' + ClientName.split(' ').join('_').split('/').join('_');

			if (response.status === 200) {
				setFiles((p) => {
					let x = [...p];
					let ind = x.findIndex((el) => el.key === obj.key);
					if (ind === -1) {
						return x;
					} else {
						x[ind].s3_uri =
							`${BLOB_URL}/${BLOB_CONTAINER_NAME}${prepath}/${blobName}`;
						x[ind].status = 'Progress';
						return x;
					}
				});
				setStatus('In Progress');

				upload_azure_hook.mutate({ connectionString, obj, blobName, prepath });
			} else if (["Session Expired" , "Unable to verify token" , "User not registered"].includes(response.message)) {
				localStorage.clear();
				dispatch(redux_logout());
				window.location.href = '/'
				window.location.reload()
			} else {
				toast.error('Unable to Connect to Server, Try again');
			}
		} catch (error) {
			toast.error('Unable to Connect to Server, Try again');
		}
	}

	useEffect(() => {
		if (upload_azure_hook.isSuccess) {
			if (
				upload_azure_hook.data === 'Upload cancelled by user.' ||
				upload_azure_hook.data === 'Error during file upload'
			) {
				// toast.error(upload_azure_hook.data)
				setFiles((p) => {
					let x = [...p];
					let ind = x.findIndex((el) => el.key === obj.key);
					x[ind].status = 'Pending';
					return x;
				});
				setStatus('Pending');
			} else {
				// toast.success("Uploaded Success")
				setfileUploaded(true);
				settryAgain(false);
				setFiles((p) => {
					let x = [...p];
					let ind = x.findIndex((el) => el.key === obj.key);
					x[ind].status = 'Saving';
					return x;
				});
				setStatus('Saving');
				uploadMongoHandle();
			}
		} else if (upload_azure_hook.isError) {
			// toast.error("Error in uploading file")
			settryAgain(true);
			setFiles((p) => {
				let x = [...p];
				let ind = x.findIndex((el) => el.key === obj.key);
				x[ind].status = 'Pending';
				return x;
			});
			setStatus('Pending');
		}
	}, [upload_azure_hook.isError, upload_azure_hook.isSuccess]);

	function handleCancel() {
		if (uploadCancelToken) {
			uploadCancelToken.cancel('Upload cancelled by user.');
		}
	}

	function handleLogout(e) {
		e.preventDefault();
		swal('Are you sure you want to cancel this Upload?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				handleCancel();
				setFiles((p) => syncDuplicateCheck(p.filter((x) => x.key !== obj.key)));

				// Add your logout logic here
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}

	function uploadMongoHandle() {
		let body = {
			_id: hexObjectID,
			client_name: ClientName,
			category_name: Category,
			project_type: ProjectType,
			vendor_name: VendorName,
			contract_type: ContractType,
			contract_variation: ContractVariation?.fname,
			is_gpo: IsGpo,
			gpo_name: GpoName,
			client_id: ClientId,
			category_id: CategoryId,
			project_type_id: ProjectTypeId,
			vendor_name_id: VendorNameId,
			contract_type_id: ContractTypeId,
			contract_variation_id: (ContractVariationId != null)?ContractVariationId:undefined,
			s3_uri: obj.s3_uri,
			fname: obj.file.name,
			type:'contract'
		};
		upload_mongo_hook.mutate(body);
	}

	useEffect(() => {
		if (upload_mongo_hook.isError) {
			setFiles((p) => {
				let x = [...p];
				let ind = x.findIndex((el) => el.key === obj.key);
				x[ind].status = 'Pending';
				return x;
			});
			setStatus('Pending');
		} else if (upload_mongo_hook.isSuccess) {
			setFiles((p) => {
				let x = [...p];
				let ind = x.findIndex((el) => el.key === obj.key);
				x[ind].status = 'Done';
				x[ind].build_in_progress = (upload_mongo_hook.data?.build_in_progress); // adding build in progress status
				return x;
			});
			//toast.success("Uploaded Success")
			setStatus('Done');
		}
	}, [upload_mongo_hook.isError, upload_mongo_hook.isSuccess]);

	return (
		<>
			{Status !== 'Done' && (
				<div
					className={`${obj.file_error === 'true' || obj.is_duplicate ? 'border-red-400' : ''} flex items-center py-1 bg-white border-2 border-primary rounded-lg text-sm mb-1`}
				>
					<div className="px-3">
						<span className="material-icons-outlined text-md mt-1">
							content_copy
						</span>
					</div>
					<div className="grow relative min-w-[200px] h-[22px] flex items-center">
						<span className="block pr-6 text-ellipsis whitespace-nowrap overflow-hidden absolute left-0 top-0 w-full">
							{obj.file.name}
						</span>
					</div>
					<div className="px-3">
						<p>{Status}</p>
					</div>
					<div className="px-3">
						{Status === 'Pending' && (
							<button onClick={handleLogout} className="text-sm text-slate-600">
								<span className="material-icons text-md mt-1">cancel</span>
							</button>
						)}
					</div>
				</div>
			)}

			{obj.file_error === 'true' ? (
				<div className="pl-12 text-xs text-red-500 mb-2">
					File size exceeds the maximum limit 30 MB.
				</div>
			) : null}
			{obj.is_duplicate ? (
				<div className="pl-12 text-xs text-red-500 mb-2">
					File already exists
				</div>
			) : null}
		</>
	);
};
