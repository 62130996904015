import React, { useState } from 'react';
import RightSideAccCont from './rightAccordionCont';
import FltrClientName from './client-name-flt';
import UploadTabsCont from './uploadTabsContracts';
import FilterCategoryList from './category-list';
import FilterProjectType from './project-type';
import FilterVendorName from './vendor-name';
import FilterContractType from './contract-type';
import FilterContractVariation from './contract-variation';
import FilterIsGpo from './is-gpo';
import FilterGpoName from './gpo-name';


function TabByContracts() {
    const [files, setFiles] = useState([]);
    const [ClientName, setClientName] = useState(null);
    const [Category, setCategory] = useState(null);
    const [ProjectType, setProjectType] = useState(null);
    const [VendorName, setVendorName] = useState(null);
    const [ContractType, setContractType] = useState(null);
    const [ContractVariation, setContractVariation] = useState(null);

    const [IsGpo, setIsGpo] = useState(null);
    const [GpoName, setGpoName] = useState(null);

    const [ClientId, setClientId] = useState(null);
    const [CategoryId, setCategoryId] = useState(null);
    const [VendorNameId, setVendorNameId] = useState(null);
    const [ProjectTypeId, setProjectTypeId] = useState(null);
    const [ContractTypeId, setContractTypeId] = useState(null);
    const [ContractVariationId, setContractVariationId] = useState(null);


    return (
        <>
            <div className="pt-5 px-10 pb-10 border-b">
                <h5 className="text-sm font-black text-primary mb-5">Upload Documents</h5>
                <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                    <div className="py-3 lg:py-0">
                        <FltrClientName
                            setClientName={setClientName}
                            ClientName={ClientName}
                            setClientId={setClientId}
                            ClientId={ClientId}
                        />
                    </div>
                    <div className="py-3 lg:py-0">
                        <FilterCategoryList
                            setCategory={setCategory}
                            Category={Category}
                            setCategoryId={setCategoryId}
                            CategoryId={CategoryId}
                        />
                    </div>
                    <div className="py-3 lg:py-0">
                        <FilterProjectType
                            setProjectType={setProjectType}
                            ProjectType={ProjectType}
                            setProjectTypeId={setProjectTypeId}
                            ProjectTypeId={ProjectTypeId}
                        />
                    </div>
                    <div className="py-3 lg:py-0">
                        <FilterVendorName
                            setVendorName={setVendorName}
                            VendorName={VendorName}
                            setVendorNameId={setVendorNameId}
                            VendorNameId={VendorNameId}
                            required='true'
                        />
                    </div>
                    <div className="py-3 lg:py-0">
                        <FilterContractType
                            setContractType={setContractType}
                            ContractType={ContractType}
                            setContractTypeId={setContractTypeId}
                            ContractTypeId={ContractTypeId}
                            setContractVariation={setContractVariation}
                            setContractVariationId={setContractVariationId}
                            ClientId={ClientId}
                            CategoryId={CategoryId}
                            VendorNameId={VendorNameId}
                        />
                    </div>
                    {ContractType === 'Child Contract' && (
                    <div className="py-3 lg:py-0">
                        <FilterContractVariation
                            setContractVariation={setContractVariation}
                            ContractVariation={ContractVariation}
                            setContractVariationId={setContractVariationId}
                            ContractVariationId={ContractVariationId}
                            ClientId={ClientId}
                            CategoryId={CategoryId}
                            VendorNameId={VendorNameId}
                        />
                    </div>)}
                    
                    {( ContractType === null || ContractType === 'Master Contract' ) && (
                    <div className="py-3 lg:py-0">
                        &nbsp;
                    </div>)}
                    <div className="py-3 lg:py-0">
                        <FilterIsGpo
                            setIsGpo={setIsGpo}
                            IsGpo={IsGpo}
                            setGpoName={setGpoName}
                            GpoName={GpoName}
                        />
                    </div>
                    {IsGpo === 'Yes' && (
                    <div className="py-3 lg:py-0">
                        <FilterGpoName
                            setGpoName={setGpoName}
                            GpoName={GpoName}
                        />
                    </div>)}
                </div>
            </div>
            <div className="lg:flex">
                <div className="grow border-t lg:border-t-0 lg:border-r">
                    <UploadTabsCont
                        files={files}
                        setFiles={setFiles}
                        ClientName={ClientName}
                        Category={Category}
                        ProjectType={ProjectType}
                        VendorName={VendorName}
                        ContractType={ContractType}
                        ContractVariation={ContractVariation}
                        IsGpo={IsGpo}
                        GpoName={GpoName}
                        setCategory={setCategory}
                        setClientName={setClientName}
                        setProjectType={setProjectType}
                        setVendorName={setVendorName}
                        setContractType={setContractType}
                        setContractVariation={setContractVariation}
                        setIsGpo={setIsGpo}
                        setGpoName={setGpoName}
                        setClientId={setClientId}
                        ClientId={ClientId}
                        setCategoryId={setCategoryId}
                        CategoryId={CategoryId}
                        setProjectTypeId={setProjectTypeId}
                        ProjectTypeId={ProjectTypeId}
                        setVendorNameId={setVendorNameId}
                        VendorNameId={VendorNameId}
                        setContractTypeId={setContractTypeId}
                        ContractTypeId={ContractTypeId}
                        setContractVariationId={setContractVariationId}
                        ContractVariationId={ContractVariationId}

                    />
                </div>
                <div className="flex-none lg:w-[30%]">
                    <RightSideAccCont files={files} />
                </div>
            </div>
        </>
    );
}

export default TabByContracts;
