import React, { useState, useEffect } from 'react';

import { useClientsListMismatch } from './hooks';
//import { toast } from 'react-toastify'
import { Autocomplete, TextField } from '@mui/material';

function FltrClientName({ setClientName, ClientName, setClientId ,ClientId,setReportId,setReportName}) {
	const [options, setoptions] = useState([]);
	const client_list_hook = useClientsListMismatch();
	const [Focus, setFocus] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [open, setOpen] = useState(false);
	const [firstTime, setfirstTime] = useState(true);

	useEffect(() => {
		if (client_list_hook.hook.data) {
			setfirstTime(false);
			setoptions(client_list_hook.hook.data.data);
			if (ClientId) {
                let clientObj = client_list_hook?.hook?.data?.data?.find((o) => (o?._id === ClientId));
                setClientName(clientObj?.name);
            }
		}
	}, [client_list_hook.hook.data, client_list_hook.hook.isSuccess, ClientId, setClientName]);

	useEffect(() => {
		client_list_hook.setArgs((args) => {
			return {
				...args,
				search: inputValue,
			};
		});
	}, [client_list_hook, inputValue]);

	let stop = false;

	return (
		<>
			<div>
				<div className="flex gap-3">
					<div className="grow">
						<div className="lightT">
							{firstTime && client_list_hook.hook.isPending ? (
								<p className="px-4 h-[38px] flex flex-col items-center justify-center border border-1 border-slate-300 rounded-md text-xs">
									{/* Client Name{' '} */}
									<span className="text-xs text-slate-400 ps-1 inline-block">
										Loading...
									</span>
								</p>
							) : (
								<Autocomplete
									disablePortal
									disabled={true}
									id="google-map-demo"
									className='expCat'
									clearOnBlur={true}
									getOptionLabel={(option) =>
										typeof option === 'string' ? option : option.name
									}
									isOptionEqualToValue={(option, value) => option?._id === value?._id}
									filterOptions={(x) => x}
									options={options || []}
									autoComplete
									includeInputInList
									filterSelectedOptions
									value={options.find(option => option._id === ClientId) || null} // Adjust the value here
									open={open}
									onOpen={() => {
										setOpen(true);
									}}
									onClose={() => {
										setOpen(false);
									}}
									noOptionsText="No Client Match"
									onChange={(event, newValue) => {
										if (newValue) {
										setClientName(newValue.name);  // Set the name
										setClientId(newValue._id);     // Set the ID
										} else {
										setClientName(null);
										setClientId(null);
										}
										setReportId(null);
										setReportName(null);
									}}
									onInputChange={(event, newInputValue) => {
										setInputValue(newInputValue);
									}}
									renderInput={(params) => (
										<TextField {...params} label="Client Name *" fullWidth />
									)}
									onFocus={() => setFocus(true)}
									onBlur={() => setFocus(false)}
									renderOption={(props, option) => {
										if (stop) return;
										if (client_list_hook.hook.isPending) {
										// Stop further iteration if stop flag is true
										stop = true;
										return (
											<p key={'loading_client'} className="ml-2">
											Loading...
											</p>
										);
										}

										return (
										<li key={option._id} {...props}>
											{option.name}
										</li>
										);
									}}
									/>
							)}
							{(ClientName == null || ClientName === '') && Focus && (
								<small className="text-red-600 ">Client name is required</small>
							)}
							
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FltrClientName;
