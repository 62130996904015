import { useMutation, useQuery , useQueryClient} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
const fetchClientList = async (Args, dispatch, signal,instance) => {
	try {
		const TOAST_ID = 'get_client_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/client_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		
	}
}

export const useClientListPagewise = (pageNo, sortType) => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState(
		{
			search: '',
			sort: sortType,
			page: pageNo,
		},
		2000
	);
	const hook = useQuery({
		queryKey: ['clientlist', Args],
		queryFn: async ({ signal }) =>
			fetchClientList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

export const useAddClientName = (setIsOpened) => {
	const queryClient = useQueryClient() 
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'add_client_name'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/add_client`, body);
				let message = responseData?.message
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData
				
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['clientlist'] })
			setIsOpened(false)
		  },
	});
};
