import React from 'react';
import FieldsProjectTypeForm from './field-project-type-form';

function AddProjectType() {
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<FieldsProjectTypeForm />
			</div>
		</>
	);
}

export default AddProjectType;
