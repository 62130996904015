import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function AnnotationErrors({ seterrorSelected, errorSelected, errorPages }) {
	const settings = {
		className: 'slider variable-width',
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
	};

	function handleNavigate(pageno) {
		seterrorSelected(pageno);
	}


	return (
		<>
			<h4
				className="text-red-600 mb-2 font-medium text-sm whitespace-nowrap overflow-hidden text-ellipsis"
				title="Navigate to Error Pages"
			>
				Navigate to Error Pages
			</h4>
			<div className="slider-container errorsSlider">
				<Slider {...settings}>
					{errorPages.map((page) => {
						return (
							<div key={`error_page${page}`} className="px-1">
								<button
									onClick={() => handleNavigate(page)}
									className="px-2 py-1 border border-red-600 text-red-600 text-xs rounded-md"
								>
									{page + 1}
								</button>
							</div>
						);
					})}
					
				</Slider>
			</div>
		</>
	);
}

export default AnnotationErrors;
