import React, { useState, useEffect } from 'react';
import styles from '../annotation/invoice.module.css';
import InvoicePane from './invoice-box';
import ContractPane from './contract-box';
import { decodeName, encodeName } from '../utils';
import {  useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { useGetMismatch, useGetNavigationIds, useUpdateMismatchStatus } from './hooks';
import SplitPane from 'split-pane-react';

import LoaderFull from '../shared/loader-full-screen';
const layoutCSS = {
    height: '100%',
    display: 'block',
};


function ComparisonIndex() {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const get_mismatch_detail_by_id = useGetMismatch(searchParams.get('mismatch_id'))
    const [Data, setData] = useState(null)
    const [sizes, setSizes] = useState(['50%', '50%']);
    const update_mismatch_status = useUpdateMismatchStatus()
    const get_navigation_ids_hook = useGetNavigationIds()

    const [MismatchIds, setMismatchIds] = useState(null)

    const [pointsTo, setpointsTo] = useState(null)


    useEffect(() => {
        async function LoadNavIds(mismatchId) {
            try {            
                let responseJson = await get_navigation_ids_hook.mutateAsync(mismatchId)
                let ind = responseJson?.data.findIndex(itm => itm._id === mismatchId)
                setpointsTo({mismatchIndex : ind+1 , totalMismatch : responseJson?.data.length})
                setMismatchIds(responseJson.data)
                return responseJson.data
            } catch (error) {
                return []
            }
        }
      LoadNavIds(searchParams.get('mismatch_id'))
    }, [])
    



    

    const onClickHandler = () => {
        const encodedClientName = encodeName(decodeName(searchParams.get('client_name')))
        const encodedVendorName = encodeName(decodeName(searchParams.get('vendor_name')))
        const encodeInvoiceName = encodeName(decodeName(searchParams.get('invoice_name')))

        navigate({
            pathname: '/my-annotations',
            search: createSearchParams({
                client_id: searchParams.get('client_id'),
                client_name: encodedClientName,
                client_list_page_no: searchParams.get('client_list_page_no'),
                client_list_sort_key: searchParams.get('client_list_sort_key'),
                client_list_sort_order: searchParams.get('client_list_sort_order'),

                vendor_id: searchParams.get('vendor_id'),
                vendor_name: encodedVendorName,
                vendor_list_page_no: searchParams.get('vendor_list_page_no'),
                vendor_list_sort_key: searchParams.get('vendor_list_sort_key'),
                vendor_list_sort_order: searchParams.get('vendor_list_sort_order'),
                category_name: encodeName(decodeName(searchParams.get('category_name'))),
                category_id: encodeName(decodeName(searchParams.get('category_id'))),

                invoice_id: searchParams.get('invoice_id'),
                invoice_name: encodeInvoiceName,
                invoice_list_page_no: searchParams.get('invoice_list_page_no'),
                invoice_list_sort_key: searchParams.get('invoice_list_sort_key'),
                invoice_list_sort_order: searchParams.get('invoice_list_sort_order'),



            }
            ).toString()
        })

    }

    useEffect(() => {
        if (get_mismatch_detail_by_id.hook.fetchStatus === 'idle') {
            if (get_mismatch_detail_by_id.hook.status === 'success' && get_mismatch_detail_by_id.hook?.data?.status === 'found') {
                setData(get_mismatch_detail_by_id.hook.data.data)
            }
            else {
                setData(null)
            }
        }
    }, [get_mismatch_detail_by_id.hook.status, get_mismatch_detail_by_id.hook.fetchStatus, get_mismatch_detail_by_id.hook.data])

    async function updateStatus(status) {
        let body = {
            mismatch_id: Data._id,
            status: status
        }
        try {
            let res = await update_mismatch_status.mutateAsync(body)
            if (res?.message === 'Update successfully') {
                handleNavigate(1)
            }
            // get_mismatch_detail_by_id.hook.refetch()

        } catch (error) {

        }


    }

    function handleNavigate(inc) {
        let tpMismatch = [...MismatchIds]
        
        let ind = tpMismatch.findIndex(itm => itm._id === searchParams.get('mismatch_id'))
        
        
        let el = null 
        if (inc >0){
            if (ind+inc <tpMismatch.length){
                el = tpMismatch[ind+inc]
                setpointsTo({mismatchIndex : ind+inc+1 , totalMismatch : MismatchIds.length})
            }
        }
        else{
            if (ind+inc >=0){
                el = tpMismatch[ind+inc]
                setpointsTo({mismatchIndex : ind+inc+1 , totalMismatch : MismatchIds.length})
            }
        }
        
        if (el){
            setData(null)
            gotoMismatch(el['_id'])
        }else{
            get_mismatch_detail_by_id.hook.refetch()
        }
    }

    function gotoMismatch(mismatch_id){
        
		const encodedClientName = encodeName(decodeName(searchParams.get('client_name')))
		const encodedVendorName = encodeName(decodeName(searchParams.get('vendor_name')))
		const encodeInvoiceName = encodeName(decodeName(searchParams.get('invoice_name')))
		navigate({
			pathname : '/comparison' ,
			search: createSearchParams({ 

				mismatch_id: mismatch_id,

				client_id: searchParams.get('client_id'),
				client_name: encodedClientName,
				client_list_page_no: searchParams.get('client_list_page_no'),
				client_list_sort_key: searchParams.get('client_list_sort_key'),
				client_list_sort_order: searchParams.get('client_list_sort_order'),

				vendor_id: searchParams.get('vendor_id'),
				vendor_name: encodedVendorName,
				vendor_list_page_no : searchParams.get('vendor_list_page_no'),
				vendor_list_sort_key: searchParams.get('vendor_list_sort_key'),
				vendor_list_sort_order: searchParams.get('vendor_list_sort_order'),
				category_name: encodeName(decodeName(searchParams.get('category_name'))),
                category_id: encodeName(decodeName(searchParams.get('category_id'))),

				invoice_id: searchParams.get('invoice_id'),
				invoice_name: encodeInvoiceName,
				invoice_list_page_no : searchParams.get('invoice_list_page_no'),
				invoice_list_sort_key: searchParams.get('invoice_list_sort_key'),
				invoice_list_sort_order: searchParams.get('invoice_list_sort_order'),


				
			}
			).toString() 
		} )
	}




    return (
        <> 
            <div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
                <div className="lg:flex gap-4 justify-between items-center px-6 pt-6">
                    <div className="lg:flex gap-8 justify-between w-full">
                        <div className="pb-4 lg:pb-0 lg:flex-none">
                            <div className="flex gap-6">
                                <div className="flex-none pt-2">
                                    <button onClick={onClickHandler} className="text-primary">
                                        <span className="material-icons text-md">arrow_back</span>
                                    </button>
                                </div>
                                <div className='grow flex gap-4'>
                                    { ! get_mismatch_detail_by_id.hook.isPending && 
                                    <div
                                        className={`${styles.cLoop} flex gap-2 items-center text-sm`}
                                    >
                                        <div
                                            className={`${styles.invfldHLayer} pr-6 relative flex items-center`} style={{ width: '120px' }}
                                        >
                                            <span
                                                title={Data?.mismatch_invoice_entity}
                                                className={`${styles.invfldName} ${styles.gray} block text-gray-600`}
                                            >
                                                {Data?.mismatch_invoice_entity}
                                            </span>

                                        </div>
                                        <div className="grow pr-0 py-1 relative">
                                            <input
                                                title={Data?.mismatch_invoice_entity_value}
                                                type="text"
                                                disabled={true}
                                                className={`${styles.invFldInput} ${Data?.mismatch_invoice_entity_value ? styles.red : ''}`} style={{ width: '200px' , border: `${Data?.mismatch_invoice_entity_value ? '' : 'solid 1px #c2c2c2'}`}}
                                                value={Data?.mismatch_invoice_entity_value}
                                            />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='py-2 flex justify-end'>
                                <div className='flex-none px-1'>
                                    {MismatchIds && <button  onClick={()=> handleNavigate(-1)} type='button' className='border rounded-sm px-2 py-1 flex gap-1 text-xs items-center hover:bg-gray-100'><img className='size-2' src="/svg/left-chevron-solid.svg" alt="" />Previous</button> }
                                </div>
                                <div className='flex-none px-1'>
                                {pointsTo &&  <p>{pointsTo?.mismatchIndex}/{pointsTo?.totalMismatch}</p>}
                                </div>

                                <div className='flex-none px-1'>
                                   {MismatchIds && <button onClick={()=> handleNavigate(1)} type='button' className='border rounded-sm px-2 py-1 flex gap-1 text-xs items-center hover:bg-gray-100'>Next<img className='size-2' src="/svg/right-chevron-solid.svg" alt="" /></button> } 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {get_mismatch_detail_by_id.hook.fetchStatus !== 'idle'? <LoaderFull/> : 
                <>
                
                
                <div className='pb-2 px-6'>
                    <div className='vLBG rounded-md p-4'>
                        <div className='pb-2 text-black font-bold text-sm'>Explanation</div>
                        <div className='p-2 bg-white rounded-md shadow-sm text-black text-xs h-12 overflow-auto'>
                            {Data?.mismatch_explanation}
                        </div>
                        <div className='pt-4 flex gap-3 justify-end'>
                            <button onClick={() => updateStatus('Disapproved')} disabled={update_mismatch_status.isPending} type='button'
                                className={`${Data?.mismatch_status === 'Disapproved' ? 'shadow-2xl font-bold bg-red-600 text-white' : 'bg-red-200 text-red-700'}  hover:bg-red-600 flex items-center px-4 h-[36px] text-sm font-bold rounded-md hover:text-white`}
                            >
                                <span className="material-icons text-sm mr-2">cancel</span>{Data?.mismatch_status === 'Disapproved' ?'Disapproved': 'Disapprove'}
                            </button>
                            <button onClick={() => updateStatus('Approved')} disabled={update_mismatch_status.isPending} type='button'
                                className={`${Data?.mismatch_status === 'Approved' ? 'shadow-2xl font-bold bg-green-600 text-white' : 'bg-green-200 text-green-700'}  hover:bg-green-700 flex items-center px-4 h-[36px] text-sm font-bold rounded-md hover:text-white`}
                            >
                                <span className="material-icons text-sm mr-2">check_circle</span>{Data?.mismatch_status === 'Approved' ?'Approved': 'Approve'}
                            </button>
                        </div>
                    </div>
                </div>


                <div className={`px-4 pt-4 w-full hContainer`}>


                    <SplitPane sizes={sizes} onChange={(sizes) => setSizes(sizes)}>
                        <div
                            className='w-full h-full'
                            style={{
                                ...layoutCSS,
                                background: '#ffffff',

                            }}
                        >


                            <div className='flex justify-between'>
                                <h4 className='pb-1 pl-3 '>Invoice</h4>
                            </div>
                            {Data !== null && <InvoicePane Data={Data} />}
                        </div>

                        <div
                            className='w-full h-full'
                            style={{
                                ...layoutCSS,
                                background: '#ffffff',
                                borderLeft: 'solid 2px #c2c2c2',
                            }}
                        >

                            <div className='flex justify-between'>
                                <h4 className='pb-1 pl-3'>Contract</h4>
                            </div>
                            {Data !== null && <ContractPane Data={Data} />}
                        </div>
                    </SplitPane>
                </div>

                </> }


            </div>

        </>
    );
}

export default ComparisonIndex;
