import axios from 'axios';
import { BACKEND_URL } from '../config';
import { toast } from 'react-toastify';

export function buildAxiosInstance(toastId,silent=false) {
    const axiosInstance = axios.create({
        baseURL: BACKEND_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('azure_access_token')}`
        },
        maxBodyLength: Infinity,
    });

    axiosInstance.interceptors.response.use(
        response => {
            return response.data;
        },
        (error) => {
            if (error.response) {
                if (!silent){
                    const errorMessage = error.response.data.message || 'An error occurred';
                    toast.error(errorMessage, {toastId: toastId });
                }
            }          
            throw error
        }
    );
    return axiosInstance
}