import React from 'react';
import FieldProjectTypeEditForm from './field-project-type-edit-form';

function EditProjectType() {
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<FieldProjectTypeEditForm />
			</div>
		</>
	);
}

export default EditProjectType;
