import React from 'react';
import AuthCopy from './copyright';
import styles from './auth.module.css';

function SideBox({ subtitle }) {
	return (
		<>
			<div
				className={`${styles.authSide} relative min-h-[460px] lg:h-full w-full flex-none lg:w-1/2`}
			>
				<div className="flex h-full py-8 lg:py-0">
					<div className="logoBox m-auto lg:ml-auto lg:mr-[20%] lg:my-auto w-[280px]">
						<div className="pl-3 pb-3">
							<h3 className="textUBdr uppercase relative text-slate-800 text-2xl">
								Be Part of
							</h3>
							<h1 className="font-black text-2xl text-primary">
								Something Bigger
							</h1>
							<p className="text-slate-800 text-md">{subtitle}</p>
						</div>
						<img src="/svg/auth-box-cartoon.svg" alt="" />
					</div>
				</div>
				<AuthCopy />
			</div>
		</>
	);
}

export default SideBox;
