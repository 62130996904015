import React, { useState, useEffect } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { useGetBatches } from './hooks';

function FilterBatchName({
    setBatchName,
	BatchName,
	setBatchNameId,
	BatchNameId,
    ClientName,
    Category,
    ProjectType,
    refetchBatch,
    disabled
}) {

    const [lable] = useState('Batch Name *')

    const [options, setoptions] = useState([]);
	const  batch_list_hook = useGetBatches();
    //const { refetch, isFetching } = useGetBatches();
	const [Focus, setFocus] = useState(false);

	useEffect(() => {
		if (batch_list_hook.data) {
            const currentBatches = batch_list_hook.data.data;
            const timestamp = formatDateToCustomString();
            const newBatch = `${ClientName} / ${Category} / ${ProjectType} / ${timestamp}`;
            // Create an array with the new batch at the start and existing batches after it
            const updatedData = [{ _id: null, name: newBatch }, ...currentBatches];

            // Update the state with the new batch list
            setoptions(updatedData.map(itm => itm.name));;
		}
	}, [batch_list_hook.data,ClientName, Category, ProjectType]);

    // useEffect(() => {
    //     if(refetchBatch){
    //         //Refetch batches here
    //         refetch();
    //     }
       
    // },[refetchBatch,refetch])

    function changeBatch(val) {
		setBatchName(val);
		let obj = batch_list_hook.data.data?.find((o) => (o._id === val || o?.name === val));
		if (obj) {
			setBatchNameId(obj._id);
		}
	}

    function formatDateToCustomString() {
        // Create a new Date object from the input date string
        const date = new Date();
      
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0'); // 24-hour format
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        // Format the date as YYYYMMDDHHMMSS
        const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
      
        return formattedDate;
      }

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT" id="batch">
                            {batch_list_hook.isPending ? (
                                <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                                    Batch Name{' '}
                                    <span className="text-sm text-slate-400 ps-4 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) =>
                                        option === value || value === '' || value == null
                                    }
                                    id="batch"
                                    options={options}
                                    value={BatchName}
                                    renderInput={(params) => (
                                        <TextField {...params} label={lable} />
                                    )}
                                    onChange={(event, value) => changeBatch(value)}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    disabled={disabled}
                                    renderOption={({ key, ...restProps }, option) => {
                                        const isFirstOption = restProps['data-option-index'] === 0;                                       
                                        
										return (
											<li key = {key} {...restProps}>
												{option}
                                                {isFirstOption && <span className='nTag'>New</span>}
											</li>
										);
									}}
                                   
                                />
                            )}
                            {BatchName == null && Focus && (
								<small className="text-red-600 ">
									Batch name is required
								</small>
							)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterBatchName;
