
import { useQuery, useMutation ,useQueryClient} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
import { handleLock, LogoutCheck } from '../utils';
import { buildAxiosInstance } from '../axiosInstance';
import { useMsal } from '@azure/msal-react';

const fetchcontractsList = async (Args, dispatch, signal,instance) => {
	
	handleLock(dispatch, 'last_contract_open',instance)
	try {
		const TOAST_ID = 'get_contract_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/contract_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		
	};

};



export const useContractlistPagewise = (Sort) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState({
		search: '',
		...Sort
	});
	const hook = useQuery({
		queryKey: ['contract_list',Args],
		queryFn: async ({ signal }) =>
			fetchcontractsList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};


export const useDeleteContract = () => {
	const queryClient = useQueryClient() 
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		retry: false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = "delete_contract"
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.delete('/delete_contract_entities', {data : JSON.stringify(body)} ) 
				let message = responseData?.message
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData
			}
			catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			};
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['contract_list'] })
		  },
	});
};