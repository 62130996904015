import * as Yup from 'yup';

export const SignUpSchema = Yup.object({
	name: Yup.string().required('required'),
	email: Yup.string().email('Invalid email address').required('required'),
	password: Yup.string()
		.min(8, 'Password must be at least 8 characters long')
		.max(20, 'Password cannot exceed 20 characters')
		.required('required')
		.matches(/^\S*$/, 'Whitespace is not allowed in the password'),
	confirm_password: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('required'),
	chk1: Yup.boolean()
		.oneOf([true], 'Please accept the terms and conditions') // Ensure chk1 is true
		.required('Required'),
	chk2: Yup.boolean()
		.oneOf([true], 'Please accept the terms and conditions') // Ensure chk1 is true
		.required('Required'),
});

export const LoginSchema = Yup.object({
	email: Yup.string().email('Invalid email address').required('required'),
	password: Yup.string()
		.min(8, 'Password must be at least 8 characters long')
		.max(20, 'Password cannot exceed 20 characters')
		.required('required'),
});

export const ForgotPasswordSchema = Yup.object({
	email: Yup.string().email('Invalid email address').required('required'),
});

export const ChangePasswordSchema = Yup.object({
	password: Yup.string()
		.min(8, 'Password must be at least 8 characters long')
		.max(20, 'Password cannot exceed 20 characters')
		.required('required')
		.matches(/^\S*$/, 'Whitespace is not allowed in the password'),
	confirm_password: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('required'),
});

export const UploadCorpusSchema = Yup.object({
	name: Yup.string().required(),
	description: Yup.string().required(),
	source: Yup.string().required(),
	category: Yup.string().required(),
	sub_category: Yup.string().required(),
});

export const UploadImageSchema = Yup.object({
	name: Yup.string().required(),
	description: Yup.string().required(),
	source: Yup.string().required(),
	category: Yup.string().required(),
	sub_category: Yup.string().required(),
});

export const ProfileSchema = Yup.object({
	name: Yup.string().required('required'),
	email: Yup.string().email('Invalid email address').required('required'),
	old_password: Yup.string().required('required'),
	new_password: Yup.string()
		.min(8, 'Password must be at least 8 characters long')
		.max(20, 'Password cannot exceed 20 characters')
		.required('required')
		.matches(/^\S*$/, 'Whitespace is not allowed in the password'),
	confirm_password: Yup.string()
		.oneOf([Yup.ref('new_password'), null], 'Passwords must match')
		.required('required'),
	// password: Yup.string()
	//     .min(8, 'Password must be at least 8 characters long')
	//     .max(20, 'Password cannot exceed 20 characters')
	//     .required('required'),
});

export const AddKeyPopupSchema = Yup.object({
	Key: Yup.string().required('required'),
	// Validation: Yup.string().required('required')
});
