import React  from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './store.js';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { AZURE_AUTHORITY_URL, AZURE_CLIENT_ID } from './config.js';
/* import { getKeyVaultSecret } from "./keyVaultService.js";


const App1 = () => {
	const [apiKey, setApiKey] = useState(null);
	
	useEffect(() => {
		const fetchSecret = async () => {
			try {
				const secretValue = await getKeyVaultSecret("test");
				setApiKey(secretValue);
				console.log(apiKey);
			} catch (error) {
				// Handle error
			}
		};
		
		fetchSecret();
	}, []);

} */

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // default: true
		},
	},
});
let persistor = persistStore(store);

const pca = new PublicClientApplication({
	auth: {
		clientId: AZURE_CLIENT_ID,
		authority: AZURE_AUTHORITY_URL,
		redirectUri: '/',
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPII) => {
				//console.log(message)
			},
			logLevel: 'Info',
		},
	},
});

// Call the initialize function before using other MSAL APIs

pca.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS) {
		pca.setActiveAccount(event.payload.account);
		localStorage.setItem('azure_access_token', event.payload.accessToken);
		localStorage.setItem('username', event.payload.account?.username);
		localStorage.setItem('name', event.payload.account?.name);
		localStorage.setItem('isSignedIn', true);
	} else if (event.eventType === EventType.LOGIN_FAILURE) {
		console.log('login fails');
	} else if (event.eventType === EventType.LOGOUT_SUCCESS) {
		localStorage.clear();
		localStorage.clear();
	}
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<QueryClientProvider client={queryClient}>
				<App msalInstance={pca} />
			</QueryClientProvider>
		</PersistGate>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
