import React, { useState, useEffect } from 'react';
//import { toast } from 'react-toastify'

import { Autocomplete, TextField } from '@mui/material';

import { GetProjectTypes } from './hooks';

function FilterContractType({
    setContractType,
	ContractType,
	setContractTypeId,
	ContractTypeId,
    setContractVariation,
    setContractVariationId,
    ClientId,
    CategoryId,
    VendorNameId
}) {
    
    
    const contractTypeOptions = ['Master Contract','Child Contract'];

    const [options] = useState(contractTypeOptions);
	const client_list_hook = GetProjectTypes();
	const [Focus, setFocus] = useState(false);


    useEffect(() => {
		setContractTypeId(null)
		setContractType(null)
	}, [ClientId, CategoryId, VendorNameId, setContractTypeId, setContractType]);

    function changeProjectType(val) {
		setContractType(val);
        setContractVariation(null)
        setContractVariationId(null)
		let obj = contractTypeOptions.indexOf(val)+1;
		if (obj) {
			setContractTypeId(obj);
		}
        else{
			setContractTypeId(null)
			setContractType(null)
		}
	}

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            {client_list_hook.isPending ? (
                                <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                                    Contract Type{' '}
                                    <span className="text-sm text-slate-400 ps-4 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) =>
                                        option === value || value === '' || value == null
                                    }
                                    id="combo-box-demo"
                                    options={options}
                                    value={ContractType}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Contract Type *" />
                                    )}
                                    onChange={(event, value) => changeProjectType(value)}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    // onInputChange={(event, newInputValue) => {
                                    //     setProjectType(newInputValue);
                                    //   }}
                                />
                            )}
                            {ContractType == null && Focus && (
								<small className="text-red-600 ">
									Contract type is required
								</small>
							)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterContractType;
