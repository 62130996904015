import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TabByInvoices from './tab_by_invoices';
import TabByContracts from './tab_by_contracts';
import { APP_ROUTE_ALLOWED } from '../../config';

function UploadDocsIndex() {

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">

				{/* Right Side - White workspaces */}
				

					<Tabs>
						<TabList className='m-0 px-10 pt-5'>
							<Tab>Invoices</Tab>
							{APP_ROUTE_ALLOWED['contract_feature_remove'] ? null : <Tab>Contracts</Tab> }
						</TabList>

						<TabPanel>
							<>
								<TabByInvoices />
							</>
						</TabPanel>
						{APP_ROUTE_ALLOWED['contract_feature_remove'] ? null : <TabPanel>
							<>
								<TabByContracts />
							</>
						</TabPanel> }
					</Tabs>

				
			</div>
		</>
	);
}

export default UploadDocsIndex;
