import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	allowedActions: [],
};

export const privilageSlice = createSlice({
	name: 'privilage',
	initialState,
	reducers: {
		privilage_set: (state, action) => {
			state.allowedActions = action.payload.privilages;
		},
		privilage_remove: (state, action) => {
			state.allowedActions = [];
		},
	},
});

// Action creators are generated for each case reducer function
export const { privilage_set, privilage_remove } = privilageSlice.actions;

export default privilageSlice.reducer;
