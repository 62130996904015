import React from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

function LoaderFull() {
	return (
		<>
			<div className="flex flex-col items-center justify-center py-5">
				<DotLottiePlayer
					src="https://lottie.host/306da635-18eb-4156-8918-602777f5d09a/Y9e7d8q6YR.lottie"
					autoplay
					loop
				></DotLottiePlayer>
				<p className="mt-2">Loading...</p>
			</div>
		</>
	);
}

export default LoaderFull;
