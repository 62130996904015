import React from 'react';
import styles from './auth.module.css';

function AuthCopy() {
	let today = new Date();
	let yearStamp = today.getFullYear();

	return (
		<>
			<div
				className={`${styles.authCopy} text-center text-slate-700 lg:text-left text-xs`}
			>
				&copy; {yearStamp} PRP. All Rights Reserved.
			</div>
		</>
	);
}

export default AuthCopy;
