import React from 'react';

export const HelpIndex = () => {
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				{/* Right Side - White workspaces */}
				<div className="vLBG py-10 px-6">
					<h1 className="text-lg font-normal text-primary">
						<strong className="font-black">Help</strong>
					</h1>
				</div>
			</div>
		</>
	);
};
