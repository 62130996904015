import React from 'react';

function Notify() {
	return (
		<>
			<div className="vLBG py-10 px-6">
				<h1 className="text-lg font-normal text-primary">
					<strong className="font-black">Notifications</strong>
				</h1>
				<p className="text-xs text-gray-500 mb-2">
					List of notifications appear here-
				</p>
			</div>

			{/* <div className='cont'>
                <div className='flex gap-4 p-6 border-b border-gray items-center'>
                    <div className='flex-none'>
                        <span className='material-icons-outlined text-lg'>notifications</span>
                    </div>
                    <div className='grow'>
                        <p>01/18/2024</p>
                        <h3 className='text-lg text-primary'>Lorem ipsum dolor sit</h3>
                    </div>
                    <div className='flex-none'>
                        <button type='button' className='bg-blue-800 hover:bg-indigo-700 inline-flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white'>
                            <span className='material-icons text-white text-sm mr-2'>delete</span>
                                Delete
                        </button>
                    </div>
                </div>
                <div className='flex gap-4 p-6 border-b border-gray items-center'>
                    <div className='flex-none'>
                        <span className='material-icons-outlined text-lg'>notifications</span>
                    </div>
                    <div className='grow'>
                        <p>01/18/2024</p>
                        <h3 className='text-lg text-primary'>Lorem ipsum dolor sit</h3>
                    </div>
                    <div className='flex-none'>
                        <button type='button' className='bg-blue-800 hover:bg-indigo-700 inline-flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white'>
                            <span className='material-icons text-white text-sm mr-2'>delete</span>
                                Delete
                        </button>
                    </div>
                </div>
                <div className='flex gap-4 p-6 border-b border-gray items-center'>
                    <div className='flex-none'>
                        <span className='material-icons-outlined text-lg'>notifications</span>
                    </div>
                    <div className='grow'>
                        <p>01/18/2024</p>
                        <h3 className='text-lg text-primary'>Lorem ipsum dolor sit</h3>
                    </div>
                    <div className='flex-none'>
                        <button type='button' className='bg-blue-800 hover:bg-indigo-700 inline-flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white'>
                            <span className='material-icons text-white text-sm mr-2'>delete</span>
                                Delete
                        </button>
                    </div>
                </div>
                <div className='flex gap-4 p-6 border-b border-gray items-center'>
                    <div className='flex-none'>
                        <span className='material-icons-outlined text-lg'>notifications</span>
                    </div>
                    <div className='grow'>
                        <p>01/18/2024</p>
                        <h3 className='text-lg text-primary'>Lorem ipsum dolor sit</h3>
                    </div>
                    <div className='flex-none'>
                        <button type='button' className='bg-blue-800 hover:bg-indigo-700 inline-flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white'>
                            <span className='material-icons text-white text-sm mr-2'>delete</span>
                                Delete
                        </button>
                    </div>
                </div>
            </div> */}
		</>
	);
}

export default Notify;
