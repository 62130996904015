import React, { useState, useEffect } from 'react';
import { GetProjectTypes } from './hooks';
//import { toast } from 'react-toastify'
import { Autocomplete, TextField } from '@mui/material';

function FilterProjectType({
	setProjectType,
	ProjectType,
	setProjectTypeId,
	ProjectTypeId,
}) {
	const [options, setoptions] = useState([]);
	const client_list_hook = GetProjectTypes();
	const [Focus, setFocus] = useState(false);

	useEffect(() => {
		if (client_list_hook.data) {
			setoptions(client_list_hook.data.data.map((itm) => itm.name));
		}
	}, [client_list_hook.data]);

	//const [ProjectTypeId, setProjectTypeId] = useState('')

	function changeProjectType(val) {
		//console.log(val);
		setProjectType(val);
		let obj = client_list_hook.data.data.find((o) => o.name === val);
		//console.log(obj);
		if (obj) {
			setProjectTypeId(obj._id);
		}
		else{
			setProjectTypeId(null)
			setProjectType(null)
		}
	}

	return (
		<>
			<div>
				<div className="flex gap-3">
					<div className="grow">
						<div className="lightT">
							{client_list_hook.isPending ? (
								<p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
									Project Type{' '}
									<span className="text-sm text-slate-400 ps-4 inline-block">
										Loading...
									</span>
								</p>
							) : (
								<Autocomplete
									disablePortal
									isOptionEqualToValue={(option, value) =>
										option === value || value === '' || value == null
									}
									id="combo-box-demo"
									options={options}
									value={ProjectType}
									renderInput={(params) => (
										<TextField {...params} label="Project Type *" />
									)}
									onChange={(event, value) => changeProjectType(value)}
									onFocus={() => setFocus(true)}
									onBlur={() => setFocus(false)}
									// onInputChange={(event, newInputValue) => {
									//     setProjectType(newInputValue);
									//   }}
								/>
							)}
							{ProjectType == null && Focus && (
								<small className="text-red-600 ">
									Project type is required
								</small>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FilterProjectType;
