import React from 'react';
import FieldsCategoryEditForm from './field-category-edit-form';

function EditCategory() {
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<FieldsCategoryEditForm />
			</div>
		</>
	);
}

export default EditCategory;
