import React from 'react';
import {
	useNavigate
} from 'react-router-dom';
import { encodeName } from '../utils';
function InvoiceAttentions({DashboardData}) {
    const navigate = useNavigate();
    function handleNavigate(itm) {
		console.log(itm);
		navigate(
			`/my-annotations?vendor_id=${itm.vendor_id}&client_id=${itm.client_id}&invoice_id=${itm._id}&client_name=${encodeName(itm.client)}&vendor_name=${encodeName(itm.vendor)}&invoice_name=${encodeName(itm.fname)}&category_id=${itm.category_id}&client_list_page_no=1&client_list_sort_key=&client_list_sort_order=&vendor_list_page_no=1&vendor_list_sort_key=&vendor_list_sort_order&invoice_list_page_no=1&invoice_list_sort_key=&invoice_list_sort_order=`
		);
	}

	return (
		<>
			<p className="text-xs text-gray-500 mb-2">
				Invoices which needs human attention
			</p>
			{/* <p className="text-lg font-black text-primary mb-4">0</p> */}
			<div className='pr-4 max-h-[286px] overflow-auto'>
            {DashboardData?.invoice_attention_list?.data.map((itm, ind) => {
                return (<div key={`itm` + ind} className='flex gap-2 mb-2'>
                    <div className='flex-none'>
                        <label className='vLBG block p-3 rounded-md text-center text-white relative text-sm'>
                            <img src="/svg/folder-ico.svg" alt="" />
                            <span className='absolute z-10 left-0 top-0 w-full h-full flex justify-center items-center pt-1'>
                                {ind+1}
                            </span>
                        </label>
                    </div>
                    <div className='grow border rounded-md px-5 py-3 text-sm flex items-center justify-between'>
                        <div className='grow relative h-5'>
                            <span className='block border-r pr-4 absolute w-full left-0 top-0 text-ellipsis overflow-hidden whitespace-nowrap' title={itm.fname}>
                            {itm.fname}
                            </span>
                        </div>
                        <div className='flex-none border-r px-4 text-center w-40'>
                        {formatDate(itm.published_on.$date)}
                        </div>
                        <div className='flex-none border-r px-4 text-center text-red-600 w-56'>
                            <span className='inline-block w-2 h-2 rounded-full bg-red-600 mr-2'></span>
                            error: {itm.total_incorrect_entities}/{itm.total_entities_captured}
                        </div>
                        <div className="flex-none px-4 text-center w-[150px] text-xs">
                            <span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
                            <span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
                            <span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
                            {itm.percentage_complete}%
                        </div>
                    </div>
                    
                    <div className='flex-none'>
                        <button onClick={() => handleNavigate(itm)} type='button' className='vLBG block px-2 py-3 text-center text-primary w-[90px] rounded-md font-black'>
                            View
                        </button>
                    </div>
                </div>)
            })}
            {DashboardData?.invoice_attention_list?.count === 0 &&
                <div  className='flex gap-2 pt-1'>
                    No Data Found
                </div> 
            }
            </div>
		</>
	);
}

export default InvoiceAttentions;

function formatDate(inputDateString) {
	if (inputDateString === undefined) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString =  month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}
