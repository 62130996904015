import React from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  //import { faker } from '@faker-js/faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

function AnnotationsChart({DashboardData}) {

    const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: 'Chart.js Line Chart',
          },
        },
        scales: {
            x: {
              //beginAtZero: true,
              grid: {
                display: false
              },
              ticks: {
                minRotation	: 20
                //count:5,
                //stepSize: 5,
                //maxTicksLimit:10
              },
            },
            y: {
              //suggestedMin: 0,
              beginAtZero: true,
              ticks: {
                precision:0
                //count:5,
                //stepSize: 5,
                //maxTicksLimit:10
              },
              grid: {
                display: true
              }
            }
          }
      };

      const date = DashboardData?.human_annotated?.map(item => {
        //console.log(item._id.year_month,item.count);
        return item._id.year_month
      });

      let total_ha = 0;
      const count = DashboardData?.human_annotated?.map(item => {
        total_ha = total_ha+item.count
        //console.log(item._id.year_month,item.count);
        return item.count
      });

      //console.log(total_ha);
      //console.log(count);
      
      const labels = date;
      let dataset = count;

      const data = {
        labels,
        datasets: [
          {
            label: 'Human Annotation',
            data: dataset,
            borderColor: 'rgb(39, 81, 163)',
            backgroundColor: 'rgb(39, 81, 163)',
          }
        ],
      };

	return (
		<>
			<h1 className="text-lg font-black text-primary">Human Annotations</h1>
			<p className="text-xs text-gray-500 mb-2">
				Total human annotations done till now
			</p>
			<p className="text-lg font-black text-primary">{total_ha}</p>
			<div className="pt-6">
      { DashboardData?.human_annotated?.length>0 &&
        <Line options={options} data={data} />
      }
      {DashboardData?.human_annotated?.length === 0 &&
        <div  className='flex gap-2 pt-1'>
            No Data Found
        </div> 
        }
			</div>
		</>
	);
}

export default AnnotationsChart;
