import React from 'react';
import {
	createSearchParams,
	useNavigate,
	useOutletContext,
	useSearchParams,
} from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { TextHighlighter } from '../shared/textBold';
import { decodeName } from '../utils';

export const VendorListingData = () => {

	const { vendorList, Search , selectedVendorIds , setselectedVendorIds, disableList } = useOutletContext();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	function handleNavigate(obj) {
		const encodedClientName = encodeURIComponent(decodeName((searchParams.get('client_name'))))
		const encodedVendorName = encodeURIComponent(obj._id.name);
		const encodeCategoryName = encodeURIComponent(obj._id.category_name);
		navigate({
			pathname : '/my-invoices' ,
			search: createSearchParams({ 
				client_id: searchParams.get('client_id'),
				client_name: encodedClientName,
				client_list_page_no: searchParams.get('client_list_page_no'),
				client_list_sort_key: searchParams.get('client_list_sort_key'),
				client_list_sort_order: searchParams.get('client_list_sort_order'),

				vendor_id: obj._id.vendor_id?.$oid,
				vendor_name: encodedVendorName,
				vendor_list_page_no : searchParams.get('vendor_list_page_no'),
				vendor_list_sort_key: searchParams.get('vendor_list_sort_key'),
				vendor_list_sort_order: searchParams.get('vendor_list_sort_order'),
				category_name: encodeCategoryName,
				category_id: obj._id.category_id?.$oid,

				invoice_list_page_no : 1,
				invoice_list_sort_key: '',
				invoice_list_sort_order: '',




			}
			).toString() 
		} );
	}

	function handleNavigateToContracts(obj) {
		const encodedClientName = encodeURIComponent(decodeName((searchParams.get('client_name'))))
		const encodedVendorName = encodeURIComponent(obj._id.name);
		const encodeCategoryName = encodeURIComponent(obj._id.category_name);
		navigate({
			pathname : '/my-contracts' ,
			search: createSearchParams({ 
				client_id: searchParams.get('client_id'),
				client_name: encodedClientName,
				client_list_page_no: searchParams.get('client_list_page_no'),
				client_list_sort_key: searchParams.get('client_list_sort_key'),
				client_list_sort_order: searchParams.get('client_list_sort_order'),

				vendor_id: obj._id.vendor_id?.$oid,
				vendor_name: encodedVendorName,
				vendor_list_page_no : searchParams.get('vendor_list_page_no'),
				vendor_list_sort_key: searchParams.get('vendor_list_sort_key'),
				vendor_list_sort_order: searchParams.get('vendor_list_sort_order'),
				category_name: encodeCategoryName,
				category_id: obj._id.category_id?.$oid,
				
				contract_list_page_no : 1,
				contract_list_sort_key: '',
				contract_list_sort_order: '',




			}
			).toString() 
		} );
	}

	function handleCheckBoxRowwise(checked, vendor_id , category_id){
		if (checked) { 
				setselectedVendorIds(p => {
					let tempList = [...p]
					let exists = tempList.some(item => item.vendor_id === vendor_id && item.category_id ===category_id);
					if (exists){
						return tempList
					}
					else{
						tempList.push({
							vendor_id : vendor_id , 
							category_id: category_id
						})
						return tempList
					}
				})
			

		}
		else { 
				setselectedVendorIds(p => {
					let tempList = [...p]
					let exists = tempList.some(item => item.vendor_id === vendor_id && item.category_id ===category_id);
					if (exists){
						return tempList.filter(item => !(item.vendor_id === vendor_id && item.category_id ===category_id) )
					}
					else{
						return tempList
					}
				})
			
		}
	}

	function isDisable(itm) {
		if (itm.disable) {
			return true
		}
		else if (disableList.some(item => item.vendor_id=== itm._id.vendor_id?.$oid && item.category_id=== itm._id.category_id?.$oid  )){
			return true
		}
		else if(itm.total_invoices===0){
			return true
		}
		else if (itm._id.vendor_id ==null || itm._id.category_id == null) {
			return true
		}
		else{
			return false
		}

		
	}

	function isDisableContractBtn(itm) {
		
		if(itm.total_contracts===0){
			return true
		}
		else if (itm._id.vendor_id ==null || itm._id.category_id == null) {
			return true
		}
		else{
			return false
		}

		
	}

	function getTitle(itm) {
		if (itm._id.vendor_id ==null || itm._id.category_id == null) {
			return 'No file exists'
		}
		else if(itm.total_invoices===0){
			return 'No invoice found'
		}
		else{
			return ''
		}
	}

	function buttonDisableScenario(itm) {
		if (itm.disable) {
			return {disable:  true , lable:  'In progress' , title: 'Model building in progress'}
		}
		else if (itm._id.vendor_id ==null || itm._id.category_id == null) {
			return {disable:  true , lable:  'View Invoices'}
		}
		else if (itm.total_invoices === 0) {
			return {disable:  true , lable:  'View Invoices'}
		}
		else if (disableList.some(item => item.vendor_id=== itm._id.vendor_id?.$oid && item.category_id=== itm._id.category_id?.$oid  )){
			return {disable:  true , lable:  'View Invoices'}
		}
		else{
			return {disable:  false , lable:  'View Invoices'}
		}
	}

	function buttonDisableScenarioContract(itm) {
		if (itm.disable) {
			return {disable:  true , lable:  'View Contracts' , title: 'Model building in progress'}
		}
		else if (itm._id.vendor_id ==null || itm._id.category_id == null) {
			return {disable:  true , lable:  'View Contracts'}
		}
		else if (itm.total_contracts === 0) {
			return {disable:  true , lable:  'View Contracts'}
		}
		else if (disableList.some(item => item.vendor_id=== itm._id.vendor_id?.$oid && item.category_id=== itm._id.category_id?.$oid  )){
			return {disable:  true , lable:  'View Contracts'}
		}
		else{
			return {disable:  false , lable:  'View Contracts'}
		}
	}

	return (
		<>
			{vendorList?.data?.map((itm, ind) => {
				return (
					<div key={`vendor` + ind} className="flex gap-2 mb-2 tblLoop">
						<div className="flex-none w-[40px] flex items-center">							
							<Checkbox disabled={isDisable(itm)} 
								title={getTitle(itm)}
								onChange={(e)=> {
								handleCheckBoxRowwise(e.target.checked, itm._id.vendor_id?.$oid , itm._id.category_id?.$oid)
							}} checked={selectedVendorIds.filter(item => item.vendor_id === itm._id.vendor_id?.$oid && item.category_id === itm._id.category_id?.$oid ).length !==0 } size="small" />
						</div>
						<div className="flex-none">
							<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
								<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
							</label>
						</div>
						<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
							<div className="grow border-r pr-4">
								<div className="min-w-[120px] relative flex grow border-r pr-4 items-center">
									<span
										title={itm._id.name}
										className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
									>
										<TextHighlighter text={itm._id.name} searchString={Search} />
									</span>
								</div>
								
							</div>
							<div className="flex-none border-r px-4 text-center w-[140px] block text-ellipsis overflow-hidden whitespace-nowrap">
								{itm._id.category_name}
							</div>
							<div className="flex-none border-r px-4 text-center w-[110px]">
								{itm.human_annotation}
							</div>
							<div className="flex-none px-4 text-center w-[90px]">
								{itm.total_invoices}
							</div>
							<div className="flex-none border-r px-4 text-center w-[110px]">
								{itm.total_contracts}
							</div>
							
							<div className="flex-none px-2 text-center w-[120px]">
								<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
								<span className="inline-block w-2 h-2 rounded-full bg-gray-400 mx-1"></span>
								<span className="inline-block w-2 h-2 rounded-full bg-gray-200 mx-1"></span>
								{itm?.percentage_complete}%
							</div>
						</div>

						<div className="flex-none flex pr-2">
							<button
								disabled={isDisable(itm)}
								onClick={() => handleNavigate(itm)}
								title={buttonDisableScenario(itm).title}
								type="button"
								className="w-[130px] vLBG block px-0 py-3 text-center text-primary text-sm rounded-md font-black mx-1 disabled:bg-gray-200 disabled:text-gray-500"
							>
								{buttonDisableScenario(itm).lable}
							</button>
							<button
								disabled={isDisableContractBtn(itm)}
								onClick={() => handleNavigateToContracts(itm)}
								title={buttonDisableScenarioContract(itm).title}
								type="button"
								className=" w-[130px] vLBG block px-0 py-3 text-center text-primary text-sm rounded-md font-black mx-1 disabled:bg-gray-200 disabled:text-gray-500"
							>
								{buttonDisableScenarioContract(itm).lable}
							</button>
						</div>
					</div>
				);
			})}
		</>
	);
};
