import React, { useState } from 'react';
import classes from './invoice.module.css';

export const RenderDropdown = ({
	Boxes,
	setBoxes,
	areaProps,
	fieldsExtracted,
	tablesExtracted,
	PageNo,
	Lables,
	setchangeDetect,
	setchangeAdd,
	DocLockedForMe
}) => {

	const [hide, sethide] = useState( ()=> {
		if (areaProps.entity) {
			return true
		}
		else{
			return false
		}
	});


	function handleLabelClick(itm) {
		const index = areaProps.areaNumber - 1;
		let allTags = [...Boxes];
	
		if (!allTags[index].entity || allTags[index].status === 'new added') {
			allTags[index].status = 'new added';
			allTags[index].entity = itm.toLocaleUpperCase();
			allTags[index].page = PageNo;
		} 
		else {
			// Handle existing entities
			if (allTags[index].entity !== itm) {
				// Entity has changed
				let newStatus = 'entity changed';
				if (['coordinate changed', 'entity coordinate changed'].includes(allTags[index].status)) {
					newStatus = 'entity coordinate changed';
				}
				allTags[index].status = newStatus;
				allTags[index].entity = itm.toLocaleUpperCase();
			}
		}
		if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=> p+1)
		}
		setBoxes(allTags);
		sethide(prev => !prev);
	}


	return (
		<div className="bbDDBoxMain">
			<div className="ddBxHdr">
				<span
					style={{ height: '22px' }}
					className="ddLbl block px-2 py-1 text-blue-400"
				>
					{areaProps.entity  ? areaProps?.entity:  ''  }
				</span>
				{(function check() {
					if (areaProps.entity) {
						if (hide) {
							return (<span
								onClick={() => sethide((p) => !p)}
								className="material-icons text-md bbDAr cursor-pointer"
							>
								keyboard_arrow_down
							</span>)
						}
						else {
							return (
								<span
									onClick={() => sethide((p) => !p)}
									className="material-icons text-md bbDAr cursor-pointer"
								>
									keyboard_arrow_up
								</span>
							)
						}
					}
					else {
						return null
					}

				})()}
				
			</div>
			{!hide && (
				<div className="bbDDBoxMain">
					{/* Dropdown */}
					<div className="flex flex-col bbDDownBox">
					{areaProps.entity && 
					<div className="fItem px-2 py-1 text-sm cursor-pointer">
						<div
							onClick={() => sethide(true)}
							className="flex relative gap-4 items-center"
						>
							<div className="grow">
								<span
									className={`${classes.invfldName} ${classes.gray} block text-gray-600`}
								>
									{areaProps.entity?.toLocaleUpperCase()}
								</span>
							</div>

							<div className="flex-none">
								<span
									className='material-icons text-sm text-green-500'
								>
									check_circle
								</span>
							</div>
						</div> 
					</div>}
						{Lables.map((itm,ind) => {
							return (
								<div key={`Drop_Lable_${ind}`}>
								
								{itm !== areaProps.entity &&
								<div className="fItem px-2 py-1 text-sm cursor-pointer">
									<div
										onClick={() => handleLabelClick(itm)}
										className="flex relative gap-4 items-center"
									>
										<div className="grow">
											<span
												className={`${classes.invfldName} ${classes.gray} block text-gray-600`}
											>
												{itm?.toLocaleUpperCase()}
											</span>
										</div>

										<div className="flex-none">
											<span
												className='material-icons text-sm text-gray-500'
											>
												check_circle
											</span>
										</div>
									</div>
								</div> }
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};
