import {  Outlet, useSearchParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useCallback ,useEffect} from 'react';
//import { useParams } from 'react-router-dom'
import { useInvoiceslistPagewise,useSoftDeleteFailedFiles } from './hooks';
//import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { RECORDS_PER_PAGE } from '../../config';
//import { dispInvoicePageNo } from '../navigateSlice';
import LoaderFull from '../shared/loader-full-screen';
import { useDebouncedState } from '@mantine/hooks';
import { decodeName ,  } from '../utils';
import { toast } from 'react-toastify';
import swal from 'sweetalert';


function ListInvoicesIndexByStatus() {
	//const navigateRedux = useSelector(state => state.navigateSlice)
	const [searchParams, setSearchParams] = useSearchParams();

	const delete_inv_hook = useSoftDeleteFailedFiles()
	//let dispatch = useDispatch()

	const [selectedInvoices, setselectedInvoices] = useState(new Set([]))

	const [DebounceSearch] = useDebouncedState('', 500);
	let sortArg =''
	if (searchParams.get('invoice_list_sort_key') ==='' || searchParams.get('invoice_list_sort_key') ===null){
		sortArg = ''
	}
	else{
		sortArg = [searchParams.get('invoice_list_sort_key') , searchParams.get('invoice_list_sort_order')].join(' ')
	}
	const invoices_list_hook = useInvoiceslistPagewise(
		decodeName(searchParams.get('client_id')),
		decodeName(searchParams.get('vendor_id')),
		decodeName(searchParams.get('category_name')),
		(parseInt(searchParams.get('invoice_list_page_no'))|| 1),
		sortArg
	)

	const renderList = useCallback(() => {
		if (invoices_list_hook.hook.isFetching || delete_inv_hook.isPending ) {
			return <LoaderFull />;
		} else if (invoices_list_hook.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<Outlet
					context={{
						invoiceList: invoices_list_hook.hook.data,
						Search: DebounceSearch,
						selectedInvoices: selectedInvoices,
						setselectedInvoices: setselectedInvoices
					}}
				/>
			);
		}
	}, [invoices_list_hook.hook, DebounceSearch, selectedInvoices, delete_inv_hook.isPending]);

	function handleCheckAll(checked) {
		if (checked) {
			setselectedInvoices(new Set(invoices_list_hook.hook?.data?.data.map(itm => itm._id)))
		}
		else{
			setselectedInvoices(new Set([]))
		}
	}

	function deleteInvClick() {
		
		swal('Are you sure you want to delete this? This action is permanent and cannot be undone.', {
		buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				let body = { 
					document_ids :Array.from(selectedInvoices)
				}
				delete_inv_hook.mutate(body)
			} else {
				// User clicked "No" button or closed the dialog
			}
		});
	}

	useEffect(() => {
		if (delete_inv_hook.isSuccess) { 
		  toast.success("The failed files has been deleted")
		  setselectedInvoices(new Set([]))
		  invoices_list_hook.hook.refetch()
		}
	  }, [delete_inv_hook.isSuccess])
	
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				{/* Right Side - White workspaces */}
				<>
					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div className="flex-none">
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Failed Files</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								List of all Failed Files are given below -
							</p>
						</div>
						<div className="flex gap-3 flex-wrap">
							<div className="flex gap-2 xl:pr-7">								
								<button type='button'
                                    className='bg-red-600 hover:bg-red-500 px-6 h-[40px] rounded-md text-sm font-black text-white flex gap-2 items-center disabled:bg-gray-200 disabled:text-gray-500 disabled:border disabled:border-gray-400'
									disabled={delete_inv_hook.isPending || selectedInvoices.size ===0} onClick={deleteInvClick}>
									<span className="material-icons-outlined text-md">delete</span>
                                    <span>Delete </span>
                                </button>
							</div>
						</div>
					</div>
					<div className="p-6">
						<div className="listDHeight overflow-auto">
							{/* Table Head */}
							<div className="flex gap-2 mb-2 items-center pr-4">
								 <div className="flex-none w-[40px]">&nbsp;
									<Checkbox 
										disabled={invoices_list_hook.hook.isFetching} 
										checked={selectedInvoices.size === invoices_list_hook.hook?.data?.data?.length && selectedInvoices.size !==0 } 
										onChange={(e)=> handleCheckAll(e.target.checked)} size="small" />
									<div className="text-[9px] text-center">Select All</div>
								</div>
								<div className="flex-none w-[55px]">
									<div className="flex-none text-center relative w-[55px]">&nbsp;
										{/* <span title='default sort'  className={`text-primary cursor-pointer  material-icons w-4 text-sm mt-1`}>
											swap_vert
										</span> */}
									</div>
								</div>
								<div className="grow px-2 py-1 flex items-center justify-between text-xs">
									<div className="grow pr-4">
										<label
											/* onClick={() => handleSort('fname')} */
											className={` block font-black relative w-[120px] cursor-default`}
										>
											Name of File
											{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span> */}
										</label>
									</div>
									<div className="flex-none px-2 text-center w-[200px]">
										<label
											// onClick={() => handleSort('uploaded_by')}
											className={`font-black block relative w-[200px] cursor-default`}
										>
											File Type
											{/* <span className='material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]'>swap_vert</span> */}
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[200px]">
										<label
											// onClick={() => handleSort('uploaded_by')}
											className={`font-black block relative w-[200px] cursor-default`}
										>
											Uploaded By
											{/* <span className='material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]'>swap_vert</span> */}
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[200px]">
										<label
											/* onClick={() => handleSort('uploaded_at')} */
											className={`font-black block relative w-[200px] cursor-default`}
										>
											Date Uploaded
											{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span> */}
										</label>
									</div>
									<div className="flex-none px-4 text-center w-[160px]">
										<label
											/* onClick={() => handleSort('uploaded_at')} */
											className=' font-black block relative w-[160px] cursor-default'
										>
											Failure Reason
											{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
												swap_vert
											</span> */}
										</label>
									</div>
								</div>
								<div className="flex-none w-[150px] pr-2">&nbsp;</div>
							</div>
							{/* Table Body Row - Loop */}

							{renderList()}
						</div>
						<div className="flex justify-center gap-3 pt-2">
							{!invoices_list_hook.hook.isLoading && (
								<Stack spacing={2}>
									<Pagination
										page={parseInt(searchParams.get('invoice_list_page_no'))}
										onChange={(e, value) => {
											// dispatch(dispInvoicePageNo({invoiceListPageNo : value}))
											invoices_list_hook.setArgs((p) => {
												return {
													...p,
													page: value,
													// sort: '',
												};
											});
											searchParams.set('invoice_list_page_no', value)
											setSearchParams(searchParams)
											// setSort({
											// 	type: null,
											// 	order: 'arrow_downward_alt',
											// })
										}}
										count={Math.ceil(
											invoices_list_hook.hook.data?.total_invoices /
												RECORDS_PER_PAGE
										)}
										color="primary"
										variant="outlined"
										shape="rounded"
									/>
								</Stack>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	);
}

export default ListInvoicesIndexByStatus;
