import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	clientListPageNo: 1,
	clientListSelectedItem: null,
	vendorListPageNo: 1,
	vendorListSelectedItem: null,
	invoiceListPageNo: 1,
	invoiceListSelectedItem: null,
};

export const navigateSlice = createSlice({
	name: 'navigateListing',
	initialState,
	reducers: {
		dispClientPageNo: (state, action) => {
			state.clientListPageNo = action.payload.clientListPageNo;
		},
		dispClientId: (state, action) => {
			state.clientListSelectedItem = action.payload.clientListSelectedItem;
		},
		dispVendorPageNo: (state, action) => {
			state.vendorListPageNo = action.payload.vendorListPageNo;
		},
		dispVendorId: (state, action) => {
			state.vendorListSelectedItem = action.payload.vendorListSelectedItem;
		},
		dispInvoicePageNo: (state, action) => {
			state.invoiceListPageNo = action.payload.invoiceListPageNo;
		},
		dispInvoiceId: (state, action) => {
			state.invoiceListSelectedItem = action.payload.invoiceListSelectedItem;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	dispClientPageNo,
	dispClientId,
	dispVendorPageNo,
	dispVendorId,
	dispInvoicePageNo,
	dispInvoiceId,
} = navigateSlice.actions;

export default navigateSlice.reducer;
