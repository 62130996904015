import React from 'react';
import { useOutletContext, useNavigate, useSearchParams } from 'react-router-dom';
import { TextHighlighter } from '../shared/textBold';
import { createSearchParams } from "react-router-dom";

export const ClientListingData = () => {
	const { clientList, Search } = useOutletContext();
	const [searchParams] = useSearchParams()

	const navigate = useNavigate();
	function handleNavigate(itm) {
		const encodedName = encodeURIComponent(itm.name);
		navigate({
			pathname : '/my-vendors' ,
			search: createSearchParams({ 
				client_id: itm._id,
				client_name: encodedName,
				client_list_page_no: searchParams.get('client_list_page_no'),
				client_list_sort_key: searchParams.get('client_list_sort_key'),
				client_list_sort_order: searchParams.get('client_list_sort_order'),
				vendor_list_page_no : 1,
				vendor_list_sort_key: '',
				vendor_list_sort_order: ''
			}
			).toString() 
		} );
	}
	return (
		<>
			{clientList?.data?.map((itm, ind) => {
				return (
					<div key={`itm` + ind} className="flex gap-2 mb-2 tblLoop">
						<div className="flex-none">
							<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
								<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
							</label>
						</div>
						<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
							<div className="min-w-[120px] relative flex grow border-r pr-4 items-center">
								<span
									title={itm.name}
									className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
								>
									{/* {itm.name} */}
									<TextHighlighter text={itm.name} searchString={Search} />
								</span>
							</div>
							<div className="flex-none border-r pl-4 pr-8 text-center w-[140px]">
								{itm.total_vendors}
							</div>
							<div className="flex-none border-r pl-4 pr-8 text-center w-[140px]">
								{itm.total_invoices}
							</div>
							<div className="flex-none pl-4 pr-8 text-center w-[155px]">
								{itm.total_contracts}
							</div>
						</div>
						<div className="flex-none pr-2">
							<button
								
								onClick={() => handleNavigate(itm)}
								type="button"
								className="vLBG block px-4 py-4 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-200 disabled:text-gray-500"
							>
								View Vendors
							</button>
						</div>
					</div>
				);
			})}
		</>
	);
};
