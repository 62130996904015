import React from 'react';

function ContractAttentions() {
	return (
		<>
			<p className="text-xs text-gray-500 mb-2">
				Contracts which needs human attention
			</p>
			<p className="text-lg font-black text-primary mb-4">0</p>
		</>
	);
}

export default ContractAttentions;
