import React, { useState, useEffect, useCallback } from 'react';
import LoaderFull from '../shared/loader-full-screen';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { RECORDS_PER_PAGE } from '../../config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useDebouncedState } from '@mantine/hooks';
import { TextHighlighter } from '../shared/textBold';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useDeleteCategory, useDeleteProject } from './hooks';

function FieldsListTable(props) {
	const datacategories = props.dataCategories;
	const dataprojecttypes = props.dataProjectTypes;

	const navigate = useNavigate();

	const onClickCategoriesType = () => navigate(`/add-category`);

	const onClickProjectType = () => navigate(`/add-project-type`);

	const [tabIndex, setTabIndex] = useState(0);
	const [DebounceSearchProjectType, setDebounceSearchProjectType] =
		useDebouncedState('', 500);
	const [DebounceSearchCategory, setDebounceSearchCategory] = useDebouncedState(
		'',
		500
	);
	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	useEffect(() => {
		const urlSearchString = window.location.search;

		const params = new URLSearchParams(urlSearchString);

		setTabIndex(Number(params.get('tab')));
	}, []);

	useEffect(() => {
		dataprojecttypes.setArgs((p) => {
			return { ...p, search: DebounceSearchProjectType, page: 1 };
		});
	}, [DebounceSearchProjectType]);

	useEffect(() => {
		datacategories.setArgs((p) => {
			return { ...p, search: DebounceSearchCategory, page: 1 };
		});
	}, [DebounceSearchCategory]);

	//console.log( typeof(tabIndex));

	const renderList = useCallback(() => {
		if (datacategories.hook.isFetching) {
			return <LoaderFull />;
		} else if (datacategories.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<>
					{datacategories.hook?.data?.data.map((user, ind) => (
						<div key={`itm` + ind} className="flex gap-2 mb-2 tblLoop">
							<div className="flex-none">
								<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
									<img className="h-8" src="/svg/build-ico.svg" alt="" />
								</label>
							</div>
							<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
								<div className="grow pr-4">
									<span className="inline-block w-[300px] lg:w-[600px] text-ellipsis whitespace-nowrap overflow-hidden">
										{/* {user.name} */}
										<TextHighlighter
											text={user.name}
											searchString={DebounceSearchCategory}
										/>
									</span>
								</div>
							</div>
							<div className="flex-none flex gap-2 w-[200px]">
								<div>
									<button
										onClick={(event) => {
											handleDeleteCategory(event, user._id);
										}}
										type="button"
										className="bg-red-100 px-4 py-4 flex gap-2 text-center text-red-700 text-sm rounded-md font-black disabled:bg-gray-300"
									>
										<span className="material-icons text-sm">delete</span>
										<label>Delete</label>
									</button>
								</div>
								<div>
									<button
										onClick={() => navigate(`/edit-category/` + user._id)}
										type="button"
										className="vLBG px-4 py-4 flex gap-2 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-300"
									>
										<span className="material-icons text-sm">edit</span>
										<label>Edit</label>
									</button>
								</div>
							</div>
						</div>
					))}
				</>
			);
		}
	}, [datacategories.hook]);

	const delete_category_hook = useDeleteCategory(null);

	function handleDeleteCategory(e, id) {
		e.preventDefault();
		swal('Are you sure you want to delete this Category?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				delete_category_hook.mutate(id);
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}

	useEffect(() => {
		if (delete_category_hook.isError) {
			//toast.error(delete_category_hook.error?.message);
		} else if (delete_category_hook.isSuccess) {
			toast.success(delete_category_hook.data?.message);
			datacategories.hook.refetch();
		}
	}, [
		delete_category_hook.isError,
		delete_category_hook.isSuccess,
		delete_category_hook.error?.message
	]);

	//useDeleteProject
	const delete_project_hook = useDeleteProject(null);

	function handleDeleteProject(e, id) {
		e.preventDefault();
		swal('Are you sure you want to delete this Project Type?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button

				delete_project_hook.mutate(id);
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}

	useEffect(() => {
		if (delete_project_hook.isError) {
			toast.error(delete_project_hook.error?.message);
		} else if (delete_project_hook.isSuccess) {
			console.log('refectch');
			toast.success(delete_project_hook.data?.message);
			dataprojecttypes.hook.refetch();
		}
	}, [
		delete_project_hook.isError,
		delete_project_hook.isSuccess,
		delete_project_hook.error?.message,
	]);

	const renderList2 = useCallback(() => {
		if (dataprojecttypes.hook.isFetching) {
			return <LoaderFull />;
		} else if (dataprojecttypes.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<>
					{dataprojecttypes.hook?.data?.data.map((user, ind) => (
						<div key={`itm` + ind} className="flex gap-2 mb-2 tblLoop">
							<div className="flex-none">
								<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
									<img className="h-8" src="/svg/build-ico.svg" alt="" />
								</label>
							</div>
							<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
								<div className="grow pr-4">
									<span className="inline-block w-[300px] lg:w-[600px] text-ellipsis whitespace-nowrap overflow-hidden">
										{/* {user.name} */}
										<TextHighlighter
											text={user.name}
											searchString={DebounceSearchProjectType}
										/>
									</span>
								</div>
							</div>
							<div className="flex-none flex gap-2 w-[200px]">
								<div>
									<button
										onClick={(event) => {
											handleDeleteProject(event, user._id);
										}}
										type="button"
										className="bg-red-100 px-4 py-4 flex gap-2 text-center text-red-700 text-sm rounded-md font-black disabled:bg-gray-300"
									>
										<span className="material-icons text-sm">delete</span>
										<label>Delete</label>
									</button>
								</div>
								<div>
									<button
										onClick={() => navigate(`/edit-project-type/` + user._id)}
										type="button"
										className="vLBG px-4 py-4 flex gap-2 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-300"
									>
										<span className="material-icons text-sm">edit</span>
										<label>Edit</label>
									</button>
								</div>
							</div>
						</div>
					))}
				</>
			);
		}
	}, [dataprojecttypes.hook]);

	return (
		<>
			<div className="relative fldWrap">
				<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
					<TabList
						className={'ps-16 absolute top-[280px] left-[25px] lg:top-[150px]'}
					>
						{PrivilagesList.includes('manage_expense_category') && (
							<Tab>Categories</Tab>
						)}
						{PrivilagesList.includes('manage_project_type') && (
							<Tab>Project Types</Tab>
						)}
					</TabList>
					{PrivilagesList.includes('manage_expense_category') && (
						<TabPanel className="pt-0">
							<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
								<div className="lg:flex gap-8">
									<div className="pb-4 lg:pb-0">
										<h1 className="text-lg font-normal text-primary">
											List of <strong className="font-black">Category</strong>
										</h1>
										<p className="text-xs text-gray-500 mb-2">
											List of all your categories are given below -
										</p>
									</div>
									<div className="lg:border-l border-l-white lg:pl-8">
										<h1 className="text-lg font-normal text-primary">
											<strong className="font-black">
												{datacategories.hook?.data?.total}
											</strong>
										</h1>
										<p className="text-xs text-gray-500 mb-2">
											Total categories
										</p>
									</div>
								</div>
								<div className="flex gap-3 flex-wrap">
									<div>
										<div className="relative">
											<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
												search
											</span>
											<input
												type="text"
												onChange={(e) => {
													setDebounceSearchCategory(e.target.value);
												}}
												className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
												placeholder="Search..."
												defaultValue={datacategories.Args.search}
											/>
										</div>
									</div>
									<div>
										<button
											onClick={onClickCategoriesType}
											className="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white"
										>
											<span className="material-icons text-white text-sm mr-1">
												add
											</span>
											Add New Category
										</button>
									</div>
								</div>
							</div>

							<div className="p-6 pt-[80px]">
								<div className="pr-4 listDHeightFld overflow-auto">
									{renderList()}
								</div>

								<div className="flex justify-center">
									{!datacategories.hook.isLoading && (
										<Stack spacing={2}>
											<Pagination
												defaultPage={datacategories.Args.page}
												onChange={(e, value) => {
													datacategories.setArgs((p) => {
														return {
															...p,
															page: value,
														};
													});
												}}
												count={Math.ceil(
													datacategories.hook.data?.total / RECORDS_PER_PAGE
												)}
												color="primary"
												variant="outlined"
												shape="rounded"
											/>
										</Stack>
									)}
								</div>
							</div>
						</TabPanel>
					)}
					{PrivilagesList.includes('manage_project_type') && (
						<TabPanel>
							<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
								<div className="lg:flex gap-8">
									<div className="pb-4 lg:pb-0">
										<h1 className="text-lg font-normal text-primary">
											List of{' '}
											<strong className="font-black">Project Type</strong>
										</h1>
										<p className="text-xs text-gray-500 mb-2">
											List of all your project types are given below -
										</p>
									</div>
									<div className="lg:border-l border-l-white lg:pl-8">
										<h1 className="text-lg font-normal text-primary">
											<strong className="font-black">
												{dataprojecttypes.hook?.data?.total}
											</strong>
										</h1>
										<p className="text-xs text-gray-500 mb-2">
											Total project types
										</p>
									</div>
								</div>
								<div className="flex gap-3 flex-wrap">
									<div>
										<div className="relative">
											<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
												search
											</span>
											<input
												type="text"
												onChange={(e) => {
													setDebounceSearchProjectType(e.target.value);
												}}
												className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
												placeholder="Search..."
												defaultValue={dataprojecttypes.Args.search}
											/>
										</div>
									</div>
									<div>
										<button
											onClick={onClickProjectType}
											className="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white"
										>
											<span className="material-icons text-white text-sm mr-1">
												add
											</span>
											Add New Project Type
										</button>
									</div>
								</div>
							</div>

							<div className="p-6 pt-[80px]">
								<div className="pr-4 listDHeightFld overflow-auto">
									{renderList2()}
								</div>

								<div className="flex justify-center">
									{!dataprojecttypes.hook.isLoading && (
										<Stack spacing={2}>
											<Pagination
												defaultPage={dataprojecttypes.Args.page}
												onChange={(e, value) => {
													// dispatch(dispClientPageNo({ clientListPageNo: value }))
													dataprojecttypes.setArgs((p) => {
														return {
															...p,
															page: value,
														};
													});
												}}
												count={
													parseInt(
														dataprojecttypes.hook.data?.total / RECORDS_PER_PAGE
													) + 1
												}
												color="primary"
												variant="outlined"
												shape="rounded"
											/>
										</Stack>
									)}
								</div>
							</div>
						</TabPanel>
					)}
				</Tabs>
			</div>
		</>
	);
}

export default FieldsListTable;
