import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useAddVendorName } from './hooks';

export const PopupAddVendor = ({
	setIsOpened,
	vendor_list_hook,
	client_id,
}) => {

	const [vendorName, setvendorName] = useState('');
	const add_vendor_name = useAddVendorName(setIsOpened);
	const [focused, setfocused] = useState(false);

	function saveChanges() {
		add_vendor_name.mutate({ name: vendorName.trim(), client_id: client_id });
	}

	function checkValidation(str) {
		let index = str.indexOf('/');
		if (index !== -1) {
			return 'Vendor Name should be text value';
		} else {
			return '';
		}
	}


	return (
		<>
			<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="relative my-6 mx-auto w-auto lg:w-[800px]">
					{/*content*/}
					<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						{/*header*/}
						<div className="p-5 rounded-t">
							<h3 className="text-2xl m-0 font-semibold text-primary text-center">
								Create New Vendor
							</h3>
							<button
								className="bg-transparent border-0 text-slate-400 leading-none font-semibold outline-none focus:outline-none absolute top-4 right-5"
								onClick={() => setIsOpened(false)}
								title="Close"
							>
								<span className="material-icons-outlined modalClose">
									cancel
								</span>
							</button>
						</div>
						{/*body*/}
						<div className="relative pb-8 px-6 lg:w-[400px] mx-auto">
							<div className="py-2">
								<div className="flex gap-3">
									<div className="flex-none">
										<label className="vLBG flex items-center px-2 h-[44px] rounded-md text-center text-white relative text-sm">
											<img
												className="h-8"
												src="/svg/ri_building-fill.svg"
												alt=""
											/>
										</label>
									</div>
									<div className="grow">
										<TextField
											onFocus={() => setfocused(true)}
											placeholder="Vendor Name should be text value"
											id="vendorname"
											value={vendorName}
											onChange={(e) => setvendorName(e.target.value)}
										/>
										{focused && (
											<small className="text-gray-400">
												{checkValidation(vendorName)}
											</small>
										)}
									</div>
								</div>
								<div className="pt-4">
									<button
										type="button"
										disabled={
											add_vendor_name.isPending ||
											checkValidation(vendorName) !== ''
										}
										onClick={saveChanges}
										className="bg-blue-800 hover:bg-indigo-700 px-8 py-3 rounded-md text-white text-sm flex w-full items-center justify-center font-black disabled:bg-gray-400"
									>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
		</>
	);
};
