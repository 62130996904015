import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useRemapInvoices, useVendorNames } from './hooks';

export const RemapPopUp = ({ invoiceIds, clientID, vendorName, vendorID, setIsOpened, invoices_list_hook }) => {
    const {hook:vendor_names_hook ,setArgs} = useVendorNames(clientID)
    const [newVendorName, setnewVendorName] = useState(null)
    const [newVendorId, setnewVendorId] = useState(null)


    const [options, setoptions] = useState([]);
    const [Focus, setFocus] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const [firstTime, setfirstTime] = useState(true);
    const remap_invoices_hook = useRemapInvoices(setIsOpened)

    useEffect(() => {
        if (vendor_names_hook.data) {
            setfirstTime(false);
            setoptions(vendor_names_hook.data.data.filter(itm => itm.vendorId !==vendorName));
        }
    }, [vendor_names_hook.data, vendor_names_hook.isSuccess,vendorName]);

    useEffect(() => {
        setArgs((args) => {
            return {
                ...args,
                search: inputValue,
            };
        });
    }, [inputValue,setArgs]);

    let stop = false;




    function saveChanges() {
        let body = {
            invoice_ids: invoiceIds,
            prev_vendor_id: vendorID,
            new_vendor_id: newVendorId,
            new_vendor_name: newVendorName,
            client_id: clientID
        }
        remap_invoices_hook.mutate(body)

    }




    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto w-auto lg:w-[800px]">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none z-[999]">
                        {/*header*/}
                        <div className="p-5 rounded-t">
                            <h3 className="text-2xl m-0 font-semibold text-primary text-center">
                                Remap vendor
                            </h3>
                            <button
                                className="bg-transparent border-0 text-slate-400 leading-none font-semibold outline-none focus:outline-none absolute top-4 right-5"
                                onClick={() => setIsOpened(false)}
                                title="Close"
                            >
                                <span className="material-icons-outlined modalClose">
                                    cancel
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative pb-8 px-6 lg:w-[400px] mx-auto">
                            <div className="py-2">
                                <div className="flex gap-3 mb-4">
                                    <div className="flex-none flex items-center">
                                        <label className="vLBG p-2 rounded-md text-center text-primary font-bold relative text-sm w-[54px]">
                                            From
                                        </label>
                                    </div>
                                    <div className="grow">
                                        <TextField
                                            id="prevVendorName"
                                            value={vendorName}
                                        />
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className='flex gap-3'>
                                        <div className="flex-none flex items-center">
                                            <label className="vLBG p-2 rounded-md text-center text-primary font-bold relative text-sm w-[54px]">
                                                To
                                            </label>
                                        </div>
                                        <div className='grow'>
                                            {firstTime && vendor_names_hook.isPending ? (
                                                <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                                                    Vendor Name{' '}
                                                    <span className="text-sm text-slate-400 ps-4 inline-block">
                                                        Loading...
                                                    </span>
                                                </p>
                                            ) : (
                                                <Autocomplete
                                                    disablePortal
                                                    id="vendor-auto-complete"
                                                    clearOnBlur={true}
                                                    getOptionLabel={(option) =>
                                                        typeof option === 'string' ? option : option.vendorId
                                                    }
                                                    isOptionEqualToValue={(option, value) => true}
                                                    filterOptions={(x) => x}
                                                    options={options}
                                                    autoComplete
                                                    includeInputInList
                                                    filterSelectedOptions
                                                    value={newVendorName}
                                                    open={open}
                                                    onOpen={() => {
                                                        setOpen(true);
                                                    }}
                                                    onClose={() => {
                                                        setOpen(false);
                                                    }}
                                                    noOptionsText="No Vendor Match"
                                                    onChange={(event, newValue) => {
                                                        if (newValue) {
                                                            setnewVendorName(newValue?.vendorId);
                                                            setnewVendorId(newValue?._id);
                                                        } else {
                                                            setnewVendorName(null);
                                                            setnewVendorId(null);
                                                        }
                                                    }}
                                                    onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Vendor Name *" fullWidth />
                                                    )}
                                                    onFocus={() => setFocus(true)}
                                                    onBlur={() => setFocus(false)}
                                                    renderOption={(props, option) => {                                                        
                                                        if (stop) return;
                                                        if (vendor_names_hook.isPending) {
                                                            // Stop further iteration if stop flag is true
                                                            stop = true;
                                                            return (
                                                                <p key={'loading_vendor'} className="ml-2">
                                                                    Loading...
                                                                </p>
                                                            );
                                                        }

                                                        return (
                                                            <li key={option.vendorId} {...props}>
                                                                {option.vendorId}
                                                            </li>
                                                        );
                                                    }}
                                                />
                                            )}
                                            <div className='pt-0'>
                                                {(newVendorName == null || newVendorName === '') && Focus && (
                                                    <small className="text-red-600 ">Vendor name is required</small>
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="pt-2">
                                    <button
                                        type="button"
                                        disabled={remap_invoices_hook.isPending || newVendorId === null}
                                        onClick={saveChanges}
                                        className="bg-blue-800 hover:bg-indigo-700 px-8 py-3 rounded-md text-white text-sm flex w-full items-center justify-center font-black disabled:bg-gray-400"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};
