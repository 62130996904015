import React from 'react';
import FieldsCategoryForm from './field-category-form';

function AddCategory() {
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<FieldsCategoryForm />
			</div>
		</>
	);
}

export default AddCategory;
