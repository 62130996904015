import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {  useState } from 'react';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { useMsal } from '@azure/msal-react';

export const useGetMismatch = (mismatch_id) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	let hook=  useQuery({
		queryKey: [`mis_detail_${mismatch_id}`],
		retry: false,
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'get_mismatch_detail_by_id'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {mismatch_id: mismatch_id},
					signal: signal,
				};
				const responseData = await axiosInstance.get('/get_mismatch_detail_by_id',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			}
		},
	});
	return {
		hook: hook , 
	}
};

export const useUpdateMismatchStatus = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'update_mismatch_status'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/update_mismatch_status`, body);
				let message = responseData?.message ? responseData?.message : 'Status updated Successfully'
				message && toast.success(message , {toastId: TOAST_ID})
				return responseData
				
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				
			}
		},
	});
};

export const useGetImageUrl = (url_string) => {
    return useQuery({
		queryFn: async () => {
			try {
				const TOAST_ID = 'sas_url_by_blob_key'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {blob_name: url_string},
				};
				const responseData = await axiosInstance.get('/sas_url_by_blob_key',config)
				return responseData
			} catch (error) {
				
			}
		},
		retry: false,
	});

}


export const useGetImgUrlByPage = (imgUrls) => {
	const [imgObj, setimgObj] = useState(() => {
		let ret = {};
		Object.keys(imgUrls).map((image_path) => {
			ret[image_path] = {
				image_blob: image_path,
				image_url: null,
                image_height: imgUrls[image_path].image_height,
                image_width: imgUrls[image_path].image_width,

			};
		return null 
		});
		return ret;
	});
	const hook = useMutation({
		mutationFn: async (image_path) => {
			if (imgObj[image_path].image_url !== null) {
				return {
					connection_string: imgObj[image_path].image_url,
				};
			} else {
				try {
					const TOAST_ID = 'sas_url_by_blob_key'
					const axiosInstance = buildAxiosInstance(TOAST_ID)
					let config = {
						params: {blob_name: imgObj[image_path].image_blob},
					};
					const responseData = await axiosInstance.get('/sas_url_by_blob_key',config)
					
					setimgObj((prev) => {
						return {
							...prev,
							[image_path]: {
								...prev[image_path],
								image_url: responseData['connection_string'],
							},
						};
					});
					return responseData
				} catch (error) {
					
				}
			}
		},
		retry: false,
	});
	return { hook, imgObj, setimgObj };
};



export const useGetNavigationIds = () => {
	const dispatch = useDispatch();
	const controller = new AbortController();
    const signal = controller.signal;
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (mismatch_id) => {
			try {
				const TOAST_ID = 'navigate_mismatch'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {mismatch_id:  mismatch_id},
					signal: signal,
				};
				const responseData = await axiosInstance.get('/navigate_mismatch',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			}
		},
	});

};
