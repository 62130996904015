import React from 'react';
//import { useNavigate } from "react-router-dom";
import EditUserForm from './edit-user-form';

function EditUser() {
	//const navigate = useNavigate()

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<EditUserForm />
			</div>
		</>
	);
}

export default EditUser;
