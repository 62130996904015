import React, { useState, useEffect } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { useGetBatches } from './hooks';

function FilterBatchName({
    setBatchName,
	BatchName,
	setBatchNameId,
	BatchNameId,
    setBatchList,
    setReportId,
	setReportName,
    setReportNameNew
}) {

    //const [lable,setLable] = useState('Batch Name')
    
    const [options, setoptions] = useState([]);
	const  batch_list_hook = useGetBatches();
	const [, setFocus] = useState(false);

	useEffect(() => {
		if (batch_list_hook.data) {
			setoptions(batch_list_hook.data.data.map((itm) => (itm.name)));
            setBatchList(batch_list_hook.data.data);
		}
	}, [batch_list_hook.data, setBatchList]);


    function changeBatch(val) {
		setBatchName(val);
		let obj = batch_list_hook.data.data.find((o) => (o._id === val || o.name === val));
        
		if (obj) {
			setBatchNameId(obj._id);
		}
        else{
            setBatchNameId(null);
        }
        setReportId(null);
		setReportName(null);
        setReportNameNew(null)
	}

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            {batch_list_hook.isPending ? (
                                <p className="px-4 h-[38px] flex flex-col items-center justify-center border border-1 border-slate-300 rounded-md">
                                    {/* Batch Name{' '} */}
                                    <span className="text-[8px] text-slate-400 ps-1 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) =>
                                        option === value || value === '' || value == null
                                    }
                                    id="batch"
                                    className='expCat'
                                    options={options}
                                    value={BatchName}
                                    renderInput={(params) => (
                                        <TextField {...params} label={'Batch Name'} />
                                    )}
                                    onChange={(event, value) => changeBatch(value)}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    // onInputChange={(event, newInputValue) => {
                                    //     setProjectType(newInputValue);
                                    //   }}
                                />
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterBatchName;
