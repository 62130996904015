import React, { useEffect, useState } from 'react'
import { useGetLicenceStatus } from '../upload-docs/hooks'
import styles from './common.module.css'
export const LicencePopup = ({setpadding}) => {

    const get_licence_status_hook = useGetLicenceStatus()
    const [licenceStatus, setlicenceStatus] = useState("pending")

    useEffect(() => {
        if (get_licence_status_hook.fetchStatus === 'idle') {
            if (get_licence_status_hook.status === 'success') {
                if ("valid" === get_licence_status_hook?.data?.message) {
                    setlicenceStatus("valid")
                    setpadding(false)
                }
                else if (get_licence_status_hook?.data?.status ===403){
                    setlicenceStatus("invalid")
                    setpadding(true)
                }
                else {
                    setlicenceStatus("error")
                }
            }
            else if (get_licence_status_hook.status === 'error') {
                setlicenceStatus("error")
            }
            else if (get_licence_status_hook.status === 'pending') {
                setlicenceStatus("retreving")
            }
        }
        else if (get_licence_status_hook.fetchStatus === 'fetching') {
            setlicenceStatus("retreving")
        }

    }, [get_licence_status_hook.fetchStatus, get_licence_status_hook.status, get_licence_status_hook?.data, setpadding])

    return (
        <>
            {licenceStatus === "invalid" && <div className={`${styles.infoMessage} z-50 absolute top-4 bg-white rounded-md`}> <div className='rounded-md border-red-300 border-2 px-5 py-2 text-red-400 flex justify-between items-center '>
                <p>Your License has expired, please contact the administrator or renew it.</p>
                <span onClick={() => {
                    setpadding(false)
                    setlicenceStatus("pending")

                }} className="mr-1 material-icons text-sm cursor-pointer ">cancel</span>
            </div>
            </div>
             }
        </>
    )
}
