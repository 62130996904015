import axios from 'axios';
import { BACKEND_URL } from '../../config';
import { useInfiniteQuery, useQuery, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useDebouncedState } from '@mantine/hooks';
import { redux_logout } from '../auth/authSlice';
import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
const fetchinvoicesList = async (pageParam, Args, dispatch, signal) => {
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${BACKEND_URL}/invoice_list_by_status`,
		params: {
			search: Args.search,
			sort: Args.sort,
			page: pageParam,
			limit: RECORDS_PER_PAGE,
			status:'failed'
		},
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${Args.auth_token}`,
		},
		signal: signal,
	};
	try {
		const response = await axios.request(config);

		if (response.status >= 200 && response.status < 300) {
			return response.data;
		} else {
			// Server responded with an error status
			throw new Error(`Server responded with status ${response.status}`);
		}
	} catch (error) {
		if (error.response) {
			if (["Session Expired" , "Unable to verify token" , "User not registered"].includes(error.response.data.message)) {
				toast.error(error.response.data.message, {
					onClose: () => {
						localStorage.clear();
						dispatch(redux_logout());
						window.location.href = '/'
						window.location.reload()
					},
				});
			} else {
				toast.error(`Error: ${error.response.data.message}`);
			}
		} else if (error.request) {
			//toast.error("No response received from the server. Please try again later.");
		} else {
			toast.error(`An error occurred: ${error.message}`);
		}
		throw new Error(`${error.response.data.message}`);
	}
};

export const useInvoiceslist = (clientID, vendorId) => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const [Args, setArgs] = useDebouncedState(
		{
			auth_token: localStorage.getItem('azure_access_token'),
			search: '',
			sort: '',
		},
		2000
	);
	const hook = useInfiniteQuery({
		queryKey: [`invoiceslistbystatus${clientID}_${vendorId}`, Args],
		queryFn: async ({ pageParam = 1 }) =>
			fetchinvoicesList(pageParam, Args, dispatch),
		getNextPageParam: (lastPage, allPages) => {
			if (allPages.length === 0) {
				return 1;
			} else if (allPages[allPages.length - 1].length === 0) {
				return allPages.length;
			} else {
				return allPages.length + 1;
			}
		},
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

export const useInvoiceslistPagewise = (clientID, vendorId,categoryName, pageNo, sortKey) => {
	//const Data = useSelector(state => state.auth)
	let dispatch = useDispatch();
	const [Args, setArgs] = useState({
		auth_token: localStorage.getItem('azure_access_token'),
		search: '',
		sort: sortKey,
		page: pageNo,
	});
	const hook = useQuery({
		queryKey: ['invoiceslistbystatus', clientID, vendorId,categoryName, Args],
		queryFn: async ({ signal }) =>
			fetchinvoicesList(Args.page, Args, dispatch, signal),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

export const useDeleteInvoice = () => {
	//const Data = useSelector(state => state.auth)
	return useMutation({
		//queryKey: ['get_user_by_id',id],
		retry: false,
		mutationFn: async (body) => {
			try {
				let config = {
					method: 'delete',
					maxBodyLength: Infinity,
					url: `${BACKEND_URL}/delete_invoice_entities`,
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + localStorage.getItem('azure_access_token'),
					},
					data: JSON.stringify(body),
				};

				const response = await axios.request(config);

				if (response.status >= 200 && response.status < 300) {
					// Successful response
					return response.data;
				} else {
					// Server responded with an error status
					throw new Error(`Server responded with status ${response.status}`);
				}
			} catch (error) {
				if (error.response) {
					if (["Session Expired" , "Unable to verify token" , "User not registered"].includes(error.response.data.message)) {
						toast.error(error.response.data.message, {
							onClose: () => {
								// localStorage.clear();
								// dispatch(redux_logout());
								// window.location.href = '/'
								// window.location.reload()
							},
						});
					} else {
						toast.error(`Error: ${error.response.data.message}`);
					}
				} else if (error.request) {
					//toast.error("No response received from the server. Please try again later.");
				} else {
					toast.error(`An error occurred: ${error.message}`);
				}
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useSoftDeleteFailedFiles = () => {
	return useMutation({
		//queryKey: ['get_user_by_id',id],
		retry: false,
		mutationFn: async (body) => {
			try {
				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${BACKEND_URL}/delete_failed_files`,
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + localStorage.getItem('azure_access_token'),
					},
					data: JSON.stringify(body),
				};

				const response = await axios.request(config);

				if (response.status >= 200 && response.status < 300) {
					// Successful response
					return response.data;
				} else {
					// Server responded with an error status
					throw new Error(`Server responded with status ${response.status}`);
				}
			} catch (error) {
				if (error.response) {
					if (["Session Expired" , "Unable to verify token" , "User not registered"].includes(error.response.data.message)) {
						toast.error(error.response.data.message, {
							onClose: () => {
								// localStorage.clear();
								// dispatch(redux_logout());
								// window.location.href = '/'
								// window.location.reload()
							},
						});
					} else {
						toast.error(`Error: ${error.response.data.message}`);
					}
				} else if (error.request) {
					//toast.error("No response received from the server. Please try again later.");
				} else {
					toast.error(`An error occurred: ${error.message}`);
				}
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const useGetSasToken = () => {
	//const Data = useSelector(state => state.auth)
	return useMutation({
		//queryKey: ['get_user_by_id',id],
		retry: false,
		mutationFn: async (body) => {
			
			try {
				let config = {
					method: 'GET',
					maxBodyLength: Infinity,
					url: `${BACKEND_URL}/generate_azure_sas_url_for_view_file`,
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + localStorage.getItem('azure_access_token'),
					},
					data: JSON.stringify(body),
				};

				const response = await axios.request(config);

				if (response.status >= 200 && response.status < 300) {
					// Successful response
					let Obj = {...response.data, ...body}
					return Obj;
				} else {
					// Server responded with an error status
					throw new Error(`Server responded with status ${response.status}`);
				}
			} catch (error) {
				if (error.response) {
					if (["Session Expired" , "Unable to verify token" , "User not registered"].includes(error.response.data.message)) {
						toast.error(error.response.data.message, {
							onClose: () => {

							},
						});
					} else {
						toast.error(`Error: ${error.response.data.message}`);
					}
				} else if (error.request) {
					//toast.error("No response received from the server. Please try again later.");
				} else {
					toast.error(`An error occurred: ${error.message}`);
				}
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};