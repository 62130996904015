import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {
	createSearchParams,
	useNavigate,
	useOutletContext,
	useSearchParams,
} from 'react-router-dom';
import { TextHighlighter } from '../shared/textBold';
import { decodeName, encodeName } from '../utils';

export const ContractsListingData = () => {

	const [toggle, setToggle] = useState({});


	const { contractList, Search, selectedContracts, setselectedContracts } = useOutletContext();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	function handleNavigate(obj) {
		const encodedClientName = encodeName(decodeName(searchParams.get('client_name')))
		const encodedVendorName = encodeName(decodeName(searchParams.get('vendor_name')))
		const encodeContractName = encodeName(obj.fname);

		navigate({
			pathname: '/my-contract-details',
			search: createSearchParams({
				client_id: searchParams.get('client_id'),
				client_name: encodedClientName,
				client_list_page_no: searchParams.get('client_list_page_no'),
				client_list_sort_key: searchParams.get('client_list_sort_key'),
				client_list_sort_order: searchParams.get('client_list_sort_order'),

				vendor_id: searchParams.get('vendor_id'),
				vendor_name: encodedVendorName,
				vendor_list_page_no: searchParams.get('vendor_list_page_no'),
				vendor_list_sort_key: searchParams.get('vendor_list_sort_key'),
				vendor_list_sort_order: searchParams.get('vendor_list_sort_order'),
				category_name: encodeName(decodeName(searchParams.get('category_name'))),
				category_id: encodeName(decodeName(searchParams.get('category_id'))),

				contract_id: obj._id,
				contract_name: encodeContractName,
				contract_list_page_no: searchParams.get('contract_list_page_no'),
				contract_list_sort_key: searchParams.get('contract_list_sort_key'),
				contract_list_sort_order: searchParams.get('contract_list_sort_order'),



			}
			).toString()
		})
	}

	function handleCheckBoxRowwise(checked, doc_id) {
		if (checked) {
			let tp = new Set(selectedContracts)
			tp.add(doc_id)
			setselectedContracts(tp)
		}
		else {
			let tp = new Set(selectedContracts)
			tp.delete(doc_id)
			setselectedContracts(tp)
		}
	}

	function handleToggle(masterId) {
		setToggle(prev => {
			let ob = {...prev}
			if (masterId in ob){
				ob[masterId]=!ob[masterId]
			}
			else{
				ob[masterId] = true 
			}
			return ob
		})
	}


	return (
		<>
			{contractList?.data?.map((itm, ind) => {
				return (
					<div key={`vendor` + ind}>
						<div className='tblLoop flex gap-2 mb-2'>
							<div className="flex-none w-[40px] flex items-center">
								<Checkbox onChange={(e) => handleCheckBoxRowwise(e.target.checked, itm.master._id)} checked={selectedContracts.has(itm.master._id)} size="small" />
							</div>
							<div className="flex-none w-[50px]">
								<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
									<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
								</label>
							</div>
							<div className='flex-none flex items-center nonShadow'>
								{itm.child.length >0 &&
								<button
									onClick={() => handleToggle(itm.master._id)}
								>
									{toggle[itm.master._id] ?
										<span className="material-icons text-md text-black">keyboard_arrow_up</span>
										:
										<span className="material-icons text-md text-black">keyboard_arrow_down</span>
									}
								</button> }
							</div>
							<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
								<div className="min-w-[150px] relative flex grow border-r pr-4 items-center">
									<span
										title={itm.master.fname}
										className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
									>
										{/* {itm.master.fname} */}
										<TextHighlighter text={itm.master.fname} searchString={Search} />
									</span>
								</div>
								<div className="flex-none border-r px-4 text-center w-[160px] relative flex items-center">
									<span
										title={itm.master.uploaded_by}
										className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
									>
										{itm.master?.uploaded_by}
									</span>
								</div>
								<div className="flex-none border-r px-4 text-center w-[140px] relative flex items-center">
									<span
										title={itm.master.disp_status}
										className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
									>
										{itm?.master.locked_by ? 'Annotating' : itm?.master.disp_status}
									</span>
								</div>

								<div className="flex-none border-r px-4 text-center w-[160px]">
									{formatDate(itm.master.uploaded_at)}
								</div>
								<div className="flex-none border-r px-4 text-center w-[160px]">
									{formatDate(itm.master.updated_at)}
								</div>
								<div className="flex-none border-r px-4 text-center w-[160px]">
									{formatDate(itm.master.published_on)}
								</div>

								{itm.master.percentage_complete === 100 ? <div className="flex-none px-4 text-center w-[150px] text-xs">
									<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
									<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
									<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
									{itm.master.percentage_complete}%
								</div> :
									<div className="flex-none px-4 text-center w-[150px] text-xs">
										<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
										<span className="inline-block w-2 h-2 rounded-full bg-gray-400 mx-1"></span>
										<span className="inline-block w-2 h-2 rounded-full bg-gray-200 mx-1"></span>
										{itm.master.percentage_complete}%
									</div>}
								{/* <div className="flex-none border-r px-4 text-center w-[150px] text-sm">
								{itm.master?.null_value}
							</div> */}
							</div>
							<div className="flex-none flex w-[72px] pr-2">
								<button
									disabled={itm.master.percentage_complete < 100}
									onClick={() => handleNavigate(itm.master)}
									type="button"
									title={itm.master.percentage_complete < 100 ? 'Processing Contract' : ''}
									className="vLBG block px-4 py-3 text-center text-primary text-sm rounded-md font-black mx-1 disabled:bg-gray-200 disabled:text-gray-500"
								>
									View
								</button>

							</div>
						</div>


						{toggle[itm.master._id] && (
							<>
								{
									itm.child.map(childItm => {
										return (
											<div key={childItm._id} className='tblLoop flex gap-2 mb-2 loopChild'>
												<div className='flex-none w-[87px]'>
													&nbsp;
												</div>

												<div className="flex-none w-[36px] flex items-center justify-end">
													<Checkbox onChange={(e) => handleCheckBoxRowwise(e.target.checked, childItm._id)} checked={selectedContracts.has(childItm._id)} size="small" className='ml-auto' />
												</div>

												<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between h-14">
													<div className="min-w-[150px] relative flex grow border-r pr-4 items-center">
														<span
															title={childItm.fname}
															className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
														>
															{/* {itm.master.fname} */}
															<TextHighlighter text={childItm.fname} searchString={Search} />
														</span>
													</div>
													<div className="flex-none border-r px-4 text-center w-[160px] relative flex items-center">
														<span
															title={childItm.uploaded_by}
															className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
														>
															{childItm?.uploaded_by}
														</span>
													</div>
													<div className="flex-none border-r px-4 text-center w-[140px] relative flex items-center">
														<span
															title={childItm.disp_status}
															className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden"
														>
															{childItm.locked_by ? 'Annotating' : childItm.disp_status}
														</span>
													</div>
													<div className="flex-none border-r px-4 text-center w-[160px]">
														{formatDate(childItm.uploaded_at)}
													</div>
													<div className="flex-none border-r px-4 text-center w-[160px]">
														{formatDate(childItm.updated_at)}
													</div>
													<div className="flex-none border-r px-4 text-center w-[160px]">
														{formatDate(childItm.published_on)}
													</div>

													{childItm.percentage_complete === 100 ? <div className="flex-none px-4 text-center w-[150px] text-xs">
														<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
														<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
														<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
														{childItm.percentage_complete}%
													</div> :
														<div className="flex-none px-4 text-center w-[150px] text-xs">
															<span className="inline-block w-2 h-2 rounded-full bg-gray-600 mx-1"></span>
															<span className="inline-block w-2 h-2 rounded-full bg-gray-400 mx-1"></span>
															<span className="inline-block w-2 h-2 rounded-full bg-gray-200 mx-1"></span>
															{childItm.percentage_complete}%
														</div>}
													{/* <div className="flex-none border-r px-4 text-center w-[150px] text-sm">
								{itm.master?.null_value}
							</div> */}
												</div>
												<div className="flex-none flex w-[72px] pr-2">
													<button
														disabled={childItm.percentage_complete < 100}
														onClick={() => handleNavigate(childItm)}
														type="button"
														title={childItm.percentage_complete < 100 ? 'Processing Contract' : ''}
														className="vLBG block px-4 py-3 text-center text-primary text-sm rounded-md font-black mx-1 disabled:bg-gray-200 disabled:text-gray-500"
													>
														View
													</button>

												</div>
											</div>
										)
									})
								}
							</>

						)}
					</div>


				);
			})}
		</>
	);
};

function formatDate(inputDateString) {

	if (!inputDateString) return '-'
	// Create a Date object from the input date string
	var date = new Date(inputDateString);

	// Extract day, month, and year
	var day = date.getDate();
	var month = date.getMonth() + 1; // Note: getMonth() returns 0-indexed month, so we add 1
	var year = date.getFullYear();

	// Format day and month to have leading zeros if necessary
	day = day < 10 ? '0' + day : day;
	month = month < 10 ? '0' + month : month;

	// Construct the formatted date string
	var formattedDateString = month + '/' + day + '/' + year;

	// Return the formatted date string
	return formattedDateString;
}
