import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddCategory } from './hooks';

function FieldsCategoryForm() {
	const [name, setName] = React.useState('');
	const [description, setDescription] = React.useState('');

	const navigate = useNavigate();

	const onClickHandler = () => navigate(`/manage-fields?tab=0`);
	const add_new_category = useAddCategory();

	function saveChanges() {
		setDisable(true);
		add_new_category.mutate({ name: name.trim(), description: description });
	}

	useEffect(() => {
		if (add_new_category.isSuccess) {
			setName('');
			setDescription('');

			setTimeout(() => {
				navigate(`/manage-fields?tab=0`);
			}, 2000);
		} else if (add_new_category.isError) {
			setDisable(false);
		}
	}, [add_new_category.isSuccess, add_new_category.isError, navigate]);

	const [Disable, setDisable] = useState(true);
	useEffect(() => {
		if (name !== '' && name !== null) {
			setDisable(false);
		} else {
			setDisable(true);
		}
	}, [name]);

	return (
		<>
			<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
				<div className="lg:flex gap-8">
					<div className="pb-4 lg:pb-0">
						<div className="flex gap-3">
							<div className="flex-none pt-1">
								<button onClick={onClickHandler} className="text-primary">
									<span className="material-icons text-md">arrow_back</span>
								</button>
							</div>
							<div>
								<h1 className="text-lg font-normal text-primary">
									Add a <strong className="font-black">New Category</strong>
								</h1>
								<p className="text-xs text-gray-500 mb-2">
									Please fill the details given below -
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-none">
					<div>
						<button
							disabled={Disable}
							onClick={saveChanges}
							className="bg-blue-800 hover:bg-indigo-700 flex gap-2 items-center px-4 h-[40px] text-sm font-black rounded-md text-white disabled:bg-slate-300 disabled:text-slate-700"
						>
							<span className="material-icons text-white text-sm">check</span>
							<label>Save and Submit</label>
						</button>
					</div>
				</div>
			</div>
			<div className="p-6">
				<div className="max-w-md m-auto pt-6">
					<h4 className="text-primary text-lg mb-4">Create New Category</h4>
					<form name="addField">
						<div className="mb-6">
							<label className="block mb-2 text-black text-md">
								Category Name
							</label>
							<div className="border border-slate-300 rounded-lg flex gap-4">
								<label className="bg-slate-100 border-r border-slate-300 flex items-center justify-center px-3 size-11 rounded-lg text-black text-md">
									<img className="h-4" src="/svg/build-ol.svg" alt="" />
								</label>
								<div className="grow pr-6">
									<input
										value={name}
										onChange={(e) => setName(e.target.value)}
										type="text"
										className="h-11 text-black outline-0 w-full"
										placeholder="Enter here"
									/>
								</div>
							</div>
							{/* <label className='pt-2 text-xs text-red-600'>This field is required.</label> */}
						</div>

						<div className="mb-6">
							<label className="block mb-2 text-black text-md">
								Description (optional)
							</label>
							<textarea
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								placeholder="Enter here"
								className="w-full border border-slate-300 rounded-lg px-4 py-2 text-black"
								rows={6}
								cols={12}
							></textarea>
							{/* <label className='pt-2 text-xs text-red-600'>This field is required.</label> */}
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default FieldsCategoryForm;
