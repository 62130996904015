import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoaderFull from '../shared/loader-full-screen';
import { useMismatchListPagewise } from './hooks';
import { Outlet, useSearchParams,useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import FilterFinalComponent from './filterFinalComp'
import swal from 'sweetalert';
import { useExportMismatch,useGetReportlist,useGetFinalReportlist,useExportFinalMismatch } from './hooks';
import { useDiscardFiles, useReprocessFiles } from './hooks';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { handleSearchKeys, handleSortUtil } from '../utils';


const SORT_KEY_CONST = 'mismatch_list_sort_key'
const SORT_ORDER_CONST = 'mismatch_list_sort_order'
const PAGE_NO_CONST = 'mismatch_list_page_no'
function ListFinalMismatchsIndex() {

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const export_mismatch_hook = useExportMismatch()
	const export_final_mismatch_hook = useExportFinalMismatch()
	const [selectedInvoices, setselectedInvoices] = useState(new Set([]))
	const [selectedMismatches, setselectedMismatches] = useState(new Set([]))
	const [searchParams, setSearchParams] = useSearchParams();
	const [Args, setArgs] = useState({})
	const [reportId, setReportId] = useState(null);
	const mismatch_list_hook = useMismatchListPagewise();
	const report_list_hook = useGetReportlist();
	const final_report_list_hook = useGetFinalReportlist();
	const discard_files_hook = useDiscardFiles()
	const reprocess_files_hook = useReprocessFiles()
	const isFirstRender = useRef(true)

	const [Sort, setSort] = useState(handleSearchKeys(searchParams,SORT_KEY_CONST,SORT_ORDER_CONST,PAGE_NO_CONST ));	
	
	function handleSort(typer) {
		let {sort_key ,sort_order} = handleSortUtil(typer , Sort)
		searchParams.set(SORT_KEY_CONST, sort_key)
		searchParams.set(SORT_ORDER_CONST, sort_order)
		setSearchParams(searchParams)
		let body = {
			...Args,
			...Sort,
			'sort': (() => {
				if (['',null,'null'].includes(sort_key)) {
					return ''
				}
				else {
					return [sort_key,sort_order].join(' ').trim()
				}
			})(),

		}
		if ('client_id' in body && body['client_id']) {
			mismatch_list_hook.hook.mutate(body)
		}

	}

	useEffect(() => {
		if (!isFirstRender.current) {
			let newSort = handleSearchKeys(searchParams, SORT_KEY_CONST, SORT_ORDER_CONST, PAGE_NO_CONST)
			setSort(newSort)
		}
		isFirstRender.current = false

	}, [searchParams])



	function ExportTrigger() {
		swal('Are you sure, you want to Export data?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				export_mismatch_hook.mutate({ ...Args })
			} 
		});
	}

	const renderList = useCallback(() => {

		if (mismatch_list_hook.hook.isPending || reprocess_files_hook.isPending || discard_files_hook.isPending) {
			return <LoaderFull />;
		} else if (mismatch_list_hook.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {

			return (
				<Outlet
					context={{
						mismatchList: mismatch_list_hook.hook.data,
						selectedInvoices: selectedInvoices,
						setselectedInvoices: setselectedInvoices,
						selectedMismatches: selectedMismatches,
						setselectedMismatches: setselectedMismatches

					}}
				/>
			);
		}
	}, [mismatch_list_hook.hook,selectedInvoices,selectedMismatches,setselectedMismatches,reprocess_files_hook.isPending, discard_files_hook.isPending]);

	useEffect(() => {

		return () => {
			mismatch_list_hook.controller.abort()

		}

	}, [])

	function checkRedirect(index)
	{
		if(index === 0)
		{
			navigate({
				pathname : '/mismatch-list' 
			} )
		}
	}
	
	const navigate = useNavigate();

	useEffect(() => {
	//console.log(export_final_mismatch_hook);
	
	if (export_final_mismatch_hook.isSuccess) { 
		//console.log('trigger refech');
		report_list_hook.refetch();

		console.log(mismatch_list_hook.hook.data);
		
		mismatch_list_hook.hook.data.data = []
		mismatch_list_hook.hook.data.total_mismatch_count = 0
		
		navigate({
			pathname: '/mismatch-list'
		})
		
	}
	}, [export_final_mismatch_hook.isSuccess])

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<>
					<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
						<div>
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Snapshots</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								List of all your snapshots is given below -
							</p>
						</div>
						<div className='grow flex gap-3 items-center justify-end'>

							<List component="nav" aria-label="Export">
									<ListItem
										id="export-button"
										aria-haspopup="listbox"
										aria-controls="export-menu"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClickListItem}
										className='text-white font-bold text-sm h-[40px]'
									>
										Actions 
										<span className="material-icons text-white text-sm ml-1">
											expand_more
										</span>
									</ListItem>
								</List>
								<Menu
									id="export-menu"
									className='expMenu'
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'export-button',
										role: 'listbox',
									}}
								>

									<MenuItem disabled={mismatch_list_hook.hook.isPending || Args.client_id == null || Args.category_id == null || Args.date_to == null}
									onClick={() => {ExportTrigger(); setAnchorEl(null);}}>
										<span className="material-icons text-sm mr-3">
											upload
										</span>
										Export
									</MenuItem>
								</Menu>

						</div>

					</div>
					
					<div className="px-6 pt-4">
						<Tabs defaultIndex={1} onSelect={(index) => checkRedirect(index)}>
							<TabList>
								<Tab>Drafts</Tab>
								<Tab>Snapshots</Tab>
							</TabList>

							<TabPanel className='pt-0'>
							</TabPanel>

							<TabPanel>
								<div className="pt-0 mb-2">
									<FilterFinalComponent Args={Args} setArgs={setArgs} mismatch_list_hook={mismatch_list_hook} final_report_list_hook = {final_report_list_hook} Sort={Sort} 
									reportId = {reportId} setReportId = {setReportId} setselectedInvoices = {setselectedInvoices} setselectedMismatches = {setselectedMismatches}/>
								</div>
								{!mismatch_list_hook.hook.isLoading &&  (
								<div className="mb-2">
									<div className="listDHeight overflow-auto larger misM">
										{/* Table Head */}
										{!mismatch_list_hook.hook.isLoading && mismatch_list_hook.hook.data?.total_mismatch_count > 0 && (
										<div className="flex gap-2 mb-2">
											{/* <div className="flex-none w-[40px]">
											<Checkbox disabled={mismatch_list_hook.hook.isFetching} checked={selectedInvoices.size === mismatch_list_hook.hook?.data?.data?.length && selectedInvoices.size !==0 } onChange={(e)=> handleCheckAll(e.target.checked)} size="small" />
												<div className="text-[9px] text-center">Select All</div>
											</div> */}
											<div className="flex-none text-center relative w-[55px]">
												{/* <span title='default sort' onClick={() => handleSort('')} className={`${Sort.type === '' ? 'text-primary' : 'cursor-pointer'}   material-icons w-4 text-sm mt-1`}>swap_vert
												</span> */}

											</div>
											<div className="grow px-5 py-1 text-xs flex items-center justify-between">

												<div className="flex-none pr-4 w-[140px]">
													<label
														
														className={`${Sort.type === 'client_name' ? 'text-primary' : ''} font-black block w-[110px] relative cursor-pointer`}
													>
														Client Name
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														
														className={`${Sort.type === 'vendor_name' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Vendor Name
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														
														className={`${Sort.type === 'expense_area' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Category
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[155px]">
													<label
														// onClick={() => handleSort('invoice_name')}
														className={`${Sort.type === 'invoice_name' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Invoice Name

													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														
														className={`${Sort.type === 'invoice_date' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Invoice Date
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[180px]">
													<label
														// onClick={() => handleSort('contract_name')}
														className={`${Sort.type === 'contract_name' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Contract Name
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>

												<div className="flex-none px-4 text-center w-[180px]">
													<label
														// onClick={() => handleSort('contract_unit_price')}
														className={`${Sort.type === 'contract_unit_price' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Contract Unit Price
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>

												<div className="flex-none px-4 text-center w-[180px]">
													<label
														// onClick={() => handleSort('contract_total_amount')}
														className={`${Sort.type === 'contract_total_amount' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Contract Total Amount
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														// onClick={() => handleSort('item_number')}
														className={`${Sort.type === 'item_number' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Item No.
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														// onClick={() => handleSort('unit_cost')}
														className={`${Sort.type === 'unit_cost' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Unit Cost
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														// onClick={() => handleSort('total_amount')}
														className={`${Sort.type === 'total_amount' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Total Amount
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														// onClick={() => handleSort('volume')}
														className={`${Sort.type === 'volume' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Volume
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[180px]">
													<label
														className={`${Sort.type === 'contract_total_amount' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Reprocess Status
														
													</label>
												</div>
												<div className="flex-none px-4 text-center w-[140px]">
													<label
														className={`${Sort.type === 'credit_amount' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Mismatch Status
													</label>
												</div>

												<div className="flex-none px-4 text-center w-[140px]">
													<label
														// onClick={() => handleSort('description')}
														className={`${Sort.type === 'description' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Description
														{/* <span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
															swap_vert
														</span> */}
													</label>
												</div>

												<div className="flex-none px-4 text-center w-[140px]">
													<label

														className={`${Sort.type === 'mismatch_explanation' ? 'text-primary' : ''} font-black block relative cursor-pointer text-center`}
													>
														Mismatch Explanation

													</label>
												</div>
												
											</div>
											
											<div className="flex-none flex gap-2 w-[285px] pr-2 text-xs">
												<div className="flex-none pl-3 justify-center w-[77px] flex items-center">
													<label className='font-black pr-0 block text-center'>
														Link to
													</label>
												</div>
												<div className="flex-none pl-3 justify-center w-[77px] flex items-center">
													<label className='font-black pr-0 block text-center'>
														Link to
													</label>
												</div>
												<div className="flex-none pl-4 justify-center w-[77px] flex items-center">
													<label className='font-black pr-0 block text-center'>
														Link to
													</label>
												</div>
											</div>

										</div>)}
										{renderList()}
										{/* <Outlet context={{ mismatchList: mismatch_list_hook.hook.data }} />

										{mismatch_list_hook.hook.isLoading && <LoaderFull />}
										{mismatch_list_hook.hook.data?.data?.length === 0 && <p className='text-center'>No data found</p>} */}
									</div>

									<div className="flex justify-center pt-2">
										{/* {!mismatch_list_hook.hook.isLoading && parseInt(searchParams.get('pageNo')) > 1 && <button onClick={() => handleNavigatePages(-1)} className='border-0 outline-0 bg-blue-200 hover:bg-indigo-400 hover:text-white rounded-md text-sm px-2 py-1'> &lsaquo; Prev</button>}
											{!mismatch_list_hook.hook.isLoading && mismatch_list_hook.hook.data?.length !== 0 && <button onClick={() => handleNavigatePages(1)} className='border-0 outline-0 bg-blue-200 hover:bg-indigo-400 hover:text-white rounded-md text-sm px-2 py-1'>Next &rsaquo;</button>} */}
										{!mismatch_list_hook.hook.isLoading && mismatch_list_hook.hook.data?.total_mismatch_count > 0 && (
											<Stack spacing={2}>
												<Pagination
													page={parseInt(searchParams.get(PAGE_NO_CONST)) || 1}
													onChange={(e, value) => {
														setArgs(p=> {
															return {...p,
																page:value
															}
														})
														let body = {
															...Args,
															...Sort,
															page:value,
														}
														if ('client_id' in body && body['client_id']) {
															mismatch_list_hook.hook.mutate(body)
														}

														searchParams.set(PAGE_NO_CONST, value)
														setSearchParams(searchParams)
									
													}}
													count={Math.ceil(
														mismatch_list_hook.hook.data?.total_mismatch_count
													)}
													color="primary"
													variant="outlined"
													shape="rounded"
												/>
											</Stack>
										)}
									</div>
								</div>
								)}
							</TabPanel>
						</Tabs>

					</div>
					
				</>
				
			</div>
		</>
	);
}

export default ListFinalMismatchsIndex;