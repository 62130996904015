import React, { Fragment, useState } from 'react';
import styles from '../invoice.module.css';
import swal from 'sweetalert';
import { TableDropdown } from './dropdown';


function TableList({ExtractionError , setExtractionError, settotalTablesExtracted, settableTitle, extract_table_hook, ExtractTable, tablesExtracted, settablesExtracted, setchangeDetect, setchangeAdd,  DocLockedForMe , settableChanged, PageNo, Freez, settableBoxes}) {

	const [isDeleteEnable, setisDeleteEnable] = useState({})

	function DeleteColPop(tableName, col) {
		swal('Are you sure you want to Delete this column?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// setBoxes(p=> {
				// 	let tpbx = [...p].filter(bx => !(bx.table ===tableName && bx.entity ===col) )
				// 	return tpbx
				// })
				settablesExtracted(prev => {
					let tempTables = { ...prev }
					Object.keys(tempTables[tableName]).map(row => {
						let newrow = tempTables[tableName][row].filter(obj => obj.entity !== col)
						tempTables[tableName][row] = newrow
					return null
					})
					return tempTables
				})
				if (DocLockedForMe){
					setchangeDetect(true)
					setchangeAdd(p=>p+1)
					settableChanged(p => {

						return {
							...p,
							[PageNo]: true
						}
					})
				}
			}
		});
	}

	function DeleteRowPop(tableName, rowName) {
		swal('Are you sure you want to Delete this row?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				settablesExtracted(prev => {
					let tempTables = { ...prev }
					delete tempTables[tableName][rowName]
					return tempTables
				})
				if (DocLockedForMe){
					setchangeDetect(true)
					setchangeAdd(p=>p+1)
					settableChanged(p => {

						return {
							...p,
							[PageNo]: true
						}
					})
				}
			} 
		});
	}

	function handleValChange(tableName , row_name , colInd , value) {
		settablesExtracted(prev => {
			let tempTables = { ...prev }
			tempTables[tableName][row_name][colInd] = {...tempTables[tableName][row_name][colInd] , value : value}
			return tempTables
		})
		if (DocLockedForMe){
					setchangeDetect(true)
					setchangeAdd(p=>p+1)
					settableChanged(p => {

						return {
							...p,
							[PageNo]: true
						}
					})
				}
	}

	function handleLableChange(tableName , colInd , value) {
		settablesExtracted(prev => {
			let tempTables = { ...prev }
			Object.keys(tempTables[tableName]).map(row => {
				tempTables[tableName][row][colInd] = {...tempTables[tableName][row][colInd] , entity : value.toLocaleUpperCase()}
			return null 
			})
			return tempTables
		})
		if (DocLockedForMe){
					setchangeDetect(true)
					setchangeAdd(p=>p+1)
					settableChanged(p => {

						return {
							...p,
							[PageNo]: true
						}
					})
				}
	}


	function addTableHandle() {
		if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=>p+1)
			settableChanged(p => {

				return {
					...p,
					[PageNo]: true
				}
			})
		}
		
		settablesExtracted(p => {
			
			let ind = Object.keys(p).reduce((acc , cur)=>{
				
				if (parseInt(cur) > acc){
					acc = cur
				}
				return acc
			},'0')
	
			try{
				settableTitle(p => {
					let tp = {...p}
					tp[PageNo][(parseInt(ind)+1).toString()] = ''
					return tp
				})
				settotalTablesExtracted(p => {
					let tp = {...p}
					tp[PageNo][(parseInt(ind)+1).toString()] = {}
					return tp
				})
				return {
					...p,
					[(parseInt(ind)+1).toString() ]: {}
				}
				
				
			}
			catch {
				return {...p}
			}
			
			
		})
		
	}

	

	return (
		<>
			{/* Below - List loop of #Table */}

			<div key='table_section' className={`${styles.invFieldsWrap} overflow-auto py-4 pl-6`}>
				{tablesExtracted &&
					Object.keys(tablesExtracted).map((tableName) => {
						let table = tablesExtracted[tableName];
						return (
							<div key={`tb_name_${tableName}`}>
								<TableDropdown ExtractionError={ExtractionError} setExtractionError={setExtractionError} settotalTablesExtracted={settotalTablesExtracted} settableTitle={settableTitle} extract_table_hook={extract_table_hook} ExtractTable={ExtractTable} setchangeAdd={setchangeAdd} PageNo={PageNo} Freez={Freez} settablesExtracted={settablesExtracted} tableName={tableName} setisDeleteEnable={setisDeleteEnable} isDeleteEnable={isDeleteEnable} setchangeDetect={setchangeDetect} DocLockedForMe={DocLockedForMe} settableBoxes={settableBoxes}/>
								
								<div className={`${styles.boxTableCtnr} overflow-auto mb-4 py-2`}>
									<table className={styles.bxTable}>
										<tbody>
										{Object.keys(table).map((row_name, rowInd) => {
											let row_lst = table[row_name];
											return (
												<Fragment key={`table_row_${rowInd}`} >
													{rowInd === 0 && (
														<tr key={`${tableName}_${row_name}_lable`}>
															{row_lst.length !==0 &&
															<td className={`${styles.stik} w-10 text-right`}>
																<span className='inline-block text-center rounded-md size-6 leading-6 bg-gray-300 border border-gray-400 text-sm'>#</span>
															</td> }
															{row_lst.map((cell, colInd) => {
																return (
																	<td key={`${tableName}_${row_name}_${colInd}_lable`}>
																		<div className={`${styles.lblBdr} flex gap-2 w-44`}>
																			<button
																			disabled={Freez || !DocLockedForMe}
																				onClick={() =>
																					DeleteColPop(tableName, cell.entity)
																				}
																				className="leading-none mt-[2px]"
																			>
																				{isDeleteEnable[tableName] && <span className="material-icons-outlined text-sm text-red-600">
																					cancel
																				</span>}
																			</button>
																			<div className="grow">
																				<input 
																				title={cell.entity}
																				disabled={Freez || !DocLockedForMe}
																					type="text"
																					// className={styles.invFldInput}
																					className={`${styles.text2Lines} font-bold text-sm outline-none`}
																					value={cell.entity}
																					onChange={(e)=>handleLableChange(tableName , colInd , e.target.value)}
																				/>
																					
																				
																			</div>
																		</div>
																	</td>
																);
															})}
														</tr>
													)}


													<tr key={`${tableName}_${row_name}`}>
														{row_lst.length !==0 &&
														<td className={`${styles.stik}`}>
															<div className='flex gap-2'>
																<button
																	onClick={() => DeleteRowPop(tableName, row_name)}
																	className={`${styles.cnclCell}`}
																>
																	{isDeleteEnable[tableName] && <span className="material-icons-outlined text-xs text-red-600">
																		cancel
																	</span>}
																</button>
																<span className='inline-block text-center rounded-md size-6 leading-6 bg-gray-300 border border-gray-400 text-sm'>
																	{rowInd + 1}
																</span>
															</div>
														</td> }
														{row_lst.map((cell, colInd) => {
															return (

																<td key={`${tableName}_${row_name}_${colInd}`}>
																	<div className={`${styles.tdInputBx} block w-44`}>
																		<input
																			title={cell.value}
																		 	disabled={Freez || !DocLockedForMe}
																			type="text"
																			className={styles.invFldInput}
																			value={cell.value}
																			onChange={(e)=>handleValChange(tableName , row_name , colInd , e.target.value)}
																		/>
																		<div className={styles.fltIco}>
																			{cell.entity_valid === 'true' && (
																				<span
																					title={
																						cell.entity_validation_explanation
																					}
																					className="material-icons cursor-default text-green-500 text-xs"
																				>
																					check_circle
																				</span>
																			)}
																			{cell.entity_valid === 'false' && (
																				<span
																					title={
																						cell.entity_validation_explanation
																					}
																					className="material-icons cursor-default text-red-500 text-xs"
																				>
																					check_circle
																				</span>
																			)}
																			{cell.entity_valid === 'none' && (
																				<span
																					title={
																						cell.entity_validation_explanation
																					}
																					className="material-icons cursor-default text-gray-500 text-xs"
																				>
																					check_circle
																				</span>
																			)}
																		</div>
																	</div>
																</td>
															);
														})}
													</tr>

												</Fragment>

											);
										})}
										</tbody>
										
									</table>
								</div>
							</div>
						);
					})}
				<div className='w-44 text-left pt-2'>
						<button
							disabled={!DocLockedForMe}
							type="button"
							onClick={() => addTableHandle()}
							className="bg-blue-200 hover:bg-indigo-400 px-4 py-2 rounded-md text-primary text-sm hover:text-white flex items-center font-black disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500"
						>
							<span className="material-icons text-sm mr-1">add</span>Add Table
						</button>
					</div>
			</div>
		</>
	);
}

export default TableList;
