import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { useMsal } from '@azure/msal-react';
export const useGetProfile = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['get_profile'],
		queryFn: async () => {
			try {
				const TOAST_ID = 'get_profile'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/get_profile')
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			};
		},
		retry: false,
	});
};
