import React, { useEffect, useState } from 'react';
import SideBox from './shared/sidebox';
import { useLogin } from './hooks';
/* import { useFormik } from 'formik';
import { LoginSchema } from '../YupSchema';
import { toast } from 'react-toastify'; */
import LoaderFull from '../shared/loader-full-screen';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

function LogIn() {
	const isAuthenticated = useIsAuthenticated();

	const login_hook = useLogin();


	const { instance } = useMsal();
	const [DisableBtn, setDisableBtn] = useState(false);

	const handleSignIn = () => {
		// instance.loginPopup({
		//     scopes: ['user.read']
		// });
		setDisableBtn(true);
		// loadLoginPopup()
		instance
			.ssoSilent({
				scopes: ['user.read'],
			})
			.then((response) => {
				instance.setActiveAccount(response.account);
				setDisableBtn(false);
			})
			.catch((error) => {
				setDisableBtn(false);
				if (error instanceof InteractionRequiredAuthError) {
					loadLoginPopup();
				}
			});
	};

	async function loadLoginPopup() {
		try {
			await instance.loginPopup({
				scopes: ['user.read'],
			});
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (isAuthenticated) {
			login_hook.mutate();
		}
	}, [isAuthenticated]);

	// if(isAuthenticated) {
	//     login_hook.mutate()
	//   }


	return (
		<>
			<div className="h-screen lg:flex overflow-x-hidden overflow-y-auto">
				<div className="lg:order-last flex grow items-center justify-center">
					<div className="w-90 max-w-[380px] px-4 py-8 m-auto">
						<div className="text-center mb-3">
							<img
								className="inline-block mb-1"
								src="/svg/logo-innerp.svg"
								alt=""
							/>
							<p className="text-slate-600 text-sm">
								Enhance your data services with our solutions.
							</p>
						</div>
						{isAuthenticated && login_hook.isPending ? (
							<LoaderFull />
						) : (
							<div className="pt-4 max-h-[65vh] overflow-auto px-3">
								<button
									type="button"
									onClick={handleSignIn}
									disabled={DisableBtn}
									className="font-sans border-2 stroke- hover:shadow-md flex items-center justify-center gap-2 w-full px-4 py-2 rounded-md mb-2 disabled:bg-gray-300"
								>
									<img src="/svg/logos_microsoft-icon.svg" alt="ms logo" />
									Log in with Microsoft
								</button>
							</div>
						)}
						<div className="text-center pt-3">
							<h4 className="mb-2 text-sm text-slate-600">
								Trouble logging in?
							</h4>
							<p className="text-sm mb-0">
								<a
									className="text-[#2751A3] underline hover:no-underline"
									href="mailto:tech.support@prpllc.com?subject=PRPLLM Support - Unable to login"
									target="_blank"
									rel="noopener noreferrer"
								>
									Click here
								</a>{' '}
								to contact support
							</p>
							<p className="text-slate-400 text-sm">or</p>
							<p className="text-sm">
								Email to{' '}
								<a
									className="text-[#2751A3] underline hover:no-underline"
									href="mailto:tech.support@prpllc.com"
								>
									tech.support@prpllc.com
								</a>
							</p>
						</div>
					</div>
				</div>
				<SideBox subtitle={`Login to your account!`} />
			</div>
		</>
	);
}

export default LogIn;
