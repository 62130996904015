import { styled } from '@mui/system';

const useStyles = styled(() => ({
	formControl: {
		width: 300,
	},
	indeterminateColor: {
		color: '#f50057',
	},
	selectAllText: {
		fontWeight: 500,
	},
	selectedAll: {
		backgroundColor: 'rgba(0, 0, 0, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.08)',
		},
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'center',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
	variant: 'menu',
};

export { useStyles, MenuProps };
