import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { useMsal } from '@azure/msal-react';

const fetchVendorsList = async ( Args, dispatch, signal,instance) => {

	try {
		const TOAST_ID = 'vendor_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/vendor_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		
	};
};

export const useVendorsListPagewise = (Sort) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState({
		search: '',
		...Sort
	});
	const hook = useQuery({
		queryKey: ['vendor_list', Args],
		queryFn: async ({ signal }) =>
			fetchVendorsList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

export const useAddVendorName = (setIsOpened) => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	const queryClient = useQueryClient() 
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'add_vendor'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/add_vendor`, JSON.stringify(body));
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			}
		},
		onSuccess: ()=> {
			queryClient.invalidateQueries({ queryKey: ['vendor_list'] })
			setIsOpened(false)
		}

	});
};


export const useBuildModel = () => {
	const queryClient = useQueryClient() 
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'build_model'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/build_model`, JSON.stringify(body));
				let message = responseData?.message || 'Build Model triggered for selected vendors and categories'
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['model_status'] })
			queryClient.invalidateQueries({ queryKey: ['vendor_list'] })
		  },
	});
};


export const useGetBuildModelStatus = () => {
	const queryClient = useQueryClient() 
	let dispatch = useDispatch();
	const { instance } = useMsal();
	return useQuery({
		queryKey: ['model_status'],
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'get_model_build_log'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					signal: signal,
				};
				const responseData = await axiosInstance.get('/get_model_build_log',config)
				if (['Completed' , 'Failed'].includes(responseData?.message)){
					queryClient.invalidateQueries({ queryKey: ['vendor_list'] })
				}
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)	
				
			};
		},
	});
};