import React from 'react';

function VendorsList({DashboardData}) {

	return (
		<>
			<h1 className="text-lg font-black text-primary">Top Vendors Annotated</h1>
			<p className="text-xs text-gray-500 mb-2">
				Top Vendors Annotated till now
			</p>
			{/* <p className="text-lg font-black text-primary">0</p> */}
			{<div className='pt-6'>
                <div className='pr-4 max-h-[286px] overflow-x-hidden overflow-y-auto'>
                {DashboardData?.total_vendor_annotated?.map((itm, ind) => {
                    return  ( <div key={`itm` + ind} className='flex gap-2 pt-1'>
                        <div className='flex-none'>
                            <label className='vLBG inline-block p-2 rounded-md'>
                                <img src="/svg/fluent_calendar-person-20-filled.png" alt="" />
                            </label>
                        </div>
                        <div className='grow border rounded-md px-5 h-[50px] text-sm flex items-center justify-between'>
                            <div className='relative h-5 grow flex items-center justify-center'>
                                <span className='block w-full absolute left-0 top-0 text-ellipsis overflow-hidden whitespace-nowrap'
                                    title={itm.vendor_name}
                                >
                                {itm.vendor_name}
                                </span>
                            </div>
                            <div className='pl-3'>
                                {itm.count}
                            </div>
                        </div>
                    </div> )
                })}
                {DashboardData?.total_vendor_annotated?.length === 0 &&
                <div  className='flex gap-2 pt-1'>
                   No Data Found
                </div> 
                }
                </div>
            </div>}
		</>
	);
}

export default VendorsList;
