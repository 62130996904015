import styles from '../shared/common.module.css';
import React, { useEffect, useRef, useState, useReducer } from 'react';
import { AreaSelector } from '@bmunozg/react-image-area';

import { useGetImgUrlByPage } from './hooks';
import LoaderFull from '../shared/loader-full-screen';
import { CustomRenderBox } from './customRender';
import swal from 'sweetalert';
function FilePlaceholder({
	fieldsExtracted,
	setfieldsExtracted,
	tablesExtracted,
	settablesExtracted,
	Boxes,
	setBoxes,
	totalFieldsExtracted,
	settotalFieldsExtracted,
	FlatBoxes,
	setFlatBoxes,
	totalTablesExtracted,
	settotalTablesExtracted,
	PageNo,
	setPageNo,
	type,
	imgUrls,
	imgData,
	errorSelected,
	Lables,
	setchangeDetect,
	setchangeAdd,
	DocLockedForMe
}) {
	const [TotalPages] = useState(Object.keys(imgUrls).length);
	const [, render] = useReducer((p) => !p, false);
	const [Margin] = useState(20);

	const get_url_hook = useGetImgUrlByPage(imgUrls);
	const refList = useRef([])
	const scrollRegister = useRef(false)

	

	function diffManage(prev, area) {
		let indices = prev.map((p) => {
			return area.findIndex(
				(a) =>
					a.box_id === p.box_id &&
					(p.x !== a.x ||
						p.y !== a.y ||
						p.height !== a.height ||
						p.width !== a.width)
			);
		});
		indices.map((c) => {
			if (c !== -1) {
				if (area[c].status === 'new added') {
				} else if (
					['entity coordinate changed', 'entity changed'].includes(
						area[c].status
					)
				) {
					area[c].status = 'entity coordinate changed';
				} else {
					area[c].status = 'coordinate changed';
				}
			}
		return null
		});
		return area;
	}

	const onChangeHandler = (areas1) => {
		if (DocLockedForMe){
			setchangeDetect(true)
			setchangeAdd(p=> p+1)
		}
		setBoxes((prev) => {
			let newObj = diffManage(prev, areas1);
			return newObj;
		});
	};

	const customRender = (areaProps) => {
		if (!areaProps.isChanging && areaProps.height && areaProps.width) {
			return (
				<CustomRenderBox 
				type={type}
				areaProps={areaProps} 
				Boxes={Boxes}
				setBoxes={setBoxes}
				fieldsExtracted={fieldsExtracted}
				tablesExtracted={tablesExtracted}
				refList={refList}
				Lables={Lables}
				PageNo={PageNo}
				setchangeDetect={setchangeDetect}
				setchangeAdd={setchangeAdd}
				DocLockedForMe={DocLockedForMe}
				/>
			);
		}
	};

	const [cumulativeSum] = useState(() => {
		let cS = [0];
		let lst = 0;
		Object.values(imgData).map((obj) => {
			cS.push(lst + obj.image_height + Margin);
			lst += obj.image_height + Margin;
		return null
		});
		return cS;
	});

	function scrollToPageNo(scrollOffset) {
		let low = 0;
		let high = cumulativeSum.length - 1;
		while (low <= high) {
			let mid = Math.floor((low + high) / 2);
			if (cumulativeSum[mid] === scrollOffset) {
				return mid;
			} else if (cumulativeSum[mid] < scrollOffset) {
				low = mid + 1;
			} else {
				high = mid - 1;
			}
		}
		return low - 1;
	}

	const divRef = useRef(null);
	const primaryPageNo = useRef(0);
	const secondaryPageNo = useRef(0);

	useEffect(() => {
		function debounce(fn, delay) {
			let context = this;
			let setTimeoutId;

			return function () {
				if (setTimeoutId) {
					window.clearTimeout(setTimeoutId);
				}
				setTimeoutId = window.setTimeout(() => {
					fn.apply(context, arguments);
					setTimeoutId = null;
				}, delay);
			};
		}
		const handleScroll = () => {
			if (divRef.current) {
				const scrollTop = divRef.current.scrollTop;
				let pgNo = scrollToPageNo(scrollTop);
				let h1 = cumulativeSum[pgNo + 1] - scrollTop;
				let h2 = divRef.current.offsetHeight - h1;
				if (h1 > h2 || pgNo === TotalPages - 1) {
					primaryPageNo.current = pgNo;
					secondaryPageNo.current = pgNo + 1;
				} else {
					primaryPageNo.current = pgNo + 1;
					secondaryPageNo.current = pgNo;
				}
				scrollRegister.current = true
				render();
			}
		};

		// Attach the scroll event listener
		if (divRef.current) {
			divRef.current.addEventListener('scroll', debounce(handleScroll, 100));
		}
		let observerRefValue = divRef?.current;

		// Cleanup the event listener
		return () => {
			if (observerRefValue) {
				observerRefValue.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	function SaveChanges(newP) {
		setFlatBoxes((prev) => {
			let bb = JSON.parse(JSON.stringify(Boxes))
			let pgbx = { ...prev };
			pgbx[PageNo] = bb;
			return pgbx;
		});

		settotalFieldsExtracted((prev) => {
			let fe = JSON.parse(JSON.stringify(fieldsExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = fe;
			return pgbx;
		});

		settotalTablesExtracted((prev) => {
			let te = JSON.parse(JSON.stringify(tablesExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = te;
			settablesExtracted(pgbx[newP]);
			return pgbx;
		});
		setfieldsExtracted(totalFieldsExtracted[newP]);
		// settablesExtracted(totalTablesExtracted[newP]);
		setBoxes(FlatBoxes[newP]);
		setPageNo(newP);
	}

	useEffect(() => {
		if (primaryPageNo.current < 0 || primaryPageNo.current >= TotalPages) {
			return;
		}
		get_url_hook.hook.mutate(primaryPageNo.current);
		get_url_hook.hook.mutate(secondaryPageNo.current);
		SaveChanges(primaryPageNo.current);
	}, [primaryPageNo.current]);

	useEffect(() => {
		if (errorSelected !== null && primaryPageNo.current !== errorSelected) {
			divRef.current.scrollTop = cumulativeSum[errorSelected];
			primaryPageNo.current = errorSelected;
			render();
		}
	}, [errorSelected]);

	function toInteger(value) {
		return isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10);
	}

	function imgLoaded(id) {
		let id2 = 'dummy_' + id;
		document.getElementById(id2).style.display = 'none';
		document.getElementById(id).style.display = 'block';
	}

	function handleError(pg) {
		get_url_hook.setimgObj((prev) => {
			return {
				...prev,
				[pg]: {
					...prev[pg],
					img_url: null,
				},
			};
		});
		
	}

	useEffect(() => {
		let entList = new Set()
		Object.keys(tablesExtracted).map(table_name=> {
			let table =  tablesExtracted[table_name]
			Object.keys(table).map(row => {
				let row_list = table[row]
				row_list.map(cell => {
					entList.add(cell.entity)
					return null
				})
			return null
			})
		return null
		})
		fieldsExtracted.map(itm => {
			entList.add(itm.entity)
			return null
		})

		setBoxes(p=> {
			let tpbx = [...p].filter(bx => entList.has(bx.entity ) )
			return tpbx
			})
	}, [tablesExtracted , fieldsExtracted])

	if (scrollRegister.current){
		setchangeAdd(p=>p+1)
		scrollRegister.current = false 
	}


	return (
		<>
			<div className="my-1 py-1 w-full text-center flex items-center justify-center gap-4 bgGrayLight relative">
				<button
					disabled={!(primaryPageNo.current - 1 >= 0)}
					onClick={(e) => {
						divRef.current.scrollTop = cumulativeSum[primaryPageNo.current - 1];
						primaryPageNo.current -= 1;
						render();
					}}
					className="prevPBtn"
				></button>
				<div>
					<input
						type="number"
						placeholder="Enter row number"
						className="text-center inline-block pl-3 pr-1 py-1 text-sm border appearance-none rounded-md"
						value={primaryPageNo.current + 1}
						min={1}
						max={TotalPages}
						onChange={(e) => {
							let newp = toInteger(e.target.value) - 1;
							divRef.current.scrollTop = cumulativeSum[newp];
							primaryPageNo.current = newp;
							render();
						}}
					/>
				</div>
				<button
					disabled={!(primaryPageNo.current + 1 < TotalPages)}
					onClick={(e) => {
						divRef.current.scrollTop = cumulativeSum[primaryPageNo.current + 1];
						primaryPageNo.current += 1;
						render();
					}}
					className="nextPBtn"
				></button>
				<div>of</div>
				<div>
					<strong>{TotalPages}</strong>
				</div>
				{/* <button onClick={ClearPageAnnotation} className='bg-red-200 text-red-600 py-1 px-2 flex items-center rounded-md text-sm absolute top-1 right-6' title='Clear page annotations'>
					<span class="material-icons-outlined text-sm mr-1">clear</span> Page annotations
				</button> */}
			</div>
			
			<div
				className={`${styles.vlFrame} grow flex flex-col justify-center overflow-auto relative`}
			>
				<div id="parent" ref={divRef} className="h-full w-full overflow-scroll">
					{Array(TotalPages)
						.fill(0)
						.map((itm, ind) => {
							return (
								<div key={`main_page_${ind}`} className='relative' 
								style={{
									
									height: imgData[ind].image_height,
									width: imgData[ind].image_width+Margin,
									margin: Margin,
								}}
								>
									
									<div
										key={`dummy_box_${ind}`} 
										id={`dummy_box_id_${ind}`}
										style={{
											height: imgData[ind].image_height,
											width: imgData[ind].image_width,
											margin: Margin
										}}
										className=" bg-gray-400 flex flex-col justify-center items-center"
									>
										<LoaderFull />
									</div>
									{!DocLockedForMe && <div 
									style={{
									
									height: imgData[ind].image_height,
									width: imgData[ind].image_width+Margin,
									// margin: Margin,
								}} className='absolute left-0 top-0 z-10' ></div> } 
									<div
										style={{
											display: 'none',
											height: imgData[ind].image_height,
											width: imgData[ind].image_width + Margin,
											margin: Margin,
										}}
										id={`box_id_${ind}`}
										key={`box_${ind}`}
									>
									
										{secondaryPageNo.current === PageNo - 1 &&
											secondaryPageNo.current === ind &&
											get_url_hook.imgObj[secondaryPageNo.current]?.img_url !==
												null && (
												<img
												alt='pg-1'
													src={
														get_url_hook.imgObj[secondaryPageNo.current]
															?.img_url
													}
													className="max-w-none"
												></img>
											)}
										{Boxes &&
											PageNo === ind &&
											get_url_hook.imgObj[primaryPageNo.current]?.img_url !==
												null && (
													
														
													
													
													<AreaSelector
													areas={Boxes}
													minHeight={5}
													minWidth={5}
													wrapperStyle={{}}
													globalAreaStyle={{
														border: '2px solid #47a6ff',
														backgroundColor: 'rgba(255,255,255,0.1)',
														opacity: '1',
													}}
													onChange={onChangeHandler}
													customAreaRenderer={customRender}
												>
													<img
													alt='pg'
														onError={() => handleError(primaryPageNo.current)}
														onLoad={() => imgLoaded(`box_id_${ind}`)}
														src={
															get_url_hook.imgObj[primaryPageNo.current]
																?.img_url
														}
														className="max-w-none"
													></img>
												</AreaSelector>
													
												
											)}
										{secondaryPageNo.current === PageNo + 1 &&
											secondaryPageNo.current === ind &&
											get_url_hook.imgObj[secondaryPageNo.current]?.img_url !==
												null && (
												<img
												alt='pg-2'
													src={
														get_url_hook.imgObj[secondaryPageNo.current]
															?.img_url
													}
													className="max-w-none"
												></img>
											)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
}

export default FilePlaceholder;
