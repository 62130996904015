import React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

export const TableDropdown = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
    const handleClose = () => {
		setAnchorEl(null);
	};

    function addRow(tableName ) {
        props.settablesExtracted(prev => {
				let tempTables = {...prev}

				let prevRows = Object.keys(tempTables[tableName])
                if (prevRows.length ===0){
                    toast.error("Add column First")
                    return tempTables
                }
				let row = tempTables[tableName][prevRows[0]].map(obj => {
					return {
						table: tableName,
						entity: obj.entity,
						value: '',
						status: 'new added',
						version: 0,
						entity_valid: '',
						rule_applied: '',
						entity_validation_explanation: '',
						annotated: true,
                        originalValue : '',
                        bounding_box: []
					}
				})
				let rname = `row_${prevRows.length}`
				tempTables[tableName][rname] = row

				return tempTables
			})
            if (props.DocLockedForMe){
                props.setchangeDetect(true)
                props.setchangeAdd(p=>p+1)
            }
		handleClose()
	}

    function addCol(tableName ) {
        props.settablesExtracted(prev => {
				let tempTables = {...prev}

				let prevRows = Object.keys(tempTables[tableName])
                if (prevRows.length ===0) {
                    tempTables[tableName]['row_0'] = [{
                        table: tableName,
                        entity: '',
                        value: '',
                        status: 'new added',
                        version: 0,
                        entity_valid: '',
                        rule_applied: '',
                        entity_validation_explanation: '',
                        annotated: true,
                        originalValue : '',
                        bounding_box: []
                    }]
                    return tempTables
                }
                else{
                    prevRows.map(row => {
                        tempTables[tableName][row].push({
                            table: tableName,
                            entity: '',
                            value: '',
                            status: 'new added',
                            version: 0,
                            entity_valid: '',
                            rule_applied: '',
                            entity_validation_explanation: '',
                            annotated: true,
                            bounding_box: []
                        })
                    return null
                    })
                    return tempTables
                }
			})
        if (props.DocLockedForMe){
                props.setchangeDetect(true)
                props.setchangeAdd(p=>p+1)
            }
        
        handleClose()
			
	}

    return (
        <div className="flex gap-3">
            <Button
                disabled={!props.DocLockedForMe}
                id={`button-${props.tableName}`}
                aria-controls={undefined}
                aria-haspopup="true"
                aria-expanded={undefined}
                onClick={handleClick}
            >
                <h2>{props.tableName}</h2>
                <span className="inline-block material-icons text-black text-sm ml-3 size-6 border rounded-md moreIco">
                    more_vert
                </span>
            </Button>
            <Menu
                id={`menu-${props.tableName}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `button-${props.tableName}`,
                }}
            >
                <MenuItem onClick={(e) => {
                    addRow(props.tableName)
                }}>Add Row</MenuItem>
                <MenuItem onClick={() => addCol(props.tableName)}>
                    Add Column
                </MenuItem>
                
                <MenuItem onClick={() => {props.setisDeleteEnable(p =>{
                    let tp = {...p}
                    if ( props.tableName in tp ) { 
                        tp[props.tableName] = ! tp[props.tableName]
                    }
                    else{
                        tp[props.tableName] = true
                    }
                    return tp 
                })
                handleClose()
            }
                }>
                   {props.isDeleteEnable[props.tableName] ? 'Hide Delete' : 'Delete'}
                </MenuItem>
            </Menu>
        </div>
    )
}
