import { useMutation } from '@tanstack/react-query';

import { useDispatch } from 'react-redux';
import { redux_logout } from './authSlice';
import { buildAxiosInstance } from '../axiosInstance';
import { LogoutCheck } from '../utils';
import { privilage_set } from './privilegesSlice';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

export const useLogin = () => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	let navigate = useNavigate();
	return useMutation({
		mutationFn: async () => {
			try {
				const TOAST_ID = 'azure_login_user'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/azure_login_user')				
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			}
		},
		onSuccess: (data)=> {			
			dispatch(privilage_set({ privilages: data.actions }));
			setTimeout(() => {
				navigate('/dashboard');
			});
		}
	});
};

export const useLogout = () => {
	let dispatch = useDispatch();
	return useMutation({
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'logout'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post('/logout',{})
				return responseData
			} catch (error) {
				throw error
			}
		},
		onSuccess: (data, body) => {
			localStorage.clear();
			dispatch(redux_logout());
		},
	});
};


export const useReleaseLock = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	return useMutation({
		retry:2,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'release_lock'
				const axiosInstance = buildAxiosInstance(TOAST_ID,true)
				const responseData = await axiosInstance.post('/release_lock', body)
				return responseData
			}
			catch (error) {
				LogoutCheck(error, dispatch,instance)
				
			};
		},
	});
}