import React, { useState, useEffect } from 'react';
//import { toast } from 'react-toastify'

//import { Autocomplete, TextField } from '@mui/material';
import { Autocomplete, Box, TextField } from '@mui/material'

import { GetContracts } from './hooks';

function FilterContractVariation({
    setContractVariation,
	ContractVariation,
	setContractVariationId,
	ContractVariationId,
    ClientId,
    CategoryId,
    VendorNameId
}) {

    const [options, setoptions] = useState([]);
	const client_list_hook = GetContracts(ClientId,CategoryId,VendorNameId);
	const [Focus, setFocus] = useState(false);

	useEffect(() => {
		if (client_list_hook.data) {
			setoptions(client_list_hook.data.data.map((itm) => itm));
		}
	}, [client_list_hook.data]);

    function changeProjectType(val) {
		setContractVariation(val);
		let obj = client_list_hook.data.data.find((o) => o.fname === val?.fname);
		if (obj) {
			setContractVariationId(obj._id);
		}
	}

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            
                            {client_list_hook.fetchStatus === 'fetching' ? (
                                <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                                    Master Contract{' '}
                                    <span className="text-sm text-slate-400 ps-4 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) =>
                                        option === value || value === '' || value == null
                                    }
                                    id="combo-box-demo"
                                    options={options}
                                    value={ContractVariation}
                                    getOptionLabel={(option) => option.fname}
                                    renderOption={(props, option) => {
                                        return (
                                          <Box component="li" {...props} key={option._id}>
                                            {option.fname}
                                          </Box>
                                        )
                                      }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Master contract *" />
                                    )}
                                    onChange={(event, value) => changeProjectType(value)}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    // onInputChange={(event, newInputValue) => {
                                    //     setProjectType(newInputValue);
                                    //   }}
                                />
                            )}
                            {ContractVariation == null && Focus && (
								<small className="text-red-600 ">
									Master contract is required
								</small>
							)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterContractVariation;
