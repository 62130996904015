import React from 'react';
import FieldsListTable from './listTable';
import { useSelector } from 'react-redux';
import { useCategoryListPagewise, useProjectTypeListPagewise } from './hooks';

function ManageFieldsIndex() {
	const navigateRedux = useSelector((state) => state.navigateSlice);

	const category_list_hook = useCategoryListPagewise(
		navigateRedux.clientListPageNo
	);

	const projecttype_list_hook = useProjectTypeListPagewise(
		navigateRedux.clientListPageNo
	);

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter manageFlds">
				<FieldsListTable
					dataCategories={category_list_hook}
					dataProjectTypes={projecttype_list_hook}
				/>
			</div>
		</>
	);
}

export default ManageFieldsIndex;
