import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useSaveMismatchReport } from './hooks';

export const PopupSaveReport = ({ setIsOpened,Args,report_list_hook,reprocessPass,saveAsFinal,reprocessAfterSave,finalAfterReportSave }) => {
	const [reportName, setreportName] = useState('');
	const save_mismatch_report = useSaveMismatchReport();
	const [focused, setfocused] = useState(false);

	function saveChanges() {
		save_mismatch_report.mutate({ 
			"name": reportName.trim(),
			"email":localStorage.getItem('username'),
			"client_id": Args.client_id,
			"client_name": Args.client_name,
			"vendor_id": Args.vendor_id,
			"batch_id": Args.batch_id,
			"category_id": Args.category_id,
			"from_date": Args.date_from,
			"to_date": Args.date_to 
		});
	}

	function checkValidation(str) {
		let index = str.indexOf('/');
		if (index !== -1) {
			return 'report Name should be text value';
		} else {
			return '';
		}
	}

	function formatDateToCustomString() {
        // Create a new Date object from the input date string
        const date = new Date();
      
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0'); // 24-hour format
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        // Format the date as YYYYMMDDHHMMSS
        const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
      
        return formattedDate;
    }

	useEffect(() => {
		const timestamp = formatDateToCustomString();
		let name = '';
		if(Args.vendor_name){
			name = `${Args.client_name}_${Args.vendor_name}_${Args.category}_${timestamp}`;
		}else{
			name = `${Args.client_name}_${Args.category}_${timestamp}`;
		}
		 
		setreportName(name);
	},[Args])

	useEffect(() => {
		if (save_mismatch_report.isSuccess) {

			//console.log(reprocessPass);
			
			//console.log(saveAsFinal);

			if(save_mismatch_report.data?.report_id){

				if(reprocessPass)
				{
					//console.log(save_mismatch_report.data.report_id);
					
					reprocessAfterSave(save_mismatch_report.data.report_id);
					report_list_hook.refetch();
					setIsOpened(false);
				}
				else if(saveAsFinal)
				{
					finalAfterReportSave(save_mismatch_report.data.report_id);
					report_list_hook.refetch();
					setIsOpened(false);
				}
				else{
					report_list_hook.refetch();
					setIsOpened(false);
				}

			}
			
		}
	}, [report_list_hook, reprocessAfterSave, reprocessPass, save_mismatch_report, save_mismatch_report.isSuccess, setIsOpened]);

	return (
		<>
			<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="relative my-6 mx-auto w-auto lg:w-[800px]">
					{/*content*/}
					<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						{/*header*/}
						<div className="p-5 rounded-t">
							<h3 className="text-2xl m-0 font-semibold text-primary text-center">
								Create New Report
							</h3>
							<button
								className="bg-transparent border-0 text-slate-400 leading-none font-semibold outline-none focus:outline-none absolute top-4 right-5"
								onClick={() => setIsOpened(false)}
								title="Close"
							>
								<span className="material-icons-outlined modalClose">
									cancel
								</span>
							</button>
						</div>
						{/*body*/}
						<div className="relative pb-8 px-6 lg:w-[400px] mx-auto">
							<div className="py-2">
								<div className="flex gap-3">
									<div className="flex-none">
										<label className="vLBG flex items-center px-2 h-[44px] rounded-md text-center text-white relative text-sm">
											<img
												className="h-8"
												src="/svg/ri_building-fill.svg"
												alt=""
											/>
										</label>
									</div>
									<div className="grow">
										<TextField
											onFocus={() => setfocused(true)}
											placeholder="Add report name"
											id="reportname"
											value={reportName}
											title={reportName}
											onChange={(e) => setreportName(e.target.value)}
										/>
										{focused && (
											<small className="text-gray-400">
												{checkValidation(reportName)}
											</small>
										)}
									</div>
								</div>
								<div className="pt-4">
									<button
										type="button"
										disabled={
											 save_mismatch_report.isPending || reportName.trim() === '' ||
											checkValidation(reportName) !== ''
										}
										onClick={saveChanges}
										className="bg-blue-800 hover:bg-indigo-700 px-8 py-3 rounded-md text-white text-sm flex w-full items-center justify-center font-black disabled:bg-gray-400"
									>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
		</>
	);
};
