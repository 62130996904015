import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';
//import { useSelector } from "react-redux";
import {
	BLOB_CONTAINER_NAME,
	RECORDS_PER_PAGE,
} from '../../config';
import { useDispatch } from 'react-redux';
//import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { useDebouncedState } from '@mantine/hooks';
import { BlobServiceClient } from '@azure/storage-blob';
import { LogoutCheck } from '../utils';
import { buildAxiosInstance } from '../axiosInstance';
import { useMsal } from '@azure/msal-react';

export const useUploadCorpusToS3 = () => {
	//let navigate = useNavigate()
	const { instance } = useMsal();
	let dispatch = useDispatch();
	const [Progress, setProgress] = useState(0);
	const [uploadCancelToken, setUploadCancelToken] = useState(null);

	const upload_s3_hook = useMutation({
		mutationFn: async (props) => {
			try {
				const cancelTokenSource = axios.CancelToken.source();
				setUploadCancelToken(cancelTokenSource);
				return await axios.post(props.url, props.formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						setProgress(percentCompleted);

						// console.log(percentCompleted);
					},
					cancelToken: cancelTokenSource.token,
				});
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
			} finally {
				setUploadCancelToken(null);
			}
		},
	});
	return {
		upload_s3_hook: upload_s3_hook,
		Progress: Progress,
		setUploadCancelToken: setUploadCancelToken,
		uploadCancelToken: uploadCancelToken,
	};
};

export const useUploadCorpusToAzure = () => {
	//let navigate = useNavigate()
	const { instance } = useMsal();
	let dispatch = useDispatch();
	const [Progress, setProgress] = useState(0);
	const [uploadCancelToken, setUploadCancelToken] = useState(null);

	const upload_azure_hook = useMutation({
		mutationFn: async (props) => {
			try {
				const containerName = BLOB_CONTAINER_NAME + props.prepath;

				const blobServiceClient = BlobServiceClient.fromConnectionString(
					props.connectionString
				);
				const containerClient =
					blobServiceClient.getContainerClient(containerName);

				const blobName = props.blobName;
				const blockBlobClient = containerClient.getBlockBlobClient(blobName);

				const cancelTokenSource = axios.CancelToken.source();
				setUploadCancelToken(cancelTokenSource);

				return await blockBlobClient
					.uploadData(props.obj.file, {
						onProgress: (ev) => {
							//console.log(ev);
							//console.log(ev.loadedBytes)
							const percentCompleted = Math.round(
								(ev.loadedBytes * 100) / props.obj.file.size
							);
							//console.log(percentCompleted+'%')
							setProgress(percentCompleted);
						},
						blobHTTPHeaders: {
							blobContentType: props.obj.file.type,
						},
					})
					.then(
						//this.onUploadComplete(observer, file),
						//this.onUploadError(observer)
					)
					.catch((err) => {
						console.log(err);
					});
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
			} finally {
				setUploadCancelToken(null);
			}
		},
	});
	return {
		upload_azure_hook: upload_azure_hook,
		Progress: Progress,
		setUploadCancelToken: setUploadCancelToken,
		uploadCancelToken: uploadCancelToken,
	};
};

export const useUploadToMongo = () => {
	//const Data = useSelector(state => state.auth)
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useMutation({
		mutationFn: async (body) => {
			try {

				const TOAST_ID = 'upload_documents'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/upload_documents`, JSON.stringify(body));
				//let message = responseData?.message
				//message && toast.success(message, { toastId: TOAST_ID })
				return responseData

				
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

//added for batch queue. we are using this for notifications
export const useBatchQueue = () => {
	//const Data = useSelector(state => state.auth)
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useMutation({
		mutationFn: async (body) => {

			try {

				const TOAST_ID = 'batch_id_queue'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/add/batch_id/queue`, JSON.stringify(body));
				//let message = responseData?.message
				//message && toast.success(message, { toastId: TOAST_ID })
				return responseData

				
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				//throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

const fetchClientList = async (Args, dispatch, signal,instance) => {

	try {
		const TOAST_ID = 'get_fetch_client_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/client_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)
		throw new Error(`${error.response.data.message}`);	
		
	};
};

export const useClientsList_Uploadpage = () => {
	//const Data = useSelector(state => state.auth)
	const { instance } = useMsal();
	let dispatch = useDispatch();
	const [Args, setArgs] = useDebouncedState(
		{
			search: '',
			page:1
		},
		1000
	);
	const hook = useQuery({
		queryKey: ['clientlist_uploadpage', Args],
		queryFn: async ({ signal }) => fetchClientList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};

export const GetCategorylist = () => {
	//const Data = useSelector(state => state.auth)
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_category'],
		retry: false,
		queryFn: async () => {
			try {
				const TOAST_ID = 'get_category_list'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/category')
				return responseData
				
			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const GetAnalystlist = () => {
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_analyst'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_analyst_list'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/analyst')
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

export const GetProjectTypes = () => {

	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_project_type'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_project_type'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/project_type')
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

};

export const GetVendors = () => {
	
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_vendors'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_vendors'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get('/vendors')
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

};

export const GetContracts = (ClientId,CategoryId,VendorNameId) => {
	
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_contracts'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_contracts'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get(`/contracts?clientid=${ClientId}&categoryid=${CategoryId}&VendorId=${VendorNameId}`)
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});

};

export const useGetLicenceStatus = () => {
	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_licence_status'],
		retry: 2,
		enabled:true,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_licence_status'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get(`license_expire_check`)
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};

/** API to fetch batches */
export const useGetBatches = () => {

	const { instance } = useMsal();
	let dispatch = useDispatch();
	return useQuery({
		queryKey: ['get_batches'],
		retry: false,
		queryFn: async () => {
			try {

				const TOAST_ID = 'get_batches'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.get(`batch`)
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw new Error(`${error.response.data.message}`);
			}
		},
	});
};