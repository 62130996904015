import React, { useEffect, useRef, useState } from 'react';
import styles from '../annotation/invoice.module.css';
import { useGetImageUrl } from './hooks';
import { AreaSelector } from '@bmunozg/react-image-area';
import LoaderFull from '../shared/loader-full-screen';


function InvoicePane({ Data }) {

	const [invoiceImgurl, setinvoiceImgurl] = useState(null)
	const get_image_from_blob = useGetImageUrl(Data?.invoice_image_path)
	const [imgSize, setimgSize] = useState('768')
	const prevWidth = useRef(null)
	const [Margin] = useState(20);
	const [Box, setBox] = useState(()=> {

		var scaleFactor = imgSize/Data.invoice_image_width
		let [x1, y1, h1, w1] = [scaleFactor*Data.invoice_bounding_box[0],scaleFactor*Data.invoice_bounding_box[1], scaleFactor*(Data.invoice_bounding_box[3] - Data.invoice_bounding_box[1]), scaleFactor*(Data.invoice_bounding_box[2] - Data.invoice_bounding_box[0])];
			// let [x1, y1, h1, w1] = [1,10,100,100];
			return [{
				x: x1,
				y: y1,
				height: h1,
				width: w1,
				unit: 'px',
				isNew: false,
				isChanging: false,

			}]
	})

	useEffect(() => {
		if (get_image_from_blob.fetchStatus === 'idle') {
			if (get_image_from_blob.status === 'success') {
				setinvoiceImgurl(get_image_from_blob?.data?.['connection_string'])

			}
			else {
				setinvoiceImgurl(null)
			}
		}

	}, [get_image_from_blob.status, get_image_from_blob.fetchStatus])

	function imgLoaded(id) {
		let id2 = 'dummy_' + id;
		document.getElementById(id2).style.display = 'none';
		document.getElementById(id).style.display = 'block';
		prevWidth.current = document.getElementById('comp_img')?.clientWidth

	}

	function handleError() {
		setinvoiceImgurl(null)
	}

	useEffect(() => {
		const el = document.getElementById('comp_img')
		var scaleFactor;
		if (prevWidth.current){
			 scaleFactor = el?.clientWidth / prevWidth.current 
		}
		else{
			scaleFactor = 1
		}
		prevWidth.current  = el?.clientWidth
		setBox(prev => {
			let tpBx = [...prev]
			tpBx[0].x =scaleFactor*tpBx[0].x
			tpBx[0].y =scaleFactor*tpBx[0].y
			tpBx[0].height =scaleFactor*tpBx[0].height
			tpBx[0].width =scaleFactor*tpBx[0].width
			return tpBx

		})
		

	}, [imgSize])


	const customRender = (props)=> {
		return (
			<div className="areaBoxOuter">
	
				</div>
			
		)
	}
	


	return (
		<>
			<div className={`${styles.compHldr} bg-gray-100 rounded-md`}>



				<div id="parent-invoice" className="h-full w-full overflow-scroll">
					<div className='bg-white shadow-md border rounded-full px-3 py-2 absolute top-0 right-5 z-20 flex items-center'>

						<button className='mx-2 border-0 leading-3' onClick={() => setimgSize(p => (parseInt(p) + 40).toString())}>
							<span
								title={'increase size'}
								className="material-icons text-sm leading-none cursor-pointer text-blue-800"
							>
								add
							</span>
						</button>
						<button className='mx-2 border-0 leading-3' onClick={() => setimgSize(p => (parseInt(p) - 40).toString())}>
							<span
								title={'increase size'}
								className="material-icons text-sm leading-none cursor-pointer text-blue-800"
							>
								remove
							</span>
						</button>
					</div>

					<div key={`main_page_invoice_${0}`} className='relative' >

						<div
							key={`dummy_box_${0}`}
							id={`dummy_box_id_${0}`}
							style={{
								height: Data?.invoice_image_height,
								width: Data?.invoice_image_width,
								display: 'none',
								margin: Margin

							}}
							className=" bg-gray-400 flex flex-col justify-center items-center"
						>
							<LoaderFull />
						</div>
						<div style={{
							height: Data?.invoice_image_height,
							width: Data?.invoice_image_width,
							// margin: Margin

						}} className='absolute left-0 top-0  z-10' ></div>

						<div

							className=' w-full h-full hideExtraBox'
							style={{
								margin: Margin
							}}
							id={`box_id_${0}`}
							key={`box_${0}`}
						>

							<AreaSelector
								areas={Box}
								minHeight={5}
								minWidth={5}
								wrapperStyle={{}}
								globalAreaStyle={{
									border: '2px solid #47a6ff',
									backgroundColor: 'rgba(255,255,255,0.1)',
									opacity: '1',
								}}

								customAreaRenderer={customRender}
							>
								<img
								id='comp_img'
								alt='inv_img'
								onError={() => handleError()}
								onLoad={() => imgLoaded(`box_id_${0}`)}
								src={invoiceImgurl}
								className="max-w-none"
								style={{
									width: imgSize + 'px'
								}}
							></img>
							</AreaSelector>






							

						</div>
					</div>
				</div>

			</div>
		</>
	);
}

export default InvoicePane;
