import React, { useEffect, useRef } from 'react'
import { RenderDropdown } from './dropdown';
import swal from 'sweetalert';


export const CustomRenderBox = ({
    Boxes,
    setBoxes,
    areaProps,
    fieldsExtracted,
    tablesExtracted,
    PageNo,
    refList,
    Lables,
    setchangeDetect,
    setchangeAdd,
    DocLockedForMe
}) => {


    const lableEl = useRef(null)

    function DeletePop(areaNum) {
        swal('Are you sure you want to Delete?', {
            buttons: ['No', 'Yes'],
        }).then((value) => {
            if (value) {
                // User clicked "Yes" button
                let deleteIndex = areaNum - 1;

                let allTags = [...Boxes];
                if (deleteIndex !== -1) {
                    //   let flds = { ...fieldsExtracted }
                    //   delete flds[allTags[deleteIndex]['entity']]
                    allTags.splice(deleteIndex, 1);
                    //   setfieldsExtracted(flds)
                    setBoxes(allTags);
                    if (DocLockedForMe){
                        setchangeDetect(true)
                        setchangeAdd(p=> p+1)

                    }
                }

            }
        });
    }

    useEffect(() => {
        function handleClass() {
            refList.current.map(itm => {
                itm.current.classList.remove("clicked")
                itm.current.style.zIndex = 2 
                return null
            }
            )
            lableEl.current.classList.add("clicked")
            lableEl.current.style.zIndex = 3
        }

        if (lableEl.current) {
            refList.current.push(lableEl)
            var node = lableEl.current.parentNode
            handleClass()
            node.addEventListener('click', handleClass);

        }
    let observerRefValue = refList?.current
    
      return () => {
        if (observerRefValue) {
            observerRefValue.splice(observerRefValue.indexOf(lableEl))
            node?.removeEventListener('click', handleClass);
        }
           
      }
    }, [])
    

    return (
        <div ref={lableEl} className="areaBoxOuter" key={areaProps.areaNumber}>
            <button
                type="button"
                className="delBBBtn"
                onClick={() => DeletePop(areaProps.areaNumber)}
            >
                &times;
            </button>
            <RenderDropdown
                    Boxes={Boxes}
                    setBoxes={setBoxes}
                    areaProps={areaProps}
                    fieldsExtracted={fieldsExtracted}
                    tablesExtracted={tablesExtracted}
                    PageNo={PageNo}
                    Lables={Lables}
                    setchangeDetect={setchangeDetect}
                    setchangeAdd={setchangeAdd}
                    DocLockedForMe={DocLockedForMe}
                />
            
        </div>
    )
}