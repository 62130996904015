import React from 'react';
import Notify from './notify';

function NotificationIndex() {
	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				{/* Right Side - White workspaces */}
				<Notify />
			</div>
		</>
	);
}

export default NotificationIndex;
