import React, { useState, useEffect } from 'react';
//import { toast } from 'react-toastify'

import { Autocomplete, TextField } from '@mui/material';
import { GetVendors } from './hooks';

function FilterVendorName({
    setVendorName,
	VendorName,
	setVendorNameId,
    ClientId,
    VendorNameId,
    setReportId,
    setReportName,
    setReportNameNew

}) {

    const [options, setoptions] = useState([]);
	const client_list_hook = GetVendors(ClientId);
	const [, setFocus] = useState(false);


	useEffect(() => {
		if (client_list_hook.data) {
			setoptions(client_list_hook.data?.data.map((itm) => (itm?.name)));
            if (VendorNameId) {
                let vendorObj = client_list_hook.data?.data?.find((o) => (o?._id === VendorNameId));
                setVendorName(vendorObj?.name);
            }
		}
	}, [client_list_hook.data, VendorNameId, setVendorName]);

    function changeProjectType(val) {
		setVendorName(val);
		let obj = client_list_hook.data.data.find((o) => (o._id === val || o.name === val));
		if (obj) {
            
			setVendorNameId(obj._id);
		}
        else{
            setVendorNameId(null);
            setVendorName(null);
        }
        setReportId(null);
		setReportName(null);
        setReportNameNew(null)
	}

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            {client_list_hook.isPending ? (
                                <p className="px-4 h-[38px] flex flex-col items-center justify-center border border-1 border-slate-300 rounded-md leading-none">
                                    {/* Vendor Name{' '} */}
                                    <span className="text-[8px] text-slate-400 ps-1 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) =>
                                        option === value || value === '' || value == null
                                    }
                                    id="combo-box-demo"
                                    className='expCat'
                                    options={options}
                                    value={VendorName || null}
                                    renderInput={(params) => (
                                        <TextField {...params} label={'Vendor Name'} />
                                    )}
                                    onChange={(event, value) => changeProjectType(value)}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    // onInputChange={(event, newInputValue) => {
                                    //     setProjectType(newInputValue);
                                    //   }}
                                />
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterVendorName;


export function ClientRequired() {
    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            
                                <div className="px-4 h-[38px] flex flex-col justify-center text-left border border-1 border-slate-300 rounded-md leading-none">
                                    <p className='text-gray-600 font-light'>
                                        Vendor Name{' '}
                                        {/*  <span className="text-[8px] text-slate-400 ps-0 inline-block">
                                                Select Client First
                                            </span> */}
                                    </p>
                                </div>
                          

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
}