import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isSignedIn: false,
	access_token: null,
	email: null,
	mobile: null,
	name: null,
	profile: null,
	role: null,
	register_id: null,
	username: null,
	loginError: null,
	is_locked:false
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		redux_login: (state, action) => {
			state.isSignedIn = true;
			state.access_token = action.payload.auth_token;
			state.name = action.payload.name;
			state.role = action.payload.role;
			state.applied_for = action.payload.applied_for;
			state.loginError = null;
			state.register_id = action.payload.register_id;
			state.email = action.payload.email;
			state.profile_picture_url = action?.payload?.profile_picture_url || '';
		},
		redux_updateProfile: (state, action) => {
			state.name = action.payload.name;
			state.email = action.payload.email;
		},
		redux_logout: (state) => {
			state.isSignedIn = false;
			state.access_token = null;
			state.email = null;
			state.mobile = null;
			state.name = null;
			state.profile = null;
			state.role = null;
			state.register_id = null;
			state.username = null;
			state.loginError = null;
			state.applied_for = null;
		},
		remove_lock: (state, action) => {
			state.is_locked = false
		},
		put_lock: (state, action) => {
			state.is_locked = true
		},
	},
});

// Action creators are generated for each case reducer function
export const { redux_login, redux_logout, redux_updateProfile , remove_lock,put_lock } =
	authSlice.actions;

export default authSlice.reducer;
