export function FormatInsideView(DATA) {
	if (typeof DATA !== 'object') {
		return {
			fieldsExtracted_: [],
			tablesExtracted_: [],
			Flat_Boxes_: [],
			imgUrls_: [],
			imgData_: [],
			errorPages_: [],
			parsable: false,
			locked_for_me_: false,
			mismatch_ids:{},
			is_csv: false
		};
	}
	

	function coordinateToBox(cords) {
		// return [x(top) , y(left), ,height , width]
		return [cords[0], cords[1], cords[3] - cords[1], cords[2] - cords[0]];
	}

	try {
		// document level variables
		let imgUrls = {};
		let mismatch_ids = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: {},
			}))
		);
		let fieldsExtracted = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: [],
			}))
		);
		let tablesExtracted = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: {},
			}))
		);
		// let TotalBoxes = Object.assign(
		// 	{},
		// 	...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
		// 		[i]: {},
		// 	}))
		// );

		let Flat_Boxes = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: [],
			}))
		);
		let imgData = {};
		let errorPages = new Set();
		let reprocess = false 
		let is_csv = false 
		if ('reprocess_required' in DATA){
			reprocess = DATA['reprocess_required']
		}

		if (reprocess ){
			sessionStorage.setItem('disableSaveBtn' , true)
			sessionStorage.setItem('disableSaveBtnTitle' , 'Invoice is being reprocess')
		}
		else if (DATA['build_model_progress']) { 
			sessionStorage.setItem('disableSaveBtn' , true)
			sessionStorage.setItem('disableSaveBtnTitle' , 'Model build in progress')
		}
		else{
			sessionStorage.setItem('disableSaveBtn' , false)
	 		sessionStorage.setItem('disableSaveBtnTitle' , '')
		}

		if (DATA?.locked_for_me){
			sessionStorage.setItem('last_doc_open' , DATA.files_document_id)
		}
		if ('is_csv' in DATA){
			is_csv = DATA['is_csv']
		}



		Object.keys(DATA.pages).map((pageNo) => {
			pageNo = parseInt(pageNo);
			let nitm = DATA.pages[pageNo];
			// page level variables
			let bx = [];
			let tbox = [];

			imgUrls[pageNo] = nitm.image_path;
			imgData[pageNo] = {
				image_height: nitm.image_height,
				image_width: nitm.image_width,
			};
			nitm?.extracted_entities?.map((itm, ind) => {
				// for (let boxIndex in itm.bounding_box) {
				// let [x1, y1, h1, w1] = coordinateToBox(itm.bounding_box[boxIndex])
				// let val = itm.value[boxIndex]
				let filterFields = fieldsExtracted[pageNo]?.filter(fldEnt => (fldEnt.entity === itm.entity))
				let mxVersion = 0
				if (filterFields?.length > 0) {
					mxVersion = Math.max(...filterFields?.map(it => it.version)) + 1
				}

				let [x1, y1, h1, w1] = itm.bounding_box ?  coordinateToBox(itm.bounding_box) : [null , null , null , null];
				// let val = itm.value;
				fieldsExtracted[pageNo]?.push(itm);
				if (itm?.mismatch_id){
					mismatch_ids[pageNo][itm?.mismatch_id] = 'Loading'
				}
				bx?.push({
					x: x1,
					y: y1,
					height: h1,
					width: w1,
					unit: 'px',
					isNew: false,
					isChanging: false,
					entity: itm.entity,
					box_id: ind,
					version: mxVersion,
					status: 'original',
					page: pageNo
				});
				if (itm.entity_valid === 'false') {
					errorPages.add(pageNo);
				}
				return null
			});

			nitm?.tables?.map((table, index) => {
				let aggTable = {};
				Object.keys(table).map((row_name) => {
					let row_list = table[row_name];
					let row_ext = [];
					row_list.map((cell) => {
						let [x1, y1, h1, w1] = cell.bounding_box ? coordinateToBox(cell.bounding_box): [null , null , null , null]
						tbox?.push({
							x: x1,
							y: y1,
							height: h1,
							width: w1,
							unit: 'px',
							isNew: false,
							isChanging: false,
							entity: cell.entity,
							table: `table_${index}`,
							column: cell.entity,
							page: pageNo,
							status: 'original',
							row_name: row_name
						});
						row_ext.push({...cell, table: `table_${index}`,});
						if (cell?.mismatch_id){
							mismatch_ids[pageNo][cell?.mismatch_id] = 'Loading'
						}
						if (cell.entity_valid === 'false') {
							errorPages.add(pageNo);
						}
						return null
					});
					aggTable[row_name] = row_ext;
					return null
				});
				tablesExtracted[pageNo][`table ${index}`] = aggTable;
				return null
			});

			// TotalBoxes[pageNo] = {
			// 	extracted_entities: bx,
			// 	tables: tbox,
			// 	name: nitm.name,
			// 	image_path: nitm.image_path,
			// 	table_annotated: false,
			// 	entity_annotated: false,
			// };
			if (nitm.flat_list) {

				Flat_Boxes[pageNo] = nitm?.flat_list.map(itm => {
					let [x1, y1, h1, w1] = itm.bounding_box ?  coordinateToBox(itm.bounding_box) : [null , null , null , null];

					return {
						x: x1,
						y: y1,
						height: h1,
						width: w1,
						unit: 'px',
						isNew: false,
						isChanging: false,
						entity: itm.entity,
						version: 0,
						status: 'original',
					}
				})
			}
			else {
				Flat_Boxes[pageNo] = bx.concat(tbox)
			}

			return null
		});
		return {
			fieldsExtracted_: fieldsExtracted,
			tablesExtracted_: tablesExtracted,
			Flat_Boxes_: Flat_Boxes,
			imgUrls_: imgUrls,
			imgData_: imgData,
			errorPages_: Array.from(errorPages),
			parsable: true,
			locked_for_me_: DATA?.locked_for_me,
			mismatch_ids:mismatch_ids, 
			is_csv: is_csv
		};
	} catch (err) {
		console.log(err);
	}
}

function BoxToCoordinate(box) {
	// return [ x(left) , y (top) , x(bottom) , y (right) ]
	return [box.x, box.y, box.width + box.x, box.y + box.height];
}

export function ReadyToUpload(
	OriginalSnap,
	TotalBx,
	fieldsExtracted,
	tablesExtracted
) {
	function ConvertTableToJson(table) {
		var ret = [];
		Object.keys(table).map((col) => {
			ret = ret.concat(
				table[col].map((itm) => {
					const obj = {};
					obj[col] = itm;
					return obj;
				})
			);
			return null
		});
		return ret;
	}



	let body = {
		document_id: OriginalSnap[0].files_documentId,
		pages: Array.from({ length: TotalBx?.length }, () => {
			return {
				extracted_entities: [],
				tables: [],
				name: '',
				image_path: '',
				table_annotated: false,
				entity_annotated: false,
			};
		}),
	};

	TotalBx.map((pageData, pageNo) => {
		let ent_ann = false;
		let tab_ann = false;
		pageData.extracted_entities.map((ent, entInd) => {
			let bestVal = null;
			let filteredFlds = fieldsExtracted.filter(
				(flds) => flds.entity === ent.entity
			);
			let ret = filteredFlds
				.filter((itm) => itm.version === ent.version)
				.map((itm) => itm.value);
			if (ret?.length === 0) {
				ret = filteredFlds
					.filter((itm) => itm.version === 0)
					.map((itm) => itm.value);
			}
			bestVal = ret[0];

			let ifExists = body.pages[pageNo].extracted_entities.findIndex(
				(itm) => itm.entity === ent.entity
			);
			if (ifExists !== -1) {
				body.pages[pageNo].extracted_entities[ifExists].value?.push(bestVal);
				body.pages[pageNo].extracted_entities[ifExists].bounding_box?.push(
					BoxToCoordinate(ent)
				);
			} else {
				body.pages[pageNo].extracted_entities?.push({
					entity: ent.entity,
					value: [bestVal],
					bounding_box: [BoxToCoordinate(ent)],
				});
			}

			if (ent.status !== 'original') {
				ent_ann = true;
			}

			return null;
		});

		pageData.tables.map((table, tableInd) => {
			body.pages[pageNo].tables?.push({
				coordinates: BoxToCoordinate(table),
				table: ConvertTableToJson(tablesExtracted[table.entity]),
			});

			if (table.status !== 'original') {
				tab_ann = true;
			}
			return null;
		});
		body.pages[pageNo].image_path = OriginalSnap[0].pages[pageNo].image_path;
		body.pages[pageNo].name = OriginalSnap[0].pages[pageNo].name;
		body.pages[pageNo].table_annotated = tab_ann;
		body.pages[pageNo].entity_annotated = ent_ann;
		return null;
	});
	return body;
}

function checkAnnotated(fldObj, boxObj) {
	if (['new added', 'entity changed', 'coordinate changed', 'entity coordinate changed'].includes(boxObj?.status)) {
		return true
	}
	else if (['value changed', 'new added'].includes(fldObj?.status)) {
		return true
	}
	else if (fldObj?.originalValue !== fldObj?.value) {
		return true
	}

	// write a logic to check if its changed from original state
	return false
}

export function prepareFlatList(bx, flds, tbls) {
	// console.log(bx, "box");
	// console.log(flds, "flds");
	// console.log(tbls, "tbls");
	let lableHaveBoxes = new Set()

	let fldFlat = []
	let notLable = 0
	bx.map((boxObj) => {
			fldFlat.push({
				entity: boxObj.entity,
				bounding_box: BoxToCoordinate(boxObj),
				annotated: checkAnnotated({}, boxObj)
			})
			if (boxObj.entity){
				lableHaveBoxes.add(boxObj.entity)
			}
			else{
				notLable+=1
			}
			return null 
		})

	// boxes without lable
	if (notLable !==0){
		return  { res: [], extracted_entities: [], tables: [], status: "Annotation Box label missing"}
	}

	// lable without boxes
	let allLables = new Set()


		
		let extracted_entities = []
		let tpTables = []
		flds.map((fldObj, fldInd) => {
			extracted_entities.push({...fldObj,
				annotated: checkAnnotated(fldObj, {}),
				source: checkAnnotated(fldObj, {}) ? 'human' : fldObj.source,
			})
			allLables.add({entity:fldObj.entity , status : fldObj.status })
			return null
		})

		let emptyCol = false


		Object.entries(tbls).map((ent1) => {
			let [, table] = ent1
			let tpTable = {}
			Object.entries(table).map(ent2 => {
				let [rowName, rowList] = ent2
				rowList.map(cellObj => {
					
					if (cellObj.entity?.length ===0 && cellObj.status ==='new added'){
						emptyCol = true
					}

					if (rowName in tpTable) {
						tpTable[rowName].push({...cellObj,
							annotated: checkAnnotated(cellObj, {}),
							confidence: 'confidence' in cellObj ? cellObj.confidence : 1.0,
							source: checkAnnotated(cellObj, {}) ? 'human' : cellObj.source,
						})
						allLables.add(cellObj.entity)
					}
					else {
						tpTable[rowName] = [{...cellObj,
							annotated: checkAnnotated(cellObj, {}),
							confidence: 'confidence' in cellObj ? cellObj.confidence : 1.0,
							source: checkAnnotated(cellObj, {}) ? 'human' : cellObj.source,
						}]
						allLables.add({entity:cellObj.entity , status : cellObj.status })
					}
					return null
				})
				return null
			})
			tpTables.push(tpTable)
			return null
		})
		if (emptyCol){
			const ret = `Empty Column name detected`;
			return  { res: [], extracted_entities: [], tables: [], status: ret}
		}
		const filteredItems = [...allLables].filter(item => !lableHaveBoxes.has(item.entity) && item.status ==='new added')

		if (filteredItems.length>0){
			const ret = `Annotation missing for field ${filteredItems.map(itm => itm.entity).join(', ')}`;
			return  { res: [], extracted_entities: [], tables: [], status: ret}
		}

		// console.log(fldFlat , "flat list");
		// console.log(extracted_entities , "ext entities");
		// console.log(tpTables , "tables");

		return { res: fldFlat, extracted_entities: extracted_entities, tables: tpTables, status: 1 }

	

}

export function CSVprepareFlatList(flds, tbls) {
	
	// lable without boxes
	let allLables = new Set()


		
		let extracted_entities = []
		let tpTables = []
		flds.map((fldObj, fldInd) => {
			extracted_entities.push({...fldObj,
				annotated: false,
				source: fldObj?.source,
			})
			allLables.add({entity:fldObj.entity , status : fldObj.status })
			return null
		})

		let emptyCol = false


		Object.entries(tbls).map((ent1) => {
			let [, table] = ent1
			let tpTable = {}
			Object.entries(table).map(ent2 => {
				let [rowName, rowList] = ent2
				rowList.map(cellObj => {
					
					if (cellObj.entity?.length ===0 && cellObj.status ==='new added'){
						emptyCol = true
					}

					if (rowName in tpTable) {
						tpTable[rowName].push({...cellObj,
							annotated: false,
							confidence: 'confidence' in cellObj ? cellObj.confidence : 1.0,
							source: cellObj?.source,
						})
						allLables.add(cellObj.entity)
					}
					else {
						tpTable[rowName] = [{...cellObj,
							annotated: false,
							confidence: 'confidence' in cellObj ? cellObj.confidence : 1.0,
							source: cellObj?.source,
						}]
						allLables.add({entity:cellObj.entity , status : cellObj.status })
					}
					return null
				})
				return null
			})
			tpTables.push(tpTable)
			return null
		})
		if (emptyCol){
			const ret = `Empty Column name detected`;
			return  { res: [], extracted_entities: [], tables: [], status: ret}
		}

		

		// console.log(fldFlat , "flat list");
		// console.log(extracted_entities , "ext entities");
		// console.log(tpTables , "tables");

		return { extracted_entities: extracted_entities, tables: tpTables, status: 1 }

	

}

export function getCurrentDateTime() {
	const now = new Date();

	const year = now.getUTCFullYear();
	const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
	const day = now.getUTCDate().toString().padStart(2, '0');
	const hours = now.getUTCHours().toString().padStart(2, '0');
	const minutes = now.getUTCMinutes().toString().padStart(2, '0');
	const seconds = now.getUTCSeconds().toString().padStart(2, '0');
	const milliseconds = now.getUTCMilliseconds().toString().padStart(3, '0');

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}000`;
}