import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Autocomplete, TextField } from '@mui/material';
import { GetCategorylist } from '../upload-docs/hooks';
import FltrClientName from './client-name-flt';
import FilterVendorName, { ClientRequired } from './vendor-name';
import FilterBatchName from './batch-name';
import { toast } from 'react-toastify';
import {useSearchParams} from "react-router-dom";

const DAYS_DIFF_ALLOWED = 62

export default function FilterComponent({ Args, setArgs, mismatch_list_hook, report_list_hook,Sort,reportId,setReportId,setselectedInvoices,setselectedMismatches }) {
	const [fromValue, setFromValue] = React.useState(getFirstDayOfLastMonth);
	const [ToValue, setToValue] = React.useState(() => {
		return dayjs(new Date())
	});
	const category_list_hook = GetCategorylist();
	// const report_list_hook = useGetReportlist();

	const [, setFocus] = useState(false);
	const [options, setoptions] = useState([]);
	const [Category, setCategory] = useState(null);
	const [CategoryId, setCategoryId] = useState(null);

	const [ClientName, setClientName] = useState(null);
	const [ClientId, setClientId] = useState(null);
	const [VendorName, setVendorName] = useState(null);
	const [VendorNameId, setVendorNameId] = useState(null);
	const [BatchName, setBatchName] = useState(null)
	const [BatchNameId, setBatchNameId] = useState(null)
	const [batchList,setBatchList] = useState(null);
	const [minDatePossible] = useState(getFirstDayOfLastMonth)
	const [reloadDisable, setreloadDisable] = useState(true)

	const [searchParams, setSearchParams] = useSearchParams();
	
	const [reports, setReports] = useState([]);
	const [reportName, setReportName] = useState(null);
	const [reportNameNew, setReportNameNew] = useState(null);
	

	useEffect(() => {
		//console.log('in');
		
		let firstDate = minDatePossible
		if (fromValue) {
			firstDate = fromValue
		}
		setArgs(p => {
			return {
				...p,
				date_from: (firstDate) ? dayjs(firstDate.$d).format("MM-DD-YYYY") : null,
				date_to: (ToValue) ? dayjs(ToValue.$d).format("MM-DD-YYYY") : null,
				category_id: CategoryId,
				client_id: ClientId,
				vendor_id: VendorNameId,
				batch_id: BatchNameId,
				batch_name: BatchName,
				client_name: ClientName,
				vendor_name: VendorName,
				category: Category,
				category_name:Category,
				report_id: reportId,
				report_name:reportNameNew,
				
			}
		})
	}, [CategoryId, VendorNameId, ClientId, BatchNameId, fromValue, ToValue, reportId, setVendorNameId, setReportId, setBatchNameId, setCategoryId, setFromValue, setToValue, minDatePossible, setArgs, BatchName, ClientName, VendorName, Category, reportNameNew])



	function setDebounceSearch() {

		if (!ClientId) {
			setreloadDisable(true)
			toast.error("Please select one client")
			return
		}

		if (!CategoryId) {
			setreloadDisable(true)
			toast.error("Please select one category")
			return
		}

		if (!fromValue) {
			setreloadDisable(true)
			toast.error(`Please select Start Date`)
			return
		}
		if (!ToValue) {
			setreloadDisable(true)
			toast.error(`Please select End Date`)
			return
		}
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		let dateDiff = Math.ceil((new Date(ToValue) - new Date(fromValue)) / millisecondsPerDay)
		if (dateDiff > DAYS_DIFF_ALLOWED) {
			toast.error(`Please select a date range that is between ${DAYS_DIFF_ALLOWED} days.`)
			return
		}

		let body = { ...Args, ...Sort,page:1 }

		//console.log(body);
		
		setreloadDisable(true)

		// const url = new URL(window.location.href);
		searchParams.set('report_id', reportId);
		searchParams.set('report_name', reportNameNew);
		searchParams.set('client_id', ClientId);
		searchParams.set('client_name', ClientName);
		searchParams.set('vendor_id', VendorNameId);
		searchParams.set('vendor_name', VendorName);
		searchParams.set('batch_id', BatchNameId);
		searchParams.set('batch_name', BatchName);
		searchParams.set('category_id', CategoryId);
		searchParams.set('category_name', Category);
		searchParams.set('from_date', body?.date_from);
		searchParams.set('to_date', body?.date_to);
		searchParams.set('mismatch_list_page_no', 1);
		setSearchParams(searchParams)
		//searchParams.delete('param2');
		// window.history.replaceState(null, null, url); // or pushState

		mismatch_list_hook.hook.mutate(body)
		setselectedInvoices(new Set([]))
		setselectedMismatches(new Set([]))
		
	}

	useEffect(() => {
		if (category_list_hook.data) {
			//console.log(category_list_hook.data);
			setoptions(category_list_hook.data.data.map((itm) => itm.name));
		}
	}, [category_list_hook.data]);

	useEffect(() => {
		if (report_list_hook.data) {
			setReports(report_list_hook.data.data);
		}
	}, [report_list_hook.data]);

	useEffect(() => {

		setreloadDisable(false)
	}, [Args])

	useEffect(() => {
		if (mismatch_list_hook.hook.isError) {
			setreloadDisable(false)			
		}
	}, [mismatch_list_hook.hook.isError])


	function changeCategory(val) {
		setCategory(val);
		let obj = category_list_hook.data.data.find((o) => o.name === val);
		if (obj) {
			setCategoryId(obj._id);
		}
		else {
			setCategoryId(null);
		}
		setReportId(null);
		setReportName(null);
		setReportNameNew(null)
	}

	function changeReport(val) {
		//console.log(val);
		
		setReportName(val);
		let reportObj = report_list_hook?.data?.data?.find((o) => o?.name === val?.name);
		if (reportObj) {
			//console.log(reportObj);
			setReportNameNew(reportObj?.name)
			setReportId(reportObj?._id);
			
			setClientId(reportObj?.client_id);
			setClientName(reportObj?.client_name);
			
			
			if(reportObj?.vendor_id){
				setVendorNameId(reportObj?.vendor_id);
				//setVendorName(reportObj?.vendor_id);
			}
			else{
				setVendorNameId(null);
				setVendorName(null);
			}
			
			setCategoryId(reportObj?.category_id);
			let categoryObj = category_list_hook?.data?.data?.find((c) => c?._id === reportObj?.category_id);
			if (categoryObj) {
				setCategory(categoryObj?.name); // Set category name
			} else {
				setCategory(null); // Clear category name if no category is found
			}
			
			setBatchNameId(reportObj?.batch_id);
			let batchObj = batchList?.find((c) => c?._id === reportObj?.batch_id);
			if (batchObj) {
				setBatchName(batchObj?.name); // Set batch name
			} else {
				setBatchName(null); // Clear batch name if no batch is found
			}

			const fromDate = dayjs(val.from_date);
			const toDate = dayjs(val.to_date);
			setFromValue(fromDate);
			setToValue(toDate);
		}
		else {
			setReportId(null);
			setReportNameNew(null)
			setClientId(null);
			setClientName(null);
			setBatchName(null);
			setBatchNameId(null);
			setVendorName(null);
			setVendorNameId(null);
			setCategory(null);
			setCategoryId(null);
			setFromValue(getFirstDayOfLastMonth);
			setToValue(() => {
				return dayjs(new Date())
			});
		}

	}

	useEffect(() => {
		//console.log('in');
		//console.log(searchParams.size);

		if(searchParams.size>0){
			const get_report_id = searchParams.get('report_id');
			const get_report_name = searchParams.get('report_name');
			const get_client_id = searchParams.get('client_id');
			const get_client_name = searchParams.get('client_name');
			const get_vendor_id = searchParams.get('vendor_id');
			const get_vendor_name = searchParams.get('vendor_name');
			const get_batch_id = searchParams.get('batch_id');
			const get_batch_name = searchParams.get('batch_name');
			const get_category_id = searchParams.get('category_id');
			const get_category_name = searchParams.get('category_name');
			const get_from_date = searchParams.get('from_date');
			const get_to_date = searchParams.get('to_date');
	
			const fromDate = dayjs(get_from_date);
			const toDate = dayjs(get_to_date);
	
			//console.log(get_client_id);
	
			if(get_client_id && !mismatch_list_hook.hook.isPending){
				if(get_report_id !== 'null'){
					setReportId(get_report_id)
					setReportNameNew(get_report_name)
					setReportName({
						batch_id:(get_batch_id)?get_batch_id:null,
						category_id:get_category_id,
						client_id:get_client_id,
						client_name:get_client_name,
						from_date:get_from_date,
						name:get_report_name,
						to_date:get_to_date,
						vendor_id:(get_vendor_id)?get_vendor_id:null,
						_id:get_report_id
					});
				}
				
				//setRe(get_client_name)
		
				setClientId(get_client_id)
				setClientName(get_client_name)
		
				if(get_vendor_id !== 'null'){
					setVendorNameId(get_vendor_id)
					setVendorName(get_vendor_name)
				}
		
				if(get_batch_id !== 'null'){
					setBatchName((get_batch_name))
					setBatchNameId(get_batch_id)
				}
				
				setCategory(get_category_name)
				setCategoryId(get_category_id)
				setFromValue(fromDate)
				setToValue(toDate)
			}
		}
		else{
			setReportId(null);
			setReportNameNew(null)
			setReportName(null)
			setClientId(null);
			setClientName(null);
			setBatchName(null);
			setBatchNameId(null);
			setVendorName(null);
			setVendorNameId(null);
			setCategory(null);
			setCategoryId(null);
			setFromValue(getFirstDayOfLastMonth);
			setToValue(() => {
				return dayjs(new Date())
			});
		}
		

	}, [mismatch_list_hook.hook.isPending, searchParams, setReportId])
	

	return (
		<>
		
			<div className="flex items-center gap-3 text-sm justify-center flex-wrap mb-3 pt-3 largeFltr">
				<div className="flex-none w-32 missDD">
					{report_list_hook.isFetching ? (
						<p className="px-4 h-[38px] flex flex-col items-center justify-center border border-1 border-slate-300 rounded-md">
							{/* Report Name{' '} */}
							<span className="text-xs text-slate-400 ps-4 inline-block">
								Loading..
							</span>
						</p>
					) : (
						<Autocomplete
							isOptionEqualToValue={(option, value) =>
								option === value || value === '' || value == null
							}
							disablePortal
							id="report-box"
							className='bg-white rounded-sm expCat'
							options={reports}
							value={reportName || null }
							getOptionLabel={(option) => option.name || ''}
							renderInput={(params) => (
								<TextField {...params} label="Report Name" />
							)}
							onChange={(event, value) => changeReport(value)}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
						/>
					)}
				</div>
				<div className="flex-none w-32 missDD">
					{/* {ClientId} */}
					<FltrClientName
						setClientName={setClientName}
						ClientName={ClientName}
						setClientId={setClientId}
						ClientId={ClientId}
						setReportId={setReportId}
						setReportName={setReportName}
						setReportNameNew={setReportNameNew}
					/>
				</div>
				<div className="flex-none w-32 missDD">
					{ClientId ?
						<FilterVendorName
							setVendorName={setVendorName}
							VendorName={VendorName}
							setVendorNameId={setVendorNameId}
							VendorNameId={VendorNameId}
							ClientId={ClientId}
							setReportId={setReportId}
							setReportName={setReportName}
							setReportNameNew={setReportNameNew}
						/> : <ClientRequired />}
				</div>
				<div className="flex-none w-32 missDD">
					<FilterBatchName
						setBatchName={setBatchName}
						BatchName={BatchName}
						setBatchNameId={setBatchNameId}
						BatchNameId={BatchNameId}
						setBatchList = {setBatchList}
						setReportId={setReportId}
						setReportName={setReportName}
						setReportNameNew={setReportNameNew}
					/>
				</div>
				<div className="flex-none w-36 missDD">
					{category_list_hook.isFetching ? (
						<p className="px-4 h-[38px] flex flex-col items-center justify-center border border-1 border-slate-300 rounded-md">
							{/* Expense Category{' '} */}
							<span className="text-xs text-slate-400 ps-4 inline-block">
								Loading..
							</span>
						</p>
					) : (
						<Autocomplete
							isOptionEqualToValue={(option, value) =>
								option === value || value === '' || value == null
							}
							disablePortal
							id="combo-box-demo"
							className='expCat'
							options={options}
							value={Category || null}
							renderInput={(params) => (
								<TextField {...params} label="Expense Category *" />
							)}
							onChange={(event, value) => changeCategory(value)}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
						/>
					)}
				</div>			
				<div className='flex-none flex gap-2 w-md relative'>
					<p className='text-primary text-[10px] absolute left-[47px] top-[-18px] 2xl:top-[-20px]'>Please select a date range upto 60 days</p>
					<div className="dateHldr missDD flex items-center gap-3">
						<div>From*</div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer components={['DatePicker']}>
								<DatePicker
									slotProps={{ field: { clearable: true } }}
									value={fromValue || null}
									onChange={(event) => { 
										setFromValue(event); 
										setReportId(null);
										setReportName(null);
										setReportNameNew(null)
										if(event?.$d){
											const fromDate = dayjs(event?.$d).format("MM-DD-YYYY");
											
											const date = dayjs(fromDate).add(DAYS_DIFF_ALLOWED, 'day');
											let returnDate;
											if (date.isAfter(dayjs())) {
												returnDate = dayjs();
											} else {
												returnDate = date;
											}
											setToValue(returnDate);

										}
									}}
									// minDate={minDateForFrom(ToValue)}
									maxDate={dayjs(new Date())}
								/>
							</DemoContainer>
						</LocalizationProvider>
					</div>

					<div className="dateHldr missDD flex items-center gap-3">
						<div>To*</div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer components={['DatePicker']}>
								<DatePicker
									slotProps={{ field: { clearable: true } }}
									value={ToValue || null}
									onChange={(newValue) => {
										setToValue(newValue);
										setReportId(null);
										setReportName(null);
										setReportNameNew(null)
									}}
									minDate={fromValue}
									maxDate={maxDateForTo(fromValue)}
								/>
							</DemoContainer>
						</LocalizationProvider>
					</div>
				</div>
				<div>
					
					<button
						type="button"
						disabled={reloadDisable || mismatch_list_hook.hook.isPending}
						onClick={setDebounceSearch}
						className="bg-blue-800 hover:bg-indigo-700 text-xs w-full px-4 py-2 h-10 rounded-md text-white flex items-center font-semibold disabled:bg-slate-300 disabled:text-slate-700"
					>
						<span className="material-icons mr-1 text-sm">refresh</span>
						Search
					</button>
				</div>
	
			</div>
		</>
	);
}

function getFirstDayOfLastMonth() {
	let currentDate = new Date()
	const date = new Date(currentDate);
	date.setDate(1);
	date.setMonth(date.getMonth() - 1);
	return dayjs(date)
}

function maxDateForTo(fromDate) {
	if (!fromDate) {
		return null
	}
	else {

		const date = dayjs(fromDate).add(DAYS_DIFF_ALLOWED, 'day');
		let returnDate;
		if (date.isAfter(dayjs())) {
			returnDate = dayjs();
		} else {
			returnDate = date;
		}
		
		return dayjs(returnDate)

	}

}
