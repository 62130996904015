import React, { useState } from 'react';

import { TextField } from '@mui/material';

import { GetProjectTypes } from './hooks';

function FilterGpoName({
    setGpoName,
	GpoName,
}) {

	const client_list_hook = GetProjectTypes();
	const [Focus, setFocus] = useState(false);

    function changeProjectType(event) {
        setGpoName(event.target.value?event.target.value:null)
	}

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            {client_list_hook.isPending ? (
                                <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                                    Is GPO{' '}
                                    <span className="text-sm text-slate-400 ps-4 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <TextField onFocus={() => setFocus(true)} onChange={(event, value) => changeProjectType(event)} id="outlined-basic" value={GpoName} label="GPO Name" variant="outlined" />
                            )}
                            {GpoName == null && Focus && (
								<small className="text-red-600 ">
									GPO Name is required
								</small>
							)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterGpoName;
