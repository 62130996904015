import React, { useState, useEffect, useRef, useMemo } from 'react';
import FilePlaceholder from './file-placeholder';
import RightSideInvAcc from './rightInvAccordion';
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderFull from '../shared/loader-full-screen';
import { useGetInvDesc, useGetMismatchStatusByIds, useOnlineStatus, useSaveAsDraft, useUpdateLockWindow, useUploadInvoiceDesc } from './hooks';
import { FormatInsideView, prepareFlatList, getCurrentDateTime, CSVprepareFlatList } from './utilFuntions';
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { decodeName, encodeName } from '../utils';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { put_lock } from '../auth/authSlice';
import { useCategoryNameById } from '../list_invoices/hooks';
import AnnotationErrors from './errors-slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


function InvoicesIndex() {
	const [searchParams] = useSearchParams();
	const [OriginalSnap, setOriginalSnap] = useState();
	const [PageNo, setPageNo] = useState(0);
	const [type, settype] = useState('extracted_entities');
	const get_boxes_hook = useGetInvDesc(searchParams.get('invoice_id'));
	const upload_inv_desc = useUploadInvoiceDesc();
	const save_as_draft = useSaveAsDraft();

	const [imgUrls, setimgUrls] = useState(null);
	const [imgData, setimgData] = useState(null);
	const [FlatBoxes, setFlatBoxes] = useState(null);
	const [Boxes, setBoxes] = useState(null);
	const [fieldsExtracted, setfieldsExtracted] = useState(null);
	const [totalFieldsExtracted, settotalFieldsExtracted] = useState({});
	const [totalTablesExtracted, settotalTablesExtracted] = useState({});
	const [tablesExtracted, settablesExtracted] = useState(null);
	const [errorPages, seterrorPages] = useState([]);
	const [errorSelected, seterrorSelected] = useState(null);
	let navigate = useNavigate()
	// const [VersionStatus, setVersionStatus] = useState(0)
	let versionTrack = useRef(null)
	const isOnline = useOnlineStatus()
	const [changeDetect, setchangeDetect] = useState(false)
	const [DocLockedForMe, setDocLockedForMe] = useState(null)
	const update_lock_window = useUpdateLockWindow()
	const [changeAdd, setchangeAdd] = useState(0)
	const prevchangeVal = useRef(0)
	const diffChange = useRef(false)
	const intractVal = useRef(0)
	const [MismatchIds, setMismatchIds] = useState({})
	const mismatch_get_hook = useGetMismatchStatusByIds()
	let dispatch = useDispatch();
	const catname_hook = useCategoryNameById(searchParams.get('category_id'))
	const [IsCSV, setIsCSV] = useState(false)


	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event) => { setAnchorEl(event.currentTarget) }
	//const delete_inv_hook = useDeleteInvoice()
	const [IsOpened, setIsOpened] = useState(false)
	const [selectedInvoices, setselectedInvoices] = useState(new Set([]))
	const handleClose = () => {
		setAnchorEl(null);
	};

	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);
	let flatLen = useMemo(()=> {
		if (FlatBoxes ==null) {
			return 0
		}
		return Object.values(FlatBoxes).reduce((acc,curr) => {
		acc+= curr?.length ? curr?.length : 0
		return acc
	},0)
	}, [FlatBoxes])


	useEffect(() => {
		if (get_boxes_hook.hook.isSuccess && get_boxes_hook.hook.data && !get_boxes_hook.hook.isFetching) {
			setOriginalSnap(get_boxes_hook.hook.data[0]);
			let {
				fieldsExtracted_,
				tablesExtracted_,
				Flat_Boxes_,
				imgUrls_,
				imgData_,
				errorPages_,
				parsable,
				locked_for_me_,
				mismatch_ids,
				is_csv
			} = FormatInsideView(get_boxes_hook.hook.data[0]);
			if (!parsable) {
				toast.error("Data is not JSON parsable")
				return
			}
			settotalFieldsExtracted(fieldsExtracted_);
			if (0 in fieldsExtracted_) {

				setfieldsExtracted(fieldsExtracted_[0]);
			}
			else {
				setfieldsExtracted([]);
			}
			settotalTablesExtracted(tablesExtracted_);
			if (0 in tablesExtracted_) {
				settablesExtracted(tablesExtracted_[0]);
			}
			else {
				settablesExtracted([]);
			}
			setFlatBoxes(Flat_Boxes_);
			if (0 in Flat_Boxes_) {
				setBoxes(Flat_Boxes_[0]);
			}
			else {
				setBoxes([])
			}
			setimgUrls(imgUrls_);
			setimgData(imgData_);
			seterrorPages(errorPages_);
			if (versionTrack.current == null) {
				versionTrack.current = get_boxes_hook.hook.data[0]?.version + 1
			}
			setDocLockedForMe(locked_for_me_)
			setMismatchIds(mismatch_ids)
			if (locked_for_me_) {
				dispatch(put_lock())
			}
			if (is_csv) {
				setSizes(['0%', '100%'])
			}
			setIsCSV(is_csv)
			// setVersionStatus(get_boxes_hook.hook.data[0]?.version+1)
		}
	}, [get_boxes_hook.hook.isSuccess, get_boxes_hook.hook.data, get_boxes_hook.hook.isFetching]);


	function clearVariablesAndRefetch() {
		setOriginalSnap('')
		setPageNo(0)
		settype('extracted_entities')
		setimgUrls(null)
		setimgData(null)
		setFlatBoxes(null)
		setBoxes(null)
		setfieldsExtracted(null)
		settotalFieldsExtracted({})
		settotalTablesExtracted({})
		settablesExtracted(null)
		seterrorPages([])
		seterrorSelected(null)
		sessionStorage.setItem("change", false)
		showAlert.current = 0
		setchangeDetect(false)
		navigate(`/my-invoices?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&invoice_list_page_no=${searchParams.get('invoice_list_page_no')}&invoice_list_sort_key=${searchParams.get('invoice_list_sort_key')}&invoice_list_sort_order=${searchParams.get('invoice_list_sort_order')}`);

	}
	function clearVariablesAfterDraftSave() {
		sessionStorage.setItem("change", false)
		showAlert.current = 0
		setchangeDetect(false)


	}


	function UploadInvDesc() {
		if (!isOnline) {
			toast.error("You are offline, Please connect to internet")
			return
		}
		setchangeAdd(p => p + 1)

		let TotalBx = { ...FlatBoxes };
		let bb = [...Boxes];
		TotalBx[PageNo] = bb;

		let totalFlds = { ...totalFieldsExtracted }
		let tpFlds = [...fieldsExtracted]
		totalFlds[PageNo] = tpFlds

		let totalTbls = { ...totalTablesExtracted }
		let tpTbls = { ...tablesExtracted }
		totalTbls[PageNo] = tpTbls
		let cpPages = Object.keys(OriginalSnap.pages).reduce((acc, key) => {
			acc[key] = {
				image_path: OriginalSnap.pages[key].image_path,
				name: OriginalSnap.pages[key].name,
				image_height: OriginalSnap.pages[key].image_height,
				image_width: OriginalSnap.pages[key].image_width

			}
			return acc;
		}, {});

		let body_nest = {
			_id: OriginalSnap._id,
			files_document_id: OriginalSnap.files_document_id,
			version: versionTrack.current,
			document_annotated: false,
			created_at: OriginalSnap.created_at,
			updated: getCurrentDateTime(),
			pages: cpPages
		}
		let totalPages = 0
		for (let page in imgUrls) {
			let { res, extracted_entities, tables, status } = prepareFlatList(TotalBx[page], totalFlds[page], totalTbls[page])
			if (status === 1) {

				body_nest.pages[page].flat_list = res
				body_nest.pages[page].extracted_entities = extracted_entities
				body_nest.pages[page].tables = tables
				body_nest.pages[page].page = parseInt(page)

			}
			else {
				toast.error(`Page ${parseInt(page) + 1}: ${status}`)
				return
			}
			totalPages += 1
		}
		let body = {
			document_id: OriginalSnap.files_document_id,
			pages: body_nest
		}
		// console.log(body);

		setFlatBoxes((prev) => {
			let bb = JSON.parse(JSON.stringify(Boxes))
			let pgbx = { ...prev };
			pgbx[PageNo] = bb;
			setBoxes(pgbx[PageNo])
			return pgbx;
		});

		settotalFieldsExtracted((prev) => {
			let fe = JSON.parse(JSON.stringify(fieldsExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = fe;
			setfieldsExtracted(pgbx[PageNo]);
			return pgbx;
		});

		settotalTablesExtracted((prev) => {
			let te = JSON.parse(JSON.stringify(tablesExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = te;
			settablesExtracted(pgbx[PageNo]);
			return pgbx;
		});

		// settablesExtracted(totalTablesExtracted[newP]);



		let chunkSize = 50

		upload_inv_desc.mutate({ body: body, chunkSize: chunkSize, totalPages: totalPages })


	}

	function CSVUploadInvDesc() {
		if (!isOnline) {
			toast.error("You are offline, Please connect to internet")
			return
		}
		setchangeAdd(p => p + 1)


		let totalFlds = { ...totalFieldsExtracted }
		let tpFlds = [...fieldsExtracted]
		totalFlds[PageNo] = tpFlds

		let totalTbls = { ...totalTablesExtracted }
		let tpTbls = { ...tablesExtracted }
		totalTbls[PageNo] = tpTbls
		let cpPages = Object.keys(OriginalSnap.pages).reduce((acc, key) => {
			acc[key] = {
				name: OriginalSnap.pages[key].name,
			}
			return acc;
		}, {});

		let body_nest = {
			_id: OriginalSnap._id,
			files_document_id: OriginalSnap.files_document_id,
			version: versionTrack.current,
			document_annotated: false,
			created_at: OriginalSnap.created_at,
			updated: getCurrentDateTime(),
			pages: cpPages
		}
		let totalPages = 0
		for (let page in imgUrls) {
			let { extracted_entities, tables, status } = CSVprepareFlatList(totalFlds[page], totalTbls[page])
			if (status === 1) {

				body_nest.pages[page].extracted_entities = extracted_entities
				body_nest.pages[page].tables = tables
				body_nest.pages[page].page = parseInt(page)

			}
			else {
				toast.error(`Page ${parseInt(page) + 1}: ${status}`)
				return
			}
			totalPages += 1
		}
		let body = {
			document_id: OriginalSnap.files_document_id,
			pages: body_nest
		}



		settotalFieldsExtracted((prev) => {
			let fe = JSON.parse(JSON.stringify(fieldsExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = fe;
			setfieldsExtracted(pgbx[PageNo]);
			return pgbx;
		});

		settotalTablesExtracted((prev) => {
			let te = JSON.parse(JSON.stringify(tablesExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = te;
			settablesExtracted(pgbx[PageNo]);
			return pgbx;
		});

		// settablesExtracted(totalTablesExtracted[newP]);



		let chunkSize = 50

		upload_inv_desc.mutate({ body: body, chunkSize: chunkSize, totalPages: totalPages })


	}

	function SaveAsDraft() {
		if (!isOnline) {
			toast.error("You are offline, Please connect to internet")
			return
		}
		setchangeAdd(p => p + 1)

		let TotalBx = { ...FlatBoxes };
		let bb = [...Boxes];
		TotalBx[PageNo] = bb;

		let totalFlds = { ...totalFieldsExtracted }
		let tpFlds = [...fieldsExtracted]
		totalFlds[PageNo] = tpFlds

		let totalTbls = { ...totalTablesExtracted }
		let tpTbls = { ...tablesExtracted }
		totalTbls[PageNo] = tpTbls
		let cpPages = Object.keys(OriginalSnap.pages).reduce((acc, key) => {
			acc[key] = {
				image_path: OriginalSnap.pages[key].image_path,
				name: OriginalSnap.pages[key].name,
				image_height: OriginalSnap.pages[key].image_height,
				image_width: OriginalSnap.pages[key].image_width

			}
			return acc;
		}, {});

		let body_nest = {
			_id: OriginalSnap._id,
			files_document_id: OriginalSnap.files_document_id,
			version: versionTrack.current,
			document_annotated: false,
			created_at: OriginalSnap.created_at,
			updated: getCurrentDateTime(),
			pages: cpPages
		}
		let totalPages = 0
		for (let page in imgUrls) {
			let { res, extracted_entities, tables, status } = prepareFlatList(TotalBx[page], totalFlds[page], totalTbls[page])
			if (status === 1) {

				body_nest.pages[page].flat_list = res
				body_nest.pages[page].extracted_entities = extracted_entities
				body_nest.pages[page].tables = tables
				body_nest.pages[page].page = parseInt(page)

			}
			else {
				toast.error(`Page ${parseInt(page) + 1}: ${status}`)
				return
			}
			totalPages += 1
		}
		let body = {
			document_id: OriginalSnap.files_document_id,
			pages: body_nest
		}
		// console.log(body);

		setFlatBoxes((prev) => {
			let bb = JSON.parse(JSON.stringify(Boxes))
			let pgbx = { ...prev };
			pgbx[PageNo] = bb;
			setBoxes(pgbx[PageNo])
			return pgbx;
		});

		settotalFieldsExtracted((prev) => {
			let fe = JSON.parse(JSON.stringify(fieldsExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = fe;
			setfieldsExtracted(pgbx[PageNo]);
			return pgbx;
		});

		settotalTablesExtracted((prev) => {
			let te = JSON.parse(JSON.stringify(tablesExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = te;
			settablesExtracted(pgbx[PageNo]);
			return pgbx;
		});

		let chunkSize = 50

		save_as_draft.mutate({ body: body, chunkSize: chunkSize, totalPages: totalPages })


	}

	function CSVSaveAsDraft() {
		if (!isOnline) {
			toast.error("You are offline, Please connect to internet")
			return
		}
		setchangeAdd(p => p + 1)



		let totalFlds = { ...totalFieldsExtracted }
		let tpFlds = [...fieldsExtracted]
		totalFlds[PageNo] = tpFlds

		let totalTbls = { ...totalTablesExtracted }
		let tpTbls = { ...tablesExtracted }
		totalTbls[PageNo] = tpTbls
		let cpPages = Object.keys(OriginalSnap.pages).reduce((acc, key) => {
			acc[key] = {
				name: OriginalSnap.pages[key].name,
			}
			return acc;
		}, {});

		let body_nest = {
			_id: OriginalSnap._id,
			files_document_id: OriginalSnap.files_document_id,
			version: versionTrack.current,
			document_annotated: false,
			created_at: OriginalSnap.created_at,
			updated: getCurrentDateTime(),
			pages: cpPages
		}
		let totalPages = 0
		for (let page in imgUrls) {
			let { extracted_entities, tables, status } = CSVprepareFlatList(totalFlds[page], totalTbls[page])
			if (status === 1) {
				body_nest.pages[page].extracted_entities = extracted_entities
				body_nest.pages[page].tables = tables
				body_nest.pages[page].page = parseInt(page)

			}
			else {
				toast.error(`Page ${parseInt(page) + 1}: ${status}`)
				return
			}
			totalPages += 1
		}
		let body = {
			document_id: OriginalSnap.files_document_id,
			pages: body_nest
		}

		settotalFieldsExtracted((prev) => {
			let fe = JSON.parse(JSON.stringify(fieldsExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = fe;
			setfieldsExtracted(pgbx[PageNo]);
			return pgbx;
		});

		settotalTablesExtracted((prev) => {
			let te = JSON.parse(JSON.stringify(tablesExtracted))
			let pgbx = { ...prev };
			pgbx[PageNo] = te;
			settablesExtracted(pgbx[PageNo]);
			return pgbx;
		});

		let chunkSize = 50

		save_as_draft.mutate({ body: body, chunkSize: chunkSize, totalPages: totalPages })


	}
	useEffect(() => {
		if (upload_inv_desc.isSuccess) {
			toast.success("Successfully updated");
			sessionStorage.setItem('disableSaveBtn', false)
			sessionStorage.setItem('disableSaveBtnTitle', '')
			sessionStorage.removeItem("last_doc_open")
			clearVariablesAndRefetch()
		}
		else if (upload_inv_desc.isError) {
			let split = upload_inv_desc.error?.message?.split('|')
			if (split[0] === '409') {
				toast.error(`${split[1]}`)
				sessionStorage.setItem('disableSaveBtn', true)
				sessionStorage.setItem('disableSaveBtnTitle', 'Build model in progress')
			}
			else {
				toast.error(`${split[1]}`)
			}

		}
	}, [upload_inv_desc.isSuccess, upload_inv_desc.isError]);

	useEffect(() => {
		if (save_as_draft.isSuccess) {
			toast.success("Successfully Saved as Draft");
			sessionStorage.setItem('disableSaveBtn', false)
			sessionStorage.setItem('disableSaveBtnTitle', '')
			clearVariablesAfterDraftSave()
		}
		else if (save_as_draft.isError) {
			let split = save_as_draft.error?.message?.split('|')
			if (split[0] === '409') {
				toast.error(`${split[1]}`)
				sessionStorage.setItem('disableSaveBtn', true)
				sessionStorage.setItem('disableSaveBtnTitle', 'Build model in progress')
			}
			else {
				toast.error(`${split[1]}`)
			}
		}
	}, [save_as_draft.isSuccess, save_as_draft.isError]);

	const [sizes, setSizes] = useState(['70%', '30%']);

	const layoutCSS = {
		height: '100%',
		display: 'block',
	};

	let Lables = useMemo(() => {
		if (fieldsExtracted !== null && tablesExtracted !== null && Boxes !== null) {

			let entList = new Set()
			Object.keys(tablesExtracted).map(table_name => {
				let table = tablesExtracted[table_name]
				Object.keys(table).map(row => {
					let row_list = table[row]
					row_list.map(cell => {
						entList.add(cell.entity.toLowerCase())
						return null
					})
					return null
				})
				return null
			})
			let entitiesHavingBox = Boxes.map(itm => itm.entity)
			let filteredFeilds = fieldsExtracted.filter(itm => !entitiesHavingBox.includes(itm.entity)).map(itm => itm.entity.toLowerCase())
			return filteredFeilds.concat(Array.from(entList))
		}
		else {
			return []
		}

	}, [fieldsExtracted, tablesExtracted, Boxes])

	function navToAlert(toLocation) {
		if (!DocLockedForMe) {
			navigate(toLocation)
		}
		if (showAlert.current % 2 === 1) {
			swal("You have unsaved changes that will be lost if you leave this page.", {
				buttons: ['Cancel', 'Continue'],
			}).then((value) => {
				if (value) {

					navigate(toLocation)
					// Add your logout logic here
				} else {

				}
			})
		} else {
			navigate(toLocation)
		}

	}

	const eventAttached = useRef(0)
	const showAlert = useRef(0)
	useEffect(() => {
		const handlebackBtn = () => {
			if (showAlert.current % 2 === 1) {

				swal("You have unsaved changes that will be lost if you leave this page.", {
					buttons: ['Cancel', 'Continue'],
				}).then((value) => {
					if (value) {

						showAlert.current = 0
						window.history.back()
						// Add your logout logic here
					} else {
						window.history.pushState(null, null, window.location.href)
					}
				})
			}
			else {
				// window.history.go(-1)
			}

		}

		const handleBeforeUnload = (e) => {

			if (showAlert.current % 2 === 1) {
				// sessionStorage.removeItem("history")
				e.preventDefault()
				return (e.returnValue = '')

			}

		}

		if (eventAttached.current === 0) {
			window.addEventListener('popstate', handlebackBtn);
			window.addEventListener('beforeunload', handleBeforeUnload);
			eventAttached.current += 1
		}


		return () => {
			get_boxes_hook.controller.abort();
			eventAttached.current -= 1
			window.removeEventListener('popstate', handlebackBtn);
			window.removeEventListener('beforeunload', handleBeforeUnload);

		};
	}, [])


	useEffect(() => {
		// get_invoice_status_hook.refetch()
		if (sessionStorage.getItem("history") === null) {
			sessionStorage.setItem("history", 1)
			sessionStorage.setItem("change", false)
		}
		else if (sessionStorage.getItem("change") === 'true') {
			window.history.back()
		}
		sessionStorage.setItem('disableSaveBtn', false)
		sessionStorage.setItem('disableSaveBtnTitle', '')

	}, [])

	useEffect(() => {
		if (changeDetect) {
			if (showAlert.current % 2 === 0) {
				showAlert.current = 1
				sessionStorage.setItem("change", true)
				window.history.pushState(null, null, window.location.href)
			}
		}
	}, [changeDetect])


	function showSessionOutpopup() {
		const timerDuration = 10000; // 10 seconds
		let timeLeft = timerDuration / 1000; // Convert to seconds
		const autoSelectNo = setTimeout(() => {
			swal.close();
			navigate(`/my-invoices?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&invoice_list_page_no=${searchParams.get('invoice_list_page_no')}&invoice_list_sort_key=${searchParams.get('invoice_list_sort_key')}&invoice_list_sort_order=${searchParams.get('invoice_list_sort_order')}`);
		}, timerDuration);
		const timerDiv = document.createElement('div');
		timerDiv.innerText = `Time left: ${timeLeft} seconds`;

		const timerInterval = setInterval(() => {
			timeLeft -= 1;
			timerDiv.innerText = `Time left: ${timeLeft} seconds`;
			if (timeLeft <= 0) {
				clearInterval(timerInterval);
			}
		}, 1000);



		swal('Are you still working', {
			content: timerDiv,
			buttons: ['No', 'Yes'],
		}).then((value) => {
			clearTimeout(autoSelectNo)

			if (value) {
				diffChange.current = true;
				intractVal.current = 0;
			} else {
				navigate(`/my-invoices?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&invoice_list_page_no=${searchParams.get('invoice_list_page_no')}&invoice_list_sort_key=${searchParams.get('invoice_list_sort_key')}&invoice_list_sort_order=${searchParams.get('invoice_list_sort_order')}`);
			}
		});
	}

	async function doesIntract() {
		if (diffChange.current) {
			let res = await update_lock_window.mutateAsync({ document_id: searchParams.get('invoice_id') })
			if (res?.message === "updated") {
				diffChange.current = false
				intractVal.current = 0
				return true
			}
			else {
				return false
			}
		}
		else {
			intractVal.current += 1
			return false
		}
	}

	useEffect(() => {
		let intervalId
		if (DocLockedForMe) {
			clearInterval(intervalId)


			intervalId = setInterval(() => {
				doesIntract()
				if (intractVal.current === 10) {
					showSessionOutpopup()
				}

			}, 60000);


		}
		else {

		}

		return () => {
			if (DocLockedForMe) {
				clearInterval(intervalId)
			}
		}
	}, [DocLockedForMe])



	useEffect(() => {
		if (prevchangeVal.current !== changeAdd) {
			prevchangeVal.current = changeAdd
			diffChange.current = true
		}
		else {
			diffChange.current = false
			intractVal.current = 0
		}
	}, [changeAdd])

	useEffect(() => {
		setchangeAdd(p => p + 1)
	}, [PageNo])


	function gotoMismatch(mismatch_id) {
		const encodedClientName = encodeName(decodeName(searchParams.get('client_name')))
		const encodedVendorName = encodeName(decodeName(searchParams.get('vendor_name')))
		const encodeInvoiceName = encodeName(decodeName(searchParams.get('invoice_name')))
		navigate({
			pathname: '/comparison',
			search: createSearchParams({

				mismatch_id: mismatch_id,

				client_id: searchParams.get('client_id'),
				client_name: encodedClientName,
				client_list_page_no: searchParams.get('client_list_page_no'),
				client_list_sort_key: searchParams.get('client_list_sort_key'),
				client_list_sort_order: searchParams.get('client_list_sort_order'),

				vendor_id: searchParams.get('vendor_id'),
				vendor_name: encodedVendorName,
				vendor_list_page_no: searchParams.get('vendor_list_page_no'),
				vendor_list_sort_key: searchParams.get('vendor_list_sort_key'),
				vendor_list_sort_order: searchParams.get('vendor_list_sort_order'),
				category_name: encodeName(decodeName(searchParams.get('category_name'))),
				category_id: encodeName(decodeName(searchParams.get('category_id'))),

				invoice_id: searchParams.get('invoice_id'),
				invoice_name: encodeInvoiceName,
				invoice_list_page_no: searchParams.get('invoice_list_page_no'),
				invoice_list_sort_key: searchParams.get('invoice_list_sort_key'),
				invoice_list_sort_order: searchParams.get('invoice_list_sort_order'),



			}
			).toString()
		})
	}

	async function handleMismatchFetch(searchStr, page_no) {
		let res = await mismatch_get_hook.mutateAsync({ search: searchStr })

		if (res.status === 'found') {
			let payload = {}
			res.data?.map(itm => {
				payload[itm._id] = itm.mismatch_status
				return null
			})
			setMismatchIds(prev => {
				return {
					...prev,
					[page_no]: {
						...prev[page_no],
						...payload
					}
				}
			})
		}
	}


	useEffect(() => {


		if (MismatchIds[PageNo] && Object.values(MismatchIds[PageNo]).includes('Loading')) {
			let page_no = PageNo
			let lst = Object.keys(MismatchIds[PageNo]).map(key => {
				return key
			})
			let searchStr = ''
			for (let id of lst) {
				if (searchStr !== '') {
					searchStr += `&mismatch_ids=${id}`
				}
				else {
					searchStr = `mismatch_ids=${id}`
				}
			}

			handleMismatchFetch(searchStr, page_no)

			//setMismatchIds --



		}


	}, [PageNo, MismatchIds])


	function ClearAllAnnotation() {
		swal("Are you sure you want to clear all annotations ? ", {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {

				settotalFieldsExtracted(prev => {
					let tp = { ...prev }
					Object.keys(tp).map(key => {
						tp[key] = []
						return null
					})
					return tp
				})
				setfieldsExtracted([])
				settablesExtracted({})
				settotalTablesExtracted(prev => {
					let tp = { ...prev }
					Object.keys(tp).map(key => {
						tp[key] = {}
						return null
					})
					return tp
				})


				setFlatBoxes(p => {
					let tp = { ...p }
					for (let k in tp) {
						tp[k] = []
					}
					return tp
				})
				setBoxes([])
			}
		})
	}

	function ClearPageAnnotation() {
		swal("Are you sure you want to clear this Page annotations ? ", {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				settotalFieldsExtracted(prev => {
					let tp = { ...prev }
					tp[PageNo] = []
					return tp
				})
				setfieldsExtracted([])
				settablesExtracted({})
				settotalTablesExtracted(prev => {
					let tp = { ...prev }
					tp[PageNo] = {}
					return tp
				})

				setFlatBoxes(p => {
					let tp = { ...p }

					tp[PageNo] = []
					return tp
				})
				setBoxes([])
			}
		})
	}

	useEffect(() => {
		setFlatBoxes((prev) => {
			let bb = JSON.parse(JSON.stringify(Boxes))
			let pgbx = { ...prev };
			pgbx[PageNo] = bb;
			return pgbx;
		});
	}, [Boxes])























	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				{/* Right Side - White workspaces */}
				{upload_inv_desc.isPending ? <LoaderFull /> :
					<div className="lg:flex lg:h-full">
						<div className='lg:flex-col lg:h-full lg:w-full'>
						{fieldsExtracted == null ? (
							<p>...</p>
						) :
							<div className="px-6 pt-6 pb-3">
								<div className="flex">
									<div className="grow">
										<div className='flex justify-between '>

											<div className="grow text-xs text-gray-900 text-center mb-2">
												{![0, undefined].includes(sessionStorage.getItem('disableSaveBtnTitle')?.length) && <p><b>STATUS-</b> {sessionStorage.getItem('disableSaveBtnTitle')}</p>}
											</div>
											<div className="text-xs text-gray-900 text-center mb-2">
												{DocLockedForMe === false && <span title='The invoice is currently edited by another user.' className="bg-orange-600 inline-flex px-4 py-2 rounded-md text-white  text-sm items-center font-black">
													<span className="material-icons text-sm mr-2">lock</span>Locked</span>}
											</div>

										</div>


										<div>
											<div className='flex justify-between items-center'>
												<h1 className="text-lg font-normal text-primary">
													Description of{' '}
													<strong className="font-black">Invoice</strong>
													{/* {DocLockedForMe &&<p className={`${diffChange.current ? 'bg-green-500' :'bg-red-500'} w-2 h-2 rounded-md`}></p> } */}
												</h1>
												{/* <button onClick={ClearAllAnnotation} className='bg-red-100 text-red-600 py-1 px-2 flex items-center rounded-md text-sm' title='Clear all annotations'>
														<span class="material-icons-outlined text-sm mr-1">clear</span>All annotations
													</button> */}
												{!IsCSV && <div className='clearAnn'>
													<List component="nav" aria-label="Clear">
														<ListItem
															id="export-button"
															aria-haspopup="listbox"
															aria-controls="clear-menu"
															aria-expanded={open ? 'true' : undefined}
															onClick={handleClickListItem}
															className='text-red-600 font-bold text-sm h-[32px]'
														>
															Clear Annotations
															<span className="material-icons text-red-600 text-sm ml-1">
																expand_more
															</span>
														</ListItem>
													</List>
													<Menu
														id="clear-annotations"
														className='clearMenu'
														anchorEl={anchorEl}
														open={open}
														onClose={handleClose}
														MenuListProps={{
															'aria-labelledby': 'clear-button',
															role: 'listbox',
														}}
													>

														<MenuItem onClick={() => {
															handleClose()
															ClearAllAnnotation()
															setIsOpened(true)
														}}>
															All
														</MenuItem>
														<MenuItem onClick={() => {
															handleClose()
															ClearPageAnnotation()
															setIsOpened(true)
														}}>
															Page
														</MenuItem>
													</Menu>
												</div> }
											</div>

											<p className="text-xs text-gray-500 mt-2 h-9">
												<button onClick={() => navToAlert(`/my-clients?client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}`)} >My Clients</button> &nbsp;{' '}
												{'>'} &nbsp;
												{/* Clients - 'TechSolutions Inc.' &nbsp; {'>'} &nbsp;  */}
												<button
													onClick={() => navToAlert(`/my-vendors?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName((searchParams.get('client_name'))))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}`)}
												>
													Client -{' '}
													{decodeName(
														searchParams.get('client_name')
													)}
												</button>{' '}
												&nbsp; {'>'} &nbsp;
												<button
													onClick={() => navToAlert(`/my-invoices?client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName((searchParams.get('client_name'))))}&client_list_page_no=${searchParams.get('client_list_page_no')}&client_list_sort_key=${searchParams.get('client_list_sort_key')}&client_list_sort_order=${searchParams.get('client_list_sort_order')}&vendor_id=${encodeName(decodeName(searchParams.get('vendor_id')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_id=${encodeName(decodeName(searchParams.get('category_id')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}&vendor_list_page_no=${searchParams.get('vendor_list_page_no')}&vendor_list_sort_key=${searchParams.get('vendor_list_sort_key')}&vendor_list_sort_order=${searchParams.get('vendor_list_sort_order')}&invoice_list_page_no=${searchParams.get('invoice_list_page_no')}&invoice_list_sort_key=${searchParams.get('invoice_list_sort_key')}&invoice_list_sort_order=${searchParams.get('invoice_list_sort_order')} `)}

												// to={`/my-invoices?vendor_id=${searchParams.get('vendor_id')}&client_id=${searchParams.get('client_id')}&client_name=${encodeName(decodeName(searchParams.get('client_name')))}&vendor_name=${encodeName(decodeName(searchParams.get('vendor_name')))}&category_name=${encodeName(decodeName(searchParams.get('category_name')))}`}
												>
													Vendor -{' '}
													{decodeName(
														searchParams.get('vendor_name')
													)} ( {catname_hook.data ? catname_hook.data?.name : '..'})
												</button>{' '}
												&nbsp; {'>'} &nbsp; Invoice -{' '}
												{decodeName(searchParams.get('invoice_name'))}
											</p>

										</div>

									</div>

								</div>
								<div className="px-4 pt-5 h-[124px]">

									<div className='flex gap-2 justify-end'>
										<div>
											{PrivilagesList.includes('annotate_document') && (
												<button
													disabled={!DocLockedForMe || save_as_draft.isPending || sessionStorage.getItem('disableSaveBtn') === 'true'}
													onClick={() => {
														if (IsCSV) {
															CSVSaveAsDraft()
														}
														else {
															SaveAsDraft()
														}
													}
													}
													title={sessionStorage.getItem('disableSaveBtnTitle')}
													type="button"
													className="bg-blue-800 hover:bg-indigo-700 px-3 py-2 rounded-md text-white disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500 text-sm flex items-center font-medium whitespace-nowrap overflow-hidden text-ellipsis"
												>
													<span className="material-icons text-sm mr-1">check</span>
													Save as Draft
												</button>
											)}
										</div>

										<div>
											{PrivilagesList.includes('annotate_document') && (
												<button
													disabled={(!IsCSV && flatLen === 0) || !DocLockedForMe || upload_inv_desc.isPending || sessionStorage.getItem('disableSaveBtn') === 'true'}
													onClick={() => {
														if (IsCSV) {
															CSVUploadInvDesc()
														}
														else {
															UploadInvDesc()
														}
													}
													}
													title={sessionStorage.getItem('disableSaveBtnTitle')}
													type="button"
													className="bg-blue-800 hover:bg-indigo-700 px-3 py-2 rounded-md text-white disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:text-gray-500 text-sm flex items-center font-medium whitespace-nowrap overflow-hidden text-ellipsis"
												>
													<span className="material-icons text-sm mr-1">check</span>
													Mark as Complete
												</button>
											)}
										</div>
									</div>

									{errorPages.length !== 0 && <div className="py-1 flex justify-end">
										<div className='relative h-[56px] w-[311px]'>
											<div className='static w-[311px]'>
												<AnnotationErrors
													seterrorSelected={seterrorSelected}
													errorSelected={errorSelected}
													errorPages={errorPages}
												/>
											</div>
										</div>
									</div>}


								</div>
							</div> }
							{get_boxes_hook.hook.isPending || get_boxes_hook.hook.isFetching ? <LoaderFull /> :

							<SplitPane sizes={sizes} onChange={(sizes) => setSizes(sizes)}>
								<div style={{ ...layoutCSS, background: '#fafafa' }}>
									<div className="flex flex-col h-screen">


										{
											(function gp1() {
												if (get_boxes_hook.hook.isPending || get_boxes_hook.hook.isFetching) {
													return <LoaderFull />
												}
												else if (Object.keys(totalFieldsExtracted).length !== 0 &&
													Object.keys(totalTablesExtracted).length !== 0 &&
													Object.keys(FlatBoxes).length !== 0 && !IsCSV) {
													return <FilePlaceholder
														fieldsExtracted={fieldsExtracted}
														setfieldsExtracted={setfieldsExtracted}
														tablesExtracted={tablesExtracted}
														settablesExtracted={settablesExtracted}
														Boxes={Boxes}
														setBoxes={setBoxes}
														FlatBoxes={FlatBoxes}
														setFlatBoxes={setFlatBoxes}
														totalFieldsExtracted={totalFieldsExtracted}
														settotalFieldsExtracted={settotalFieldsExtracted}
														totalTablesExtracted={totalTablesExtracted}
														settotalTablesExtracted={settotalTablesExtracted}
														setPageNo={setPageNo}
														PageNo={PageNo}
														type={type}
														imgUrls={imgUrls}
														imgData={imgData}
														errorSelected={errorSelected}
														Lables={Lables}
														setchangeDetect={setchangeDetect}
														setchangeAdd={setchangeAdd}
														DocLockedForMe={DocLockedForMe}
													/>
												}
												else {
													return null
												}

											})()
										}
									</div>
								</div>
								<div
									style={{
										...layoutCSS,
										background: '#ffffff',
										borderLeft: 'solid 2px #c2c2c2',
									}}
								>
									<div className="flex flex-col w-full h-full">
										{
											(function gp2() {
												if (get_boxes_hook.hook.isPending || get_boxes_hook.hook.isFetching) {
													return <LoaderFull />
												}
												else if (Object.keys(totalFieldsExtracted).length !== 0 &&
													Object.keys(totalTablesExtracted).length !== 0 &&
													Object.keys(FlatBoxes).length !== 0) {
													return <RightSideInvAcc
														fieldsExtracted={fieldsExtracted}
														setfieldsExtracted={setfieldsExtracted}
														tablesExtracted={tablesExtracted}
														settablesExtracted={settablesExtracted}
														Boxes={Boxes}
														setBoxes={setBoxes}
														FlatBoxes={FlatBoxes}
														setFlatBoxes={setFlatBoxes}
														totalFieldsExtracted={totalFieldsExtracted}
														settotalFieldsExtracted={settotalFieldsExtracted}
														totalTablesExtracted={totalTablesExtracted}
														settotalTablesExtracted={settotalTablesExtracted}
														setPageNo={setPageNo}
														PageNo={PageNo}
														type={type}
														settype={settype}
														UploadInvDesc={UploadInvDesc}
														CSVUploadInvDesc={CSVUploadInvDesc}
														upload_inv_desc={upload_inv_desc}
														save_as_draft={save_as_draft}
														SaveAsDraft={SaveAsDraft}
														CSVSaveAsDraft={CSVSaveAsDraft}
														errorPages={errorPages}
														seterrorSelected={seterrorSelected}
														errorSelected={errorSelected}
														Lables={Lables}
														setchangeDetect={setchangeDetect}
														setchangeAdd={setchangeAdd}
														DocLockedForMe={DocLockedForMe}
														MismatchIds={MismatchIds}
														gotoMismatch={gotoMismatch}
														IsCSV={IsCSV}
													/>
												}
												else {
													return null
												}


											})()
										}

									</div>
								</div>
							</SplitPane>
							}

						</div>
					</div>}
			</div>
		</>
	);
}
export default InvoicesIndex;
