import React, { useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';

import { GetProjectTypes } from './hooks';

function FilterIsGpo({
    setIsGpo,
	IsGpo,
    setGpoName,
    GpoName
}) {

    const isGpoOptions = ['Yes','No'];

    const [options] = useState(isGpoOptions);
	const client_list_hook = GetProjectTypes();
	const [, setFocus] = useState(false);

    function changeProjectType(val) {
		console.log(val);
        setIsGpo(val)
        setGpoName(null)
	}

    return (
        <>
            <div>
                <div className="flex gap-3">
                    <div className="grow">
                        <div className="lightT">
                            {client_list_hook.isPending ? (
                                <p className="px-4 py-2 border border-1 border-slate-300 rounded-md">
                                    Is GPO{' '}
                                    <span className="text-sm text-slate-400 ps-4 inline-block">
                                        Loading...
                                    </span>
                                </p>
                            ) : (                                
                                <Autocomplete
                                    disablePortal
                                    isOptionEqualToValue={(option, value) =>
                                        option === value || value === '' || value == null
                                    }
                                    id="combo-box-demo"
                                    options={options}
                                    value={IsGpo}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Is GPO" />
                                    )}
                                    onChange={(event, value) => changeProjectType(value)}
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => setFocus(false)}
                                    // onInputChange={(event, newInputValue) => {
                                    //     setProjectType(newInputValue);
                                    //   }}
                                />
                            )}
                            {/* {IsGpo == null && Focus && (
								<small className="text-red-600 ">
									Contract type is required
								</small>
							)} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterIsGpo;
