import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UsersListTable from './listTable';
import { useSelector } from 'react-redux';
import { useUserListPagewise } from './hooks';
import { useDebouncedState } from '@mantine/hooks';

function ManageUsersIndex() {
	const navigate = useNavigate();
	const onClickHandler = () => navigate(`/add-user`);
	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	const navigateRedux = useSelector((state) => state.navigateSlice);

	const user_list_hook = useUserListPagewise(navigateRedux.clientListPageNo);
	const [DebounceSearch, setDebounceSearch] = useDebouncedState('', 500);

	useEffect(() => {
		user_list_hook.setArgs((p) => {
			return { ...p, search: DebounceSearch, page: 1, sort: '' };
		});
	}, [DebounceSearch]);

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
					<div className="lg:flex gap-8">
						<div className="pb-4 lg:pb-0">
							<h1 className="text-lg font-normal text-primary">
								List of <strong className="font-black">Users</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">
								List of all your users is given below -
							</p>
						</div>
						<div className="lg:border-l border-l-white lg:pl-8">
							<h1 className="text-lg font-normal text-primary">
								<strong className="font-black">
									{user_list_hook.hook?.data?.total_users > 0
										? user_list_hook.hook?.data?.total_users
										: 0}
								</strong>
							</h1>
							<p className="text-xs text-gray-500 mb-2">Total users</p>
						</div>
					</div>
					<div className="flex gap-3 flex-wrap">
						<div>
							<div className="relative">
								<span className="material-icons absolute top-2 left-2 z-10 text-slate-400 text-md">
									search
								</span>
								<input
									type="text"
									onChange={(e) => {
										setDebounceSearch(e.target.value);
									}}
									className="border relative border-slate-300 rounded-md w-full h-10 py-2 pr-2 pl-10 focus:outline-blue-400 text-sm"
									placeholder="Search..."
									defaultValue={user_list_hook.Args.search}
								/>
							</div>
						</div>
						<div>
							{PrivilagesList.includes('create_user') && (
								<button
									onClick={onClickHandler}
									className="bg-blue-800 hover:bg-indigo-700 flex items-center px-4 h-[40px] text-sm font-black rounded-md text-white"
								>
									<span className="material-icons text-white text-sm mr-1">
										add
									</span>
									Add New User
								</button>
							)}
						</div>
					</div>
				</div>

				<UsersListTable dataUsers={user_list_hook} Search={DebounceSearch} />
			</div>
		</>
	);
}

export default ManageUsersIndex;
