export function FormatInsideView(DATA) {
	if (typeof DATA !== 'object') {
		return {
			fieldsExtracted_: [],
			tablesExtracted_: [],
			imgUrls_: [],
			imgData_: [],
			errorPages_: [],
			parsable: false,
			locked_for_me_: false,
			freez: false,
			tableBoxes_: [],
			tableTitles_: {}
		};
	}
	

	function coordinateToBox(cords) {
		// return [x(top) , y(left), ,height , width]
		return [cords[0], cords[1], cords[3] - cords[1], cords[2] - cords[0]];
	}

	try {
		// document level variables
		let imgUrls = {};
		let fieldsExtracted = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: [],
			}))
		);
		let tablesExtracted = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: {},
			}))
		);
		let tableBoxes = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: [],
			}))
		);
		let tableTitles_ = Object.assign(
			{},
			...Array.from({ length: Object.keys(DATA.pages).length }, (_, i) => ({
				[i]: {},
			}))
		);


		let imgData = {};
		let errorPages = new Set();
		let freez = false 
		// if ('file_status' in DATA){
		// 	freez = DATA['file_status'] === 'Completed' ? true : false
		// }
		if ('ingestion_status' in DATA){
			freez= DATA['ingestion_status']
		}

		let reprocess = false 
		if ('reprocess_required' in DATA){
			reprocess = DATA['reprocess_required']
		}
		if (reprocess || freez){
			sessionStorage.setItem('contract_disableSaveBtn' , true)
			sessionStorage.setItem('contract_disableSaveBtnTitle' , 'Contract is being reprocess')
		}
		else{
			sessionStorage.setItem('contract_disableSaveBtn' , false)
	 		sessionStorage.setItem('contract_disableSaveBtnTitle' , '')
		}

		if (DATA?.locked_for_me){
			sessionStorage.setItem('last_contract_open' , DATA.files_document_id)
		}

		



		Object.keys(DATA.pages).map((pageNo) => {
			pageNo = parseInt(pageNo);
			let nitm = DATA.pages[pageNo];

			imgUrls[pageNo] = nitm.image_path;
			imgData[pageNo] = {
				image_height: nitm.image_height,
				image_width: nitm.image_width,
			};
			
			nitm?.table_titles?.map((itm,ind)=> {
				tableTitles_[pageNo][ind] = itm
				return null
			})
			nitm?.extracted_entities?.map((itm, ind) => {
				let filterFields = fieldsExtracted[pageNo]?.filter(fldEnt => (fldEnt.entity === itm.entity))
				let mxVersion = 0
				if (filterFields?.length > 0) {
					mxVersion = Math.max(...filterFields?.map(it => it.version)) + 1
				}

				fieldsExtracted[pageNo]?.push({
					entity: itm.entity,
					value: itm.value,
					status: '',
					version: mxVersion,
					entity_valid: itm?.entity_valid,
					rule_applied: itm?.rule_applied,
					entity_validation_explanation: itm?.entity_validation_explanation,
					annotated: itm?.annotated,
					originalValue: itm?.value,
					confidence: itm?.confidence,
					bounding_box: itm?.bounding_box,
					source: itm?.source
				});
				
				if (itm?.entity_valid === 'false') {
					errorPages.add(pageNo);
				}
				return null
			})
			

			nitm?.tables?.map((table, index) => {
				let aggTable = {};
				Object.keys(table).map((row_name) => {
					let row_list = table[row_name];
					let row_ext = [];
					row_list.map((cell) => {
						
						row_ext.push({
							table: `table_${index}`,
							entity: cell.entity,
							value: cell.value,
							status: '',
							version: 0,
							entity_valid: cell?.entity_valid,
							rule_applied: cell?.rule_applied,
							entity_validation_explanation: cell?.entity_validation_explanation,
							annotated: cell?.annotated,
							originalValue: cell?.value,
							confidence: cell?.confidence,
							bounding_box: cell?.bounding_box,
							source: cell?.source
						});
						if (cell?.entity_valid === 'false') {
							errorPages.add(pageNo);
						}
						return null
					});
					aggTable[row_name] = row_ext;
					return null
				});
				tablesExtracted[pageNo][index] = aggTable;
				return null
			});
			
			nitm?.coordinates.map((cords , tableInd) => {
				let [x1, y1, h1, w1] = coordinateToBox(cords);
				tableBoxes[pageNo]?.push({
					x: x1,
					y: y1,
					height: h1,
					width: w1,
					unit: 'px',
					isNew: false,
					isChanging: false,
					entity: `table_${tableInd}` ,
					status: 'original',
					page: pageNo,
					box_id: tableInd,
					
				});
				return null
			})



			return null
		});
		return {
			fieldsExtracted_: fieldsExtracted,
			tablesExtracted_: tablesExtracted,
			imgUrls_: imgUrls,
			imgData_: imgData,
			errorPages_: Array.from(errorPages),
			parsable: true,
			locked_for_me_: DATA?.locked_for_me,
			freez: freez,
			tableBoxes_: tableBoxes,
			tableTitles_: tableTitles_
		};
	} catch (err) {
		console.log(err);
	}
}

export function BoxToCoordinate(box) {
	// return [ x(left) , y (top) , x(bottom) , y (right) ]
	return [box.x, box.y, box.width + box.x, box.y + box.height];
}

export function ReadyToUpload(
	OriginalSnap,
	TotalBx,
	fieldsExtracted,
	tablesExtracted
) {
	function ConvertTableToJson(table) {
		var ret = [];
		Object.keys(table).map((col) => {
			ret = ret.concat(
				table[col].map((itm) => {
					const obj = {};
					obj[col] = itm;
					return obj;
				})
			);
			return null
		});
		return ret;
	}



	let body = {
		document_id: OriginalSnap[0].files_documentId,
		pages: Array.from({ length: TotalBx?.length }, () => {
			return {
				extracted_entities: [],
				tables: [],
				name: '',
				image_path: '',
				table_annotated: false,
				entity_annotated: false,
			};
		}),
	};

	TotalBx.map((pageData, pageNo) => {
		let ent_ann = false;
		let tab_ann = false;
		pageData.extracted_entities.map((ent, entInd) => {
			let bestVal = null;
			let filteredFlds = fieldsExtracted.filter(
				(flds) => flds.entity === ent.entity
			);
			let ret = filteredFlds
				.filter((itm) => itm.version === ent.version)
				.map((itm) => itm.value);
			if (ret?.length === 0) {
				ret = filteredFlds
					.filter((itm) => itm.version === 0)
					.map((itm) => itm.value);
			}
			bestVal = ret[0];

			let ifExists = body.pages[pageNo].extracted_entities.findIndex(
				(itm) => itm.entity === ent.entity
			);
			if (ifExists !== -1) {
				body.pages[pageNo].extracted_entities[ifExists].value?.push(bestVal);
				body.pages[pageNo].extracted_entities[ifExists].bounding_box?.push(
					BoxToCoordinate(ent)
				);
			} else {
				body.pages[pageNo].extracted_entities?.push({
					entity: ent.entity,
					value: [bestVal],
					bounding_box: [BoxToCoordinate(ent)],
				});
			}

			if (ent.status !== 'original') {
				ent_ann = true;
			}

			return null;
		});

		pageData.tables.map((table, tableInd) => {
			body.pages[pageNo].tables?.push({
				coordinates: BoxToCoordinate(table),
				table: ConvertTableToJson(tablesExtracted[table.entity]),
			});

			if (table.status !== 'original') {
				tab_ann = true;
			}
			return null;
		});
		body.pages[pageNo].image_path = OriginalSnap[0].pages[pageNo].image_path;
		body.pages[pageNo].name = OriginalSnap[0].pages[pageNo].name;
		body.pages[pageNo].table_annotated = tab_ann;
		body.pages[pageNo].entity_annotated = ent_ann;
		return null;
	});
	return body;
}

function checkAnnotated(fldObj, boxObj) {
	if (['new added', 'entity changed', 'coordinate changed', 'entity coordinate changed'].includes(boxObj?.status)) {
		return true
	}
	else if (['value changed', 'new added'].includes(fldObj?.status)) {
		return true
	}
	else if (fldObj?.originalValue !== fldObj?.value) {
		return true
	}

	// write a logic to check if its changed from original state
	return false
}

function dateFormatCheck(value) {
	const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;

		if (value === '' || regex.test(value)) {
			return value
			// const [month, day, year] = value.split('/').map(Number);
    		// const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
			// const isoString = date.toISOString();
			// return isoString.replace('Z', '+00:00');
		}
		return false
}



export function prepareFlatList(flds, tbls, tableBoxes, table_titles) {


		
		let extracted_entities = []
		let tpTables = []
		let invalidDate = false
		let start_date_r = null
		let end_date_r = null
		let tenure = null 
		let promisee = null 
		let titles = Object.keys(table_titles).sort((a,b)=> {
			if (parseInt(a) < parseInt(b) ){
				return -1
			}
			else{
				return 1
			}
		}).map(key => table_titles[key])
		let coords = tableBoxes.filter(itm => itm.entity).sort((a,b)=> {
			// console.log(a,b ,"AB");
			
			if (parseInt(a?.entity.split('_')[1]) < parseInt(b?.entity.split('_')[1]) ){
				return -1
			}
			else{
				return 1
			}
		}).map(itm  => BoxToCoordinate(itm))
		

		flds.map((fldObj, fldInd) => {
			if (fldObj.error) {
				invalidDate = true 
			}
			if (fldObj.entity.toLocaleLowerCase() ==='start date'){
				let rt = dateFormatCheck(fldObj.value)
				if (rt !==false){
					start_date_r = rt
				}

			}
			else if (fldObj.entity.toLocaleLowerCase() ==='end date'){
				let rt = dateFormatCheck(fldObj.value)
				if (rt !==false){
					end_date_r = rt
				}
			}
			if (fldObj.entity.toLocaleLowerCase() ==='promisee'){
				promisee = fldObj.value
			}
			if (fldObj.entity.toLocaleLowerCase() ==='tenure'){
				tenure = fldObj.value
			}
			extracted_entities.push({
				annotated: checkAnnotated(fldObj, {}),
				bounding_box: fldObj.bounding_box,
				confidence: 'confidence' in fldObj ? fldObj.confidence : 1.0,
				entity: fldObj.entity,
				entity_valid: fldObj.entity_valid,
				entity_validation_explanation: fldObj.entity_validation_explanation,
				rule_applied: fldObj.rule_applied,
				source: checkAnnotated(fldObj, {}) ? 'human' : fldObj.source,
				value: fldObj.value,
			})
			return null
		})

		if (invalidDate){
			const ret = `Invalid Date Format entered`;
			return  { res: [], extracted_entities: [], tables: [], status: ret, start_date_r: start_date_r,end_date_r: end_date_r, promisee_r : promisee , tenure_r : tenure }
		}

		let emptyCol = false


		Object.entries(tbls).map((ent1) => {
			let [, table] = ent1
			let tpTable = {}
			Object.entries(table).map(ent2 => {
				let [rowName, rowList] = ent2
				rowList.map(cellObj => {
					
					if (cellObj.entity?.length ===0 && cellObj.status ==='new added'){
						emptyCol = true
					}

					if (rowName in tpTable) {
						tpTable[rowName].push({
							entity: cellObj.entity,
							value: cellObj.value,
							bounding_box: cellObj.bounding_box,
							rule_applied: cellObj.rule_applied,
							entity_valid: cellObj.entity_valid,
							entity_validation_explanation: cellObj.entity_validation_explanation,
							annotated: checkAnnotated(cellObj, {}),
							confidence: 'confidence' in cellObj ? cellObj.confidence : 1.0,
							source: checkAnnotated(cellObj, {}) ? 'human' : cellObj.source,
						})
					}
					else {
						tpTable[rowName] = [{
							entity: cellObj.entity,
							value: cellObj.value,
							bounding_box: cellObj.bounding_box,
							rule_applied: cellObj.rule_applied,
							entity_valid: cellObj.entity_valid,
							entity_validation_explanation: cellObj.entity_validation_explanation,
							annotated: checkAnnotated(cellObj, {}),
							confidence: 'confidence' in cellObj ? cellObj.confidence : 1.0,
							source: checkAnnotated(cellObj, {}) ? 'human' : cellObj.source,
						}]
					}
					return null
				})
				return null
			})
			tpTables.push(tpTable)
			return null
		})
		if (emptyCol){
			const ret = `Empty Column name detected`;
			return  {coords: coords,  res: [], extracted_entities: [], tables: [], status: ret, start_date_r: start_date_r,end_date_r: end_date_r , promisee_r : promisee , tenure_r : tenure }
		}


		return {titles: titles , coords: coords, extracted_entities: extracted_entities, tables: tpTables, status: 1, start_date_r: start_date_r,end_date_r: end_date_r , promisee_r : promisee , tenure_r : tenure  }

	

}

export function getCurrentDateTime() {
	const now = new Date();

	const year = now.getUTCFullYear();
	const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
	const day = now.getUTCDate().toString().padStart(2, '0');
	const hours = now.getUTCHours().toString().padStart(2, '0');
	const minutes = now.getUTCMinutes().toString().padStart(2, '0');
	const seconds = now.getUTCSeconds().toString().padStart(2, '0');
	const milliseconds = now.getUTCMilliseconds().toString().padStart(3, '0');

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}000`;
}

