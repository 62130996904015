import React from 'react';
//import { useNavigate } from "react-router-dom";
import UserForm from './user-form';

function AddUser() {
	//const navigate = useNavigate()

	return (
		<>
			<div className="grow h-screen overflow-x-hidden overflow-y-auto fontInter">
				<UserForm />
			</div>
		</>
	);
}

export default AddUser;
