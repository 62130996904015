import React, {  useState,useEffect } from 'react';
import RightSideAcc from './rightAccordion';
import FltrClientName from './client-name-flt';
import UploadTabs from './uploadTabs';
import FilterCategoryList from './category-list';
import FilterProjectType from './project-type';
import FilterVendorName from './vendor-name';
import FilterBatchName from './batch-name';

function TabByInvoices() {
    const [files, setFiles] = useState([]);
    const [ClientName, setClientName] = useState(null);
    const [Category, setCategory] = useState(null);
    const [ProjectType, setProjectType] = useState(null);
    const [VendorName, setVendorName] = useState(null);
    const [BatchName, setBatchName] = useState(null);
    const [refetchBatch, setRefetchBatch] = useState(false);

    const [ClientId, setClientId] = useState(null);
    const [CategoryId, setCategoryId] = useState(null);
    const [ProjectTypeId, setProjectTypeId] = useState(null);
    const [VendorNameId, setVendorNameId] = useState(null);
    const [BatchNameId, setBatchNameId] = useState(null);

    useEffect(() => {
        // Check if any of the conditions are not met; if so, reset BatchName
        // if (!ClientName || !Category || !ProjectType) {
            setBatchName('');
            setBatchNameId('');
        // }
    }, [ClientName, Category, ProjectType]);


    return (
        <>
            <div className="pt-5 px-10 pb-10 border-b">
                <h5 className="text-sm font-black text-primary mb-5">Upload Documents</h5>
                <div className="lg:grid lg:grid-cols-3 lg:gap-6">
                    <div className="py-3 lg:py-0">
                        <FltrClientName
                            setClientName={setClientName}
                            ClientName={ClientName}
                            setClientId={setClientId}
                            ClientId={ClientId}
                        />
                    </div>
                    <div className="py-3 lg:py-0">
                        <FilterCategoryList
                            setCategory={setCategory}
                            Category={Category}
                            setCategoryId={setCategoryId}
                            CategoryId={CategoryId}
                        />
                    </div>
                    <div className="py-3 lg:py-0">
                        <FilterProjectType
                            setProjectType={setProjectType}
                            ProjectType={ProjectType}
                            setProjectTypeId={setProjectTypeId}
                            ProjectTypeId={ProjectTypeId}
                        />
                    </div>
                    <div className="py-3 lg:py-0">
                        <FilterVendorName
                            setVendorName={setVendorName}
                            VendorName={VendorName}
                            setVendorNameId={setVendorNameId}
                            VendorNameId={VendorNameId}
                            required='false'
                        />
                    </div>
                    {ClientName && Category && ProjectType && (
                        <div className="py-3 lg:py-0">
                            <FilterBatchName
                                setBatchName={setBatchName}
                                BatchName={BatchName}
                                setBatchNameId={setBatchNameId}
                                BatchNameId={BatchNameId}
                                ClientName={ClientName}
                                Category={Category}
                                ProjectType={ProjectType}
                                refetchBatch={refetchBatch}
                                disabled={!ClientName || !Category || !ProjectType}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="lg:flex">
                <div className="grow border-t lg:border-t-0 lg:border-r">
                    <UploadTabs
                        files={files}
                        setFiles={setFiles}
                        ClientName={ClientName}
                        Category={Category}
                        ProjectType={ProjectType}
                        VendorName={VendorName}
                        setCategory={setCategory}
                        setClientName={setClientName}
                        setProjectType={setProjectType}
                        setVendorName={setVendorName}
                        setClientId={setClientId}
                        ClientId={ClientId}
                        setCategoryId={setCategoryId}
                        CategoryId={CategoryId}
                        setProjectTypeId={setProjectTypeId}
                        ProjectTypeId={ProjectTypeId}
                        setVendorNameId={setVendorNameId}
                        VendorNameId={VendorNameId}
                        BatchName={BatchName}
                        setBatchName={setBatchName}
                        BatchNameId={BatchNameId}    
                        setBatchNameId={setBatchNameId}
                        setRefetchBatch={setRefetchBatch}
                    />
                </div>
                <div className="flex-none lg:w-[30%]">
                    <RightSideAcc files={files} />
                </div>
            </div>
        </>
    );
}

export default TabByInvoices;