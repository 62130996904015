import React from 'react';
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AnnotationsInProgressChart({DashboardData}) {

    //console.log(DashboardData?.human_annotation_in_progress);

    const options = {
        elements: {
            line: {
                fill: true,
            },
        },
        responsive: true,
        plugins: { legend: { display: false }, },
        scales: {
            x: {
                type: "time",
                time: {
                    unit: 'month',
                    tooltipFormat: 'MMMM YY'
                },
                ticks: {
                        autoSkip: true,
                        maxTicksLimit: 20
                },
                grid: {
                    display: false
                  }
    
            },
            y: {
                beginAtZero: true,
            }
        },
    };
    
    let d = [];
    let uniqueMonths = [];

    DashboardData?.human_annotation_in_progress?.forEach(yearlyData => {
      yearlyData?.monthlyData?.forEach(monthlyData => {
        //console.log(monthlyData.month);
    
        if(uniqueMonths.indexOf(monthlyData.month) === -1) {
          uniqueMonths.push(monthlyData.month);
        }
      });
    });

    uniqueMonths.sort((date1, date2) => new Date(date1) - new Date(date2));

    DashboardData?.human_annotation_in_progress?.forEach(yearlyData => {
      let y = [];
      const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
      const randomRGB = `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
    
      uniqueMonths.forEach(uniqueMonth => {
        let index = yearlyData?.monthlyData?.findIndex(p => p.month === uniqueMonth);
    
        let x = {
          x: new Date(uniqueMonth),
          y: (index >= 0) ? yearlyData?.monthlyData[index].count : 0
        };
    
        y.push(x);
      });
    
      d.push({
        label: yearlyData.uploaded_by,
        hoverBackgroundColor: randomRGB,
        borderColor: randomRGB,
        backgroundColor: randomRGB,
        pointBackgroundColor: randomRGB,
        borderWidth: 2,
        cubicInterpolationMode: 'monotone',
        stepped: false,
        borderJoinStyle: "round",
        tension: 0.2,
        data: y,
        fill: false,
      });
    });

    const data = {
      datasets: d
    };

	return (
		<>
			<h1 className="text-lg font-black text-primary">Total Annotations In progress</h1>
			<p className="text-xs text-gray-500 mb-2">
            Total Annotations In progress for the selected date range
			</p>
			{/* <p className="text-lg font-black text-primary">{total_ha}</p> */}
			<div className="pt-6">
      { DashboardData?.human_annotation_in_progress?.length>0 &&
        <Line options={options} data={data} />
      }
      {DashboardData?.human_annotation_in_progress?.length === 0 &&
        <div  className='flex gap-2 pt-1'>
            No Data Found
        </div> 
        }
			</div>
		</>
	);
}

export default AnnotationsInProgressChart;
