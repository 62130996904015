import React, { useCallback, useEffect, useState } from 'react';
import LoaderFull from '../shared/loader-full-screen';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
/* import { useUserListPagewise } from './hooks'
import { useSearchParams } from "react-router-dom"
import { dispClientPage, dispClientPageNo, dispInvoiceId, dispInvoicePageNo, dispVendorId, dispVendorPageNo } from '../navigateSlice' */
import { RECORDS_PER_PAGE } from '../../config';
import { TextHighlighter } from '../shared/textBold';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { useDeleteUser } from './hooks';
import { toast } from 'react-toastify';

function UsersListTable(props) {
	const datausers = props.dataUsers;
	const Search = props.Search;
	const navigate = useNavigate();

	const PrivilagesList = useSelector(
		(state) => state?.privilageSlice?.allowedActions
	);

	const [Sort, setSort] = useState({
		type: null,
		order: 'arrow_downward_alt',
	});

	function handleSort(typer) {
		setSort((p) => {
			if (p.type === typer) {
				if (p.order === 'arrow_upward_alt') {
					return {
						type: typer,
						order: 'arrow_downward_alt',
					};
				} else {
					return {
						type: typer,
						order: 'arrow_upward_alt',
					};
				}
			} else {
				return {
					type: typer,
					order: 'arrow_downward_alt',
				};
			}
		});
	}

	const delete_user_hook = useDeleteUser(null);

	function handleDelete(e, id) {
		e.preventDefault();
		//console.log(id);
		swal('Are you sure you want to delete this User?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				// User clicked "Yes" button
				delete_user_hook.mutate(id);
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}

	useEffect(() => {
		if (delete_user_hook.isError) {
			toast.error(delete_user_hook.error?.message);
		} else if (delete_user_hook.isSuccess) {
			toast.success(delete_user_hook.data?.message);
			datausers.hook.refetch();
		}
	}, [
		delete_user_hook.isError,
		delete_user_hook.isSuccess,
		delete_user_hook.error?.message
	]);

	useEffect(() => {
		if (Sort.type !== null) {
			datausers.setArgs((p) => {
				var tp1 = Sort.order === 'arrow_downward_alt' ? 'asc' : 'desc';
				var tp = Sort.type + ' ' + tp1;
				return {
					...p,
					sort: tp,
				};
			});
		}
	}, [Sort.type, Sort.order]);

	const renderList = useCallback(() => {
		if (datausers.hook.isFetching) {
			return <LoaderFull />;
		} else if (datausers.hook.data?.data?.length === 0) {
			return <p className="text-center">No data found</p>;
		} else {
			return (
				<>
					{datausers.hook?.data?.data.map((user, ind) => (
						<div key={`itm` + ind} className="flex gap-2 mb-2 tblLoop">
							<div className="flex-none">
								<label className="vLBG flex items-center px-3 h-[53px] rounded-md text-center text-white relative text-sm">
									<img className="h-8" src="/svg/ri_building-fill.svg" alt="" />
								</label>
							</div>
							<div className="grow border rounded-md px-5 py-3 text-sm flex items-center justify-between">
								<div className="min-w-[150px] relative flex grow border-r pr-4 items-center">
									<span className="absolute w-full block text-ellipsis whitespace-nowrap overflow-hidden">
										{/* {user.email} */}
										<TextHighlighter text={user.email} searchString={Search} />
									</span>
								</div>
								<div className="flex-none px-4 text-center w-[240px]">
									{user.role}
								</div>
							</div>
							<div className="flex-none flex gap-2 w-[200px]">
								<div>
									{PrivilagesList.includes('delete_users') && (
										<button
											onClick={(event) => {
												handleDelete(event, user._id);
											}}
											type="button"
											className="bg-red-100 px-4 py-4 flex gap-2 text-center text-red-700 text-sm rounded-md font-black disabled:bg-gray-300"
										>
											<span className="material-icons text-sm">delete</span>
											<label>Delete</label>
										</button>
									)}
								</div>
								<div>
									{PrivilagesList.includes('update_users') &&
										PrivilagesList.includes('manage_role') && (
											<button
												onClick={() => navigate(`/edit-user/` + user._id)}
												type="button"
												className="vLBG px-4 py-4 flex gap-2 text-center text-primary text-sm rounded-md font-black disabled:bg-gray-300"
											>
												<span className="material-icons text-sm">edit</span>
												<label>Edit</label>
											</button>
										)}
								</div>
							</div>
						</div>
					))}
				</>
			);
		}
	}, [datausers.hook.isFetching, datausers.hook.data?.data, Search, PrivilagesList]);

	return (
		<>
			<div className="px-6 pt-6">
				<div className="pr-4 listDHeight overflow-auto">
					{/* Table Head */}
					<div className="flex gap-2 mb-2">
						<div className="flex-none w-[55px]">&nbsp;</div>
						<div className="grow px-5 py-1 text-xs flex items-center justify-between">
							<div className="grow pr-4">
								<label
									onClick={() => handleSort('email')}
									className={`${Sort.type === 'email' ? 'text-primary' : ''} font-black block relative w-[110px] cursor-pointer`}
								>
									Email Address
									<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
										swap_vert
									</span>
								</label>
							</div>
							{/* <div className='flex-none px-4 text-center w-[300px]'>
                                <label className='font-black block relative'>
                                    Email Address
                                </label>
                            </div> */}
							<div className="flex-none px-4 text-center w-[240px] relative">
								<label
									onClick={() => handleSort('role')}
									className={`${Sort.type === 'role' ? 'text-primary' : ''} font-black inline-block relative w-[66px] cursor-pointer`}
								>
									Role
									<span className="material-icons absolute w-4 top-0 right-0 text-sm ml-1 mt-[-2px]">
										swap_vert
									</span>
								</label>
							</div>
						</div>
						<div className="flex-none w-[200px]">&nbsp;</div>
					</div>

					{renderList()}
				</div>
			</div>

			<div className="flex justify-center pt-2">
				{!datausers.hook.isLoading && (
					<Stack spacing={2}>
						<Pagination
							defaultPage={datausers.Args.page}
							onChange={(e, value) => {
								// dispatch(dispClientPageNo({ clientListPageNo: value }))
								datausers.setArgs((p) => {
									return {
										...p,
										page: value,
										// sort: '',
									};
								});
								// setSort({
								// 	type: null,
								// 	order: 'arrow_downward_alt',
								// })
							}}
							count={Math.ceil(
								datausers.hook.data?.total_users / RECORDS_PER_PAGE
							)}
							color="primary"
							variant="outlined"
							shape="rounded"
						/>
					</Stack>
				)}
			</div>
		</>
	);
}

export default UsersListTable;
