import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useDebouncedState } from '@mantine/hooks';
import { toast } from 'react-toastify';
import { RECORDS_PER_PAGE } from '../../config';
import { useState } from 'react';
import { handleLock, LogoutCheck } from '../utils';
import { buildAxiosInstance } from '../axiosInstance';
import { useMsal } from '@azure/msal-react';

const fetchinvoicesList = async ( Args, dispatch, signal,instance) => {
	handleLock(dispatch, 'last_doc_open',instance)
	try {
		const TOAST_ID = 'get_invoice_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/invoice_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		
	};
};

export const useInvoiceslistPagewise = (Sort) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useState({
		search: '',
		...Sort
	});
	const hook = useQuery({
		queryKey: ['invoices_list', Args],
		queryFn: async ({ signal }) =>
			fetchinvoicesList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};


export const useDeleteInvoice = () => {
	const queryClient = useQueryClient() 
	let dispatch = useDispatch()
	const { instance } = useMsal();
	return useMutation({
		retry: false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'delete_invoice_entities'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.delete(`/delete_invoice_entities`,{data : JSON.stringify(body)});
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['invoices_list'] })
		  },
	});
};


const fetchClientList = async (Args, dispatch, signal,instance) => {

	try {
		const TOAST_ID = 'vendor_names_list'
		const axiosInstance = buildAxiosInstance(TOAST_ID)
		let config = {
			params: {...Args, limit: RECORDS_PER_PAGE},
			signal: signal,
		};
		const responseData = await axiosInstance.get('/vendor_names_list',config)
		return responseData
	} catch (error) {
		LogoutCheck(error, dispatch,instance)	
		
	};
};

export const useVendorNames = (client_id) => {
	let dispatch = useDispatch();
	const { instance } = useMsal();
	const [Args, setArgs] = useDebouncedState(
		{
			search: '',
			client_id: client_id,
			page:1
		},
		1000
	);
	const hook = useQuery({
		queryKey: ['vendor_names', Args],
		queryFn: async ({ signal }) => fetchClientList(Args, dispatch, signal,instance),
		retry: false,
	});
	return {
		hook: hook,
		Args: Args,
		setArgs: setArgs,
	};
};


export const useRemapInvoices = (setIsOpened) => {
	const queryClient = useQueryClient() 
	let dispatch = useDispatch()
	const { instance } = useMsal();
	return useMutation({
		retry: false,
		mutationFn: async (body) => {
			try {
				const TOAST_ID = 'remap_invoices'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				const responseData = await axiosInstance.post(`/remap_invoices`, JSON.stringify(body));
				let message = responseData?.message
				message && toast.success(message, { toastId: TOAST_ID })
				return responseData

			} catch (error) {
				LogoutCheck(error, dispatch,instance)
				throw error
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['invoices_list'] })
			setIsOpened(false)
		  },

	});
};


export const useCategoryNameById = (cat_id) => {
	let dispatch = useDispatch()
	return useQuery({
		retry: false,
		queryKey: ['cat_name', cat_id],
		queryFn: async ({signal}) => {
			try {
				const TOAST_ID = 'get_category_by_id'
				const axiosInstance = buildAxiosInstance(TOAST_ID)
				let config = {
					params: {id:cat_id},
					signal:signal
				};
				const responseData = await axiosInstance.get('/get_category_by_id',config)
				return responseData
			} catch (error) {
				LogoutCheck(error, dispatch)	
				
			};
		},
	});
};