import React from 'react';
import styles from '../invoice.module.css';
import {useLikeDislikeChat} from '../hooks';


function LikeDislike({inserted_id, selectedOption, onLikeDislikeChange,setchangeAdd}) {

    const mutation = useLikeDislikeChat();
    const handleLikeDislike = (value) => {
		onLikeDislikeChange(inserted_id, value);
		// Call the update like/dislike API
		updateLikeDislikeAPI(value)
	};

    const updateLikeDislikeAPI = async (value) => {
		try {
			const payload = {
				userEmail:localStorage.getItem('username'),
				isLike: value,
                inserted_id:inserted_id   
			};
			mutation.mutate(payload,{
				onSuccess: (data) => {
					// console.log('Data updated successfully:', data);
					setchangeAdd(p=>p+1);		
				},
				onError: (error) => {
					console.error('Error updating data:', error);
				},
			});
		} catch (error) {
			throw error;
		}
	};

	return (
		<>

            {/* ==== Like-Dislike Box ======== */}
            <div className="flex gap-2 items-end">
                <div className="flex-none pt-2">
                    <label className={`${styles.ldBtn} align-middle`}>
                        <input type='radio' name={`likeDLike-${inserted_id}`} value="true" checked={selectedOption === true} onChange={() => handleLikeDislike(true)}/>
                        <span className={styles.ld}></span>
                    </label>
                    <label className={`${styles.ldBtn} align-middle`}>
                        <input type='radio' name={`likeDLike-${inserted_id}`} value="false" checked={selectedOption === false} onChange={() => handleLikeDislike(false)}/>
                        <span className={`${styles.ld} ${styles.dis}`}></span>
                    </label>
                    <label className='align-middle'>Confirmed this as correct</label>
                </div>                
            </div>
		</>
	);
}

export default LikeDislike;