import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from '../shared/common.module.css';

function RightSideAcc({ files }) {
	//console.log(files);
	let doneCount = 0;
	files.map((item) => {
		//console.log(item);
		if (item.status === 'Done') {
			doneCount += 1;
		}
		return true;
	});

	const [expanded, setExpanded] = React.useState(true);

	return (
		<>
			<div className="pb-5 uplDocAcc">
				<Accordion expanded={expanded} onChange={() => setExpanded((p) => !p)}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<div className="flex gap-3 justify-between w-full items-center">
							<label className="font-bold text-primary text-sm">
								Uploaded Documents
							</label>
							<div>
								<span className="text-sm font-bold text-primary pr-3">
									{doneCount} File{doneCount > 1 ? 's' : ''}
								</span>
							</div>
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<>
							<div className={`${styles.listAccWrap} overflow-auto text-xs`}>
								{files?.map((itm, ind) => {
									return (
										<div key={`status_${ind}`}>
											
											{itm.status === 'Done' && (
												<div className="flex gap-4 justify-between items-center bg-blue-100 px-4 py-2 mb-2">
													<div className="flex gap-2">
														<span className="material-icons text-primary text-sm">
															library_add_check
														</span>
														<label className="text-primary font-bold maxWFN">
															{itm.file.name}
														</label>
													</div>
													<div>
														<p className="text-slate-500">
															{itm.date.toLocaleDateString('en-GB')} |{' '}
															{parseFloat(itm.file.size / 1024).toFixed(2)} kb{' '}
															{itm.file.name.split('.').pop()}
														</p>
													</div>
												</div>
											)}
										</div>
									);
								})}
							</div>
						</>
					</AccordionDetails>
				</Accordion>
				{/* 
                
                 <div key={`status_${ind}`} className='flex gap-3 items-center justify-between mb-2'>
                                            <div>
                                                <span title={`${itm.file.name}`} className='block max-w-[150px] whitespace-nowrap text-ellipsis overflow-hidden'>
                                                    {itm.file.name}
                                                   
                                                </span>
                                            </div>
                                            <div className='text-gray-400'>
                                                {itm.status}
                                                
                                            </div>
                                        </div>
                
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>
                            Contract <span className='pl-4 inline-block text-xs'>3 files</span>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InvoiceStatInAcc />
                    </AccordionDetails>
                </Accordion> */}
			</div>
		</>
	);
}

export default RightSideAcc;
