import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
//import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
//import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MenuProps, useStyles } from './utils';
import { useRoleList, useAddUser } from './hooks';
import { GetCategorylist } from './../upload-docs/hooks';

function UserForm() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(null);

	const [username, setUsername] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [role, setRole] = React.useState('');
	const [expenseCategory, setExpenseCategory] = React.useState([]);
	const [selectedexpenseCategory, setselectedExpenseCategory] = React.useState(
		[]
	);
	const [roles, setRoles] = useState([]);
	const [category, setCategory] = useState([]);

	const role_list_hook = useRoleList();
	const expense_category_list_hook = GetCategorylist();

	useEffect(() => {
		if (role_list_hook.data) {
			//console.log(role_list_hook.data);
			setRoles(role_list_hook.data.map((itm) => itm.display_name));
		}

		if (expense_category_list_hook.data) {
			//console.log(expense_category_list_hook.data.data);
			setCategory(expense_category_list_hook.data.data.map((itm) => itm.name));
			//console.log(category);
			setExpenseCategory(category);
		}
	}, [role_list_hook.data, expense_category_list_hook.data]);

	//const [Focus, setFocus] = useState(false)

	const classes = useStyles('Button');
	//const [selected, setSelected] = React.useState([]);

	const open = Boolean(anchorEl);
	const handleClickListItem = (event) => {
		//console.log(event.currentTarget);
		setAnchorEl(event.currentTarget);
	};

	//roles

	const handleMenuItemClick = (event, index) => {
		//console.log(event.currentTarget.value);
		setRole(role_list_hook.data[event.currentTarget.value].role_name);
		setSelectedIndex(index);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	React.useEffect(() => {
		//let selectedCat = [];
		//console.log(category);
		setExpenseCategory(category);
		
	}, [category, expense_category_list_hook.data]);

	const isAllSelected =
		category.length > 0 && expenseCategory.length === category.length;

	React.useEffect(() => {
		let selectedCat = [];
		//console.log(category);
		//setExpenseCategory(category);
		expenseCategory.map((item1, key) => {
			var result = expense_category_list_hook.data.data.find(
				(item) => item.name === item1
			);
			//console.log(result);
			selectedCat.push(result?._id);
			//console.log(selectedCat);
			if (selectedCat.length > 0) {
				setselectedExpenseCategory(selectedCat);
			} else {
				setselectedExpenseCategory([]);
			}
		return null
		});
	}, [expenseCategory, isAllSelected, expense_category_list_hook.data]);

	const handleChange = (event) => {
		//console.log(event.target.value);
		if (
			event.target.value.includes('all') &&
			expenseCategory.length !== category.length
		) {
			//console.log('1');
			//console.log(category);
			setExpenseCategory(category);
			//console.log(expenseCategory);
		} else if (event.target.value.includes('all')) {
			//console.log('2');
			setExpenseCategory([]);
			//console.log(expenseCategory);
		} else {
			//console.log('3');
			setExpenseCategory(event.target.value);
			//console.log(expenseCategory);
		}
	};

	const navigate = useNavigate();

	const onClickHandler = () => navigate(`/manage-users`);
	const add_new_user = useAddUser();

	function saveChanges() {
		setDisable(true);
		add_new_user.mutate({
			username:username,
			email: email,
			role: role,
			expense_category: selectedexpenseCategory,
		});
	}

	useEffect(() => {
		if (add_new_user.isSuccess) {
			//console.log('reser');
			setUsername('')
			setEmail('');
			setRole('');
			setSelectedIndex(null);
			setExpenseCategory([]);
			//client_list_hook.hook.refetch()
			//setIsOpened(false)

			setTimeout(() => {
				navigate(`/manage-users`);
			}, 2000);
		} else if (add_new_user.isError) {
			setDisable(false);
		}
	}, [add_new_user.isSuccess, add_new_user.isError, navigate]);

	const [Disable, setDisable] = useState(true);
	useEffect(() => {
		//console.log(role);
		//console.log(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
		if (
			email !== '' &&
			username !== '' &&
			role !== '' &&
			email !== null &&
			username !== null &&
			role !== null &&
			expenseCategory.length > 0 &&
			/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
		) {
			// console.log('if');
			setDisable(false);
		} else {
			//console.log('else');
			setDisable(true);
		}
	}, [email,username, role, expenseCategory]);

	return (
		<>
			<div className="lg:flex gap-4 justify-between items-center vLBG py-4 px-6">
				<div className="lg:flex gap-8">
					<div className="pb-4 lg:pb-0">
						<div className="flex gap-3">
							<div className="flex-none pt-1">
								<button onClick={onClickHandler} className="text-primary">
									<span className="material-icons text-md">arrow_back</span>
								</button>
							</div>
							<div>
								<h1 className="text-lg font-normal text-primary">
									Add a <strong className="font-black">New User</strong>
								</h1>
								<p className="text-xs text-gray-500 mb-2">
									Please fill the details given below -
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="flex-none">
					<div>
						<button
							disabled={Disable}
							onClick={saveChanges}
							className="bg-blue-800 hover:bg-indigo-700 flex gap-2 items-center px-4 h-[40px] text-sm font-black rounded-md text-white disabled:bg-slate-300 disabled:text-slate-700"
						>
							<span className="material-icons text-white text-sm">check</span>
							<label>Save and Submit</label>
						</button>
					</div>
				</div>
			</div>
			<div className="p-6">
				<div className="max-w-md m-auto pt-6">
					<form name="addUser">
						<div className='mb-6'>
                            <label className='block mb-2 text-black text-md'>User Name <span className='text-red-600'>*</span></label>
                            <div className='border border-slate-300 rounded-lg flex gap-4'>
                                <label className='bg-slate-100 border-r border-slate-300 flex items-center justify-center px-3 size-11 rounded-lg text-black text-md'>
                                    <img className='h-4' src="/svg/user-ol.svg" alt="" />
                                </label>
                                <div className='grow pr-6'>
                                    <input value={username} onChange={(e) => setUsername(e.target.value)} type='text' className='h-11 text-black outline-0 w-full' placeholder='Enter User Name *' />
                                </div>
                            </div>
                        </div>
						<div className="mb-6">
							<label className="block mb-2 text-black text-md">
								Email Address <span className='text-red-600'>*</span>
							</label>
							<div className="border border-slate-300 rounded-lg flex gap-4">
								<label className="bg-slate-100 border-r border-slate-300 flex items-center justify-center px-3 size-11 rounded-lg text-black text-md">
									<img className="h-4" src="/svg/email-ol.svg" alt="" />
								</label>
								<div className="grow pr-6">
									<input
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										type="email"
										className="h-11 text-black outline-0 w-full"
										placeholder="Enter Email Address *"
									/>
								</div>
							</div>
							{/* <label className='pt-2 text-xs text-red-600'>This field is required.</label> */}
						</div>
						<div className="mb-6">
							<label className="block mb-2 text-black text-md">Role <span className='text-red-600'>*</span></label>
							<div className="border border-slate-300 rounded-lg flex gap-0">
								<label className="bg-slate-100 border-r border-slate-300 flex items-center justify-center px-3 size-11 rounded-lg text-black text-md">
									<img className="h-4" src="/svg/role-ol.svg" alt="" />
								</label>
								<div className="grow pr-2 cdRow relative">
									{role_list_hook.isPending ? (
										<span className="text-sm text-slate-400 ps-4 pt-3 inline-block">
											Loading...
										</span>
									) : (
										<>
											{!selectedIndex ? (
												<InputLabel
													className="absolute top-0 left-0"
													id="mutiple-select-label"
												>
													Select Role *
												</InputLabel>
											) : (
												''
											)}
											<List className="rolleDDBtn">
												<ListItemButton
													id="lock-button"
													aria-haspopup="listbox"
													aria-controls="lock-menu"
													aria-expanded={open ? 'true' : undefined}
													onClick={handleClickListItem}
												>
													<ListItemText primary={roles[selectedIndex]} />
													<span className="material-icons text-md">
														arrow_drop_down
													</span>
												</ListItemButton>
											</List>
										</>
									)}

									<Menu
										id="lock-menu"
										className="roleDD"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'lock-button',
											role: 'listbox',
										}}
									>
										{roles.map((option, index) => (
											<MenuItem
												key={option}
												value={index}
												selected={index === selectedIndex}
												onClick={(event) => handleMenuItemClick(event, index)}
											>
												{option}
											</MenuItem>
										))}
									</Menu>
								</div>
							</div>
							{/* <label className='pt-2 text-xs text-red-600'>This field is required.</label> */}
						</div>
						<div className="mb-6">
							<label className="block mb-2 text-black text-md">
								Expense Category <span className='text-red-600'>*</span>
							</label>
							<div className="border border-slate-300 rounded-lg flex gap-0">
								<label className="bg-slate-100 border-r border-slate-300 flex items-center justify-center px-3 size-11 rounded-lg text-black text-md">
									<img className="h-8" src="/svg/category-ol.svg" alt="" />
								</label>
								<div className="grow pr-0 msDD cdRow relative">
									{expense_category_list_hook.isPending ? (
										<span className="text-sm text-slate-400 ps-4 pt-3 inline-block">
											Loading...
										</span>
									) : (
										<>
											{expenseCategory.length === 0 ? (
												<InputLabel
													className="absolute top-0 left-0"
													id="mutiple-select-label"
												>
													Select Expense Category *
												</InputLabel>
											) : (
												''
											)}
											<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												label="Expense Category"
												multiple
												value={expenseCategory}
												onChange={handleChange}
												input={<OutlinedInput />}
												renderValue={(selected) => selected.join(', ')}
												MenuProps={MenuProps}
											>
												<MenuItem
													value="all"
													classes={{
														root: isAllSelected ? classes.selectedAll : '',
													}}
												>
													<ListItemIcon>
														<Checkbox
															classes={{
																indeterminate: classes.indeterminateColor,
															}}
															checked={isAllSelected}
															indeterminate={
																expenseCategory.length > 0 &&
																expenseCategory.length < category.length
															}
														/>
													</ListItemIcon>
													<ListItemText
														classes={{ primary: classes.selectAllText }}
														primary="Select All"
													/>
												</MenuItem>
												{category.map((name) => (
													<MenuItem key={name} value={name}>
														<Checkbox
															checked={expenseCategory.indexOf(name) > -1}
														/>
														<ListItemText primary={name} />
													</MenuItem>
												))}
											</Select>
										</>
									)}
								</div>
							</div>
							{/* <label className='pt-2 text-xs text-red-600'>This field is required.</label> */}
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default UserForm;
