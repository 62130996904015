import React, { useState, useEffect, useRef } from 'react';
import 'react-tabs/style/react-tabs.css';
import { FileUploader } from 'react-drag-drop-files';
import styles from '../shared/common.module.css';
import { toast } from 'react-toastify';
import { SingleUploadContracts } from './singleUploadContracts';
import swal from 'sweetalert';

const fileTypes = ['JPG', 'PNG', 'PDF', 'TIFF', 'TIF'];

function UploadTabsCont({
	files,
	setFiles,
	ClientName,
	Category,
	ProjectType,
	VendorName,
	ContractType,
	ContractVariation,
	IsGpo,
    GpoName,
	setCategory,
	setClientName,
	setProjectType,
	setVendorName,
	setContractType,
	setContractVariation,
	setIsGpo,
    setGpoName,
	setClientId,
	ClientId,
	setCategoryId,
	CategoryId,
	setProjectTypeId,
	ProjectTypeId,
	setVendorNameId,
	VendorNameId,
	setContractTypeId,
	ContractTypeId,
	setContractVariationId,
	ContractVariationId

}) {
	const [AllUpload, setAllUpload] = useState(0);
	const [Disable, setDisable] = useState(true);
	const prevLengthRef = useRef(files.length);
	const [fileKey, setfileKey] = useState(-1);
	const handleChange = (fls) => {
		//console.log(fls);
		var fk = fileKey;
		let newLst = Object.values(fls).map((itm) => {
			fk += 1;
			return {
				key: fk,
				file: itm,
				s3_uri: '',
				status: 'Pending',
				file_error: itm.size / 1024 / 1024 > 30 ? 'true' : 'false',
				date: new Date(),
				is_duplicate: false,
			};
		});

		setFiles((p) => syncDuplicateCheck([...p, ...newLst]));

		setfileKey(fk);
	};

	const handleFileError = (err) => {
		toast.error(`This file format is not permitted.`);
	};

	function syncDuplicateCheck(fls) {
		let tp_files = [...fls];
		let freq = {};
		for (const fl of tp_files) {
			if (fl.file.name in freq) {
				freq[fl.file.name] += 1;
			} else {
				freq[fl.file.name] = 1;
			}
		}
		let leave_first = new Set();
		tp_files.map((fls, ind) => {
			if (freq[fls.file.name] !== 1) {
				if (leave_first.has(fls.file.name)) {
					tp_files[ind].is_duplicate = true;
				} else {
					tp_files[ind].is_duplicate = false;
					leave_first.add(fls.file.name);
				}
			} else {
				tp_files[ind].is_duplicate = false;
			}
			return null
		});
		return tp_files;
	}

	useEffect(() => {
		//console.log(VendorName);
		if (
			files.filter((itm) => itm.is_duplicate === true).length === 0 &&
			files.filter((itm) => itm.status === 'Pending').length !== 0 &&
			Category !== '' &&
			ClientName !== '' &&
			ProjectType !== '' &&
			Category !== null &&
			ClientName !== null &&
			ProjectType !== null &&
			VendorName !== '' &&
			ContractType !== '' &&
			ContractVariation !== '' &&
			VendorName !== null &&
			ContractType !== null
		) {
			setDisable(false);
			console.log(ContractType);
			console.log(ContractVariation);
			

			if (files.filter((itm) => itm.file_error === 'true').length > 0) {
				setDisable(true);
			} else {
				setDisable(false);
			}

			if( (ContractType === 'Child Contract' && ContractVariation === null) || (IsGpo === 'Yes' && GpoName === null) ){
				
				setDisable(true);
			}else {
				setDisable(false);
			}			

		} else {
			setDisable(true);
		}
	}, [files, AllUpload, Category, ClientName, ProjectType,VendorName,ContractType,ContractVariation,IsGpo,GpoName]);

	useEffect(() => {
		if (
			files.length === prevLengthRef.current &&
			files.filter((itm) => itm.status === 'Done').length !== 0 &&
			files.length === files.filter((itm) => itm.status === 'Done').length
		) {
			toast.success(
				`${files.length} document${files.length > 1 ? 's' : ''} has been uploaded successfully!`
			);
			//setFiles([])

			if(files[files.length-1]?.build_in_progress > 0){
				setTimeout(() => {
					swal('Vendor Model Training in Progress.', 'Model training is currently in progress for the vendor you selected. Invoices will be processed once training is complete, which may take a few hours. Please check the vendor list periodically for updates on processed documents.');
				}, 5000); //message for build in progress
			}else{
				setTimeout(() => {
					swal('Your files are being processed.', 'This may take anywhere from a few minutes to a few hours, depending on the complexity and size of the files. You can monitor the progress in real time on your dashboard under "Processing Files." Once the processing is complete, you can navigate to your files by going to the "My Client" tab.');
				}, 5000); // message for default case.
			}

			setAllUpload(0);
			setDisable(true);
			setCategory(null);
			setClientName(null);
			setProjectType(null);
			setVendorName(null);
			setContractType(null);
			setContractVariation(null);
			setIsGpo(null);
			setGpoName(null);
			setCategoryId(null);
			setClientId(null);
			setProjectTypeId(null);
			setVendorNameId(null);
			setContractTypeId(null);
			setContractVariationId(null);
		}
		prevLengthRef.current = files.length;
	}, [
		files,
		setAllUpload,
		setDisable,
		setCategory,
		setClientName,
		setProjectType,
		setVendorName,
		setContractType,
		setContractVariation,
		setIsGpo,
		setGpoName,
		setCategoryId,
		setClientId,
		setProjectTypeId,
		setVendorNameId,
		setContractTypeId,
		setContractVariationId
	]);

	function handleDiscard() {
		swal('Files which are not uploaded will be discarded?', {
			buttons: ['No', 'Yes'],
		}).then((value) => {
			if (value) {
				setFiles([]);
				setAllUpload(0);
				setDisable(true);
				setCategory(null);
				setClientName(null);
				setProjectType(null);
				setVendorName(null);
				setContractType(null);
				setContractVariation(null);
				setIsGpo(null);
				setGpoName(null);
				setCategoryId(null);
				setClientId(null);
				setProjectTypeId(null);
				setVendorNameId(null);
				setContractTypeId(null);
				setContractVariationId(null);
				// Add your logout logic here
			} else {
				// User clicked "No" button or closed the dialog
				// Add any additional logic you want when the user clicks "No" or closes the dialog
			}
		});
	}

	return (
		<>			

			<form className="block pt-6 pb-2">
				<div className="px-10 pb-2">
					<div className="mb-6">
						<p className="mb-2 text-sm">Upload from Device</p>
						<div className={`${styles.dragDropBox} px-6 pb-5`}>
							<FileUploader
								handleChange={handleChange}
								onTypeError={handleFileError}
								name="file"
								types={fileTypes}
								multiple
							>
								<div className="text-center p-6">
									<p>
										<img
											className="inline-block"
											src="/svg/upCloud-mc.svg"
											alt=""
										/>
									</p>
									<p className="text-primary mb-1">
										<strong>Select files or drag and drop here</strong>
									</p>
									<p className="text-xs text-slate-400 mb-4">
										Supported formats: JPG,PNG,PDF,TIF
									</p>
									{/* <p>{file ? `File name: ${file[0].name}` : ""}</p> */}

									{files ? (
										<div className="pt-4 text-center">
											<label className="cursor-default bg-blue-800 hover:bg-indigo-700 px-6 py-2 rounded-md text-white inline-block font-black disabled:bg-gray-400">
												Select Files
											</label>
										</div>
									) : null}
								</div>
							</FileUploader>

							<div className="max-w-[600px] mx-auto">
								{files?.map((itm, ind) => {
									return (
										<div key={`file${ind}`}>
											<SingleUploadContracts
												setAllUpload={setAllUpload}
												setFiles={setFiles}
												obj={itm}
												AllUpload={AllUpload}
												ClientName={ClientName}
												Category={Category}
												ProjectType={ProjectType}
												VendorName={VendorName}
												ContractType={ContractType}
												ContractVariation={ContractVariation}
												IsGpo={IsGpo}
												GpoName={GpoName}
												ClientId={ClientId}
												CategoryId={CategoryId}
												ProjectTypeId={ProjectTypeId}
												VendorNameId={VendorNameId}
												ContractTypeId={ContractTypeId}
												ContractVariationId={ContractVariationId}
												syncDuplicateCheck={syncDuplicateCheck}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>

				<div className="border-t px-10 pt-5 pb-2 flex gap-4 justify-end">
					<div>
						<button
							onClick={handleDiscard}
							type="button"
							className="border border-blue-800 hover:bg-blue-800 w-full px-4 py-2 rounded-md text-primary hover:text-white text-sm flex items-center font-black"
						>
							<span className="material-icons text-sm mr-2">block</span>
							Discard
						</button>
					</div>
					<div>
						<button
							type="button"
							disabled={Disable}
							onClick={() => {
								if (
									Category === '' ||
									ClientName === '' ||
									ProjectType === '' ||
									VendorName === '' ||
									ContractType === '' || (ContractType === 'Child Contract' && ContractVariation === null)  || (IsGpo === 'Yes' && GpoName === null)
								) {
									setAllUpload(false);
									setDisable(true);
								} else {
									setAllUpload(true);
									setDisable(false);
								}
							}}
							className="bg-blue-800 hover:bg-indigo-700 px-6 py-2 rounded-md text-white inline-block font-black disabled:bg-gray-400"
						>
							Submit
						</button>
					</div>
				</div>
			</form>
		</>
	);
}

export default UploadTabsCont;
